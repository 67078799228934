import React from 'react';
import { Redirect } from 'react-router-dom';

// Components
import SkillGroupNavigation from './skill-group-navigation.component';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { SPORT_TERMINOLOGY } from '../../../lib/constants';
import { Sport, SportTerminology } from '../../../lib/types';

interface ISkillGroupNavigationContainer {
  currentLocation: string;
  countDrills: number;
  loading: boolean;
  fetchError: boolean;
}

const SkillGroupNavigationContainer: React.FC<ISkillGroupNavigationContainer> = ({
  currentLocation,
  countDrills,
  loading,
  fetchError
}) => {
  if (currentLocation === '/practice-plans' && countDrills === 0 && !loading)
    return <Redirect to={"/" + pluralTerm(SPORT_TERMINOLOGY[Sport.default][SportTerminology.EVENT].toLowerCase())} />;

  return <SkillGroupNavigation
    currentLocation={currentLocation}
    countDrills={countDrills}
    loading={loading}
    fetchError={fetchError}
  />;
};

export default SkillGroupNavigationContainer;
