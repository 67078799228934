import _ from 'lodash';
import React, { useState } from 'react';
import Select, { OptionTypeBase } from 'react-select';
import { ValueType } from 'react-select/src/types';

// Material UI
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// Components
import { EnhancedTable, IEnhancedTableRow } from '../../_core/_ui/table.components';
import { FormHelperText, FormInput, FormLabel, IFormOption } from '../../_core/_ui/forms.component';
import { CardButton, CardButtonIcon, PrimaryButton } from '../../_core/_ui/buttons.component';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import DescriptionIcon from '@material-ui/icons/Description';
import { SectionHeading, Text } from '../../_core/_ui/typography.component';
import AddItemIcon from '@material-ui/icons/PostAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { SportTerminology } from '../../../lib/types';

interface ICompanyPlayerList {
  players: IPlayer[];
  search: Function;
  loading: boolean;
  fetchError: boolean;
  selected: IPlayer[];
  setSelected: Function;
  teamOptions: IFormOption[];
  positionOptions: IFormOption[];
  ageGroupOptions: IFormOption[];
  copyAgeGroupOptions: IFormOption[];
  activeAgeGroup?: IAgeGroup;
  setActiveAgeGroup: Function;
  setActivePosition: Function;
  submitError: boolean;
  addPlayersToAssessment: Function;
  assessmentOptions: IFormOption[];
  setActiveCopyAssessment: Function;
  setActiveTeam: Function;
  setActiveCopyAgeGroup: Function;
  activeTeam: string | undefined | null;
  copyTeam: ClickHandler;
  method: string | null | undefined;
  setMethod: Function;
}

const CompanyPlayerList: React.FC<ICompanyPlayerList> = ({
  players,
  search,
  loading,
  fetchError,
  selected,
  setSelected,
  assessmentOptions,
  copyAgeGroupOptions,
  teamOptions,
  positionOptions,
  ageGroupOptions,
  activeAgeGroup,
  setActiveAgeGroup,
  setActivePosition,
  submitError,
  addPlayersToAssessment,
  setActiveCopyAssessment,
  setActiveCopyAgeGroup,
  setActiveTeam,
  activeTeam,
  copyTeam,
  method,
  setMethod
}) => {
  const termLookup = getCompanySportTerminology();
  const athletesTerm = pluralTerm(termLookup[SportTerminology.ATHLETE]);

  const rows: IEnhancedTableRow[] = [];

  _.each(players, (player: IPlayer) => {
    rows.push({
      _id: player._id,
      data: player,
      cells: [
        <TableCell key="name">{player.lastName}, {player.firstName}</TableCell>,
        <TableCell key="dob">{player.dateOfBirth}</TableCell>,
      ]
    });
  });

  const columns = [
    { key: 'name', label: 'Name', path: 'player.lastName' },
    { key: 'dob', label: 'Date of Birth', path: 'player.dateOfBirth' },
  ];

  return (
    <>
      <Box display="flex" my={2} alignItems="center">
        <Box mr={2} width="220px">
          <FormControl>
            <FormLabel>{activeAgeGroup ? 'Assign to' : 'Select'} Age Group</FormLabel>
            <Box width="220px">
              <Select
                options={ageGroupOptions}
                onChange={(option: ValueType<OptionTypeBase, false>) => setActiveAgeGroup(option?.value)}
              />
            </Box>
          </FormControl>
        </Box>
        {activeAgeGroup && method === 'select' && <Box mr={2} width="220px">
          <FormControl>
            <FormLabel>Assign to Position</FormLabel>
            <Box width="220px">
              <Select
                options={positionOptions}
                onChange={(option: ValueType<OptionTypeBase, false>) => setActivePosition(option?.value)}
              />
            </Box>
          </FormControl>
        </Box>}
        {submitError && (
          <Box><FormHelperText error>Please select an age group and a position</FormHelperText></Box>
        )}
      </Box>
      {activeAgeGroup && !method && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CardButton onClick={() => setMethod('select')}>
              <CardButtonIcon><AddItemIcon color="primary" /></CardButtonIcon>
              <SectionHeading>Select {athletesTerm}</SectionHeading>
              <Text>(Within Association)</Text>
            </CardButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <CardButton onClick={() => setMethod('copy')}>
              <CardButtonIcon><GroupAddIcon color="primary" /></CardButtonIcon>
              <SectionHeading>Copy Team</SectionHeading>
              <Text>(From Previous Assessment)</Text>
            </CardButton>
          </Grid>
        </Grid>
      )}
      {activeAgeGroup && method === 'copy' && (<div>
        <FormControl>
          <FormLabel>Assessment</FormLabel>
          <Box width="220px">
            <Select
              options={assessmentOptions}
              onChange={(option: ValueType<OptionTypeBase, false>) => setActiveCopyAssessment(option?.value)}
            />
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel>Age Group</FormLabel>
          <Box width="220px">
            <Select
              options={copyAgeGroupOptions}
              onChange={(option: ValueType<OptionTypeBase, false>) => setActiveCopyAgeGroup(option?.value)}
            />
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel>Team</FormLabel>
          <Box width="220px">
            <Select
              options={teamOptions}
              onChange={(option: ValueType<OptionTypeBase, false>) => setActiveTeam(option?.value)}
            />
          </Box>
        </FormControl>
        {activeTeam && <PrimaryButton onClick={copyTeam}>Copy Team to Assessment</PrimaryButton>}
      </div>)}

      {activeAgeGroup && method === 'select' && <>
        <EnhancedTable
          numCols={3}
          rows={rows}
          columns={columns}
          defaultOrder="player.lastName"
          entityName="players"
          showError={fetchError}
          showLoading={loading && rows.length === 0}
          title={`${athletesTerm} (Not In Assessment)`}
          selected={selected}
          setSelected={setSelected}
          selectedAction={(
            <PrimaryButton
              style={{ minWidth: '200px' }}
              onClick={() => addPlayersToAssessment(selected.map(item => item._id))}
              startIcon={<AddCircleIcon />}
            >Add to Assessment</PrimaryButton>
          )}
          filterComponent={(
            <FormInput
              placeholder={`Search ${athletesTerm}..`}
              onKeyUp={(e: any) => search(e.currentTarget.value)} />
          )}
        />
      </>}
    </>
  );
};

export default CompanyPlayerList;
