export enum UserActionType {
  score = "score",
  removeScore = "removeScore",
  compositeScore = "compositeScore",
  removeCompositeScore = "removeCompositeScore"
}

export interface UserSessionParams {
  actionType: UserActionType;
  payload: object;
  loggedAt: Date
}

export interface IUserSessionStore {
  // User sessions by user id
  userSessions: Record<string, IUserSession[]>
}