import { gql } from '@apollo/client';

export const SKILL_FIELDS = gql`
  fragment SkillFields on Skill {
    _id
    name
    type
    options
  }
`;

export const REMOVE_SKILL = gql`
  mutation RemoveSkill($_id: ID!, $companyId: ID) {
    removeSkill(_id: $_id, companyId: $companyId) {
      _id
    }
  }
`;

export const RESTORE_SKILL = gql`
  mutation RestoreSkill($_id: ID!, $companyId: ID) {
    restoreSkill(_id: $_id, companyId: $companyId) {
      ...SkillFields
    }
  }

  ${SKILL_FIELDS}
`;

export const CHECK_SKILL_DELETE_EFFECTS = gql`
  mutation CheckSkillDeleteEffects($_id: ID!, $companyId: ID) {
    checkSkillDeleteEffects(_id: $_id, companyId: $companyId) {
      assessments
      drills
    }
  }
`;
