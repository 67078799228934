import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import Fuse from 'fuse.js';

// Components
import SkillList from './skill-list.component';

interface ISkillListContainer {
  skills: ISkill[];
  countArchivedSkills: number;
  loading: boolean;
  fetchError: boolean;
  viewingArchived: boolean;
  setViewingArchived: Function;
  removeSkill: Function;
  restoreSkill: Function;
  checkSkillDeleteEffects: Function
  isAdmin: boolean;
}

const SkillListContainer: React.FC<ISkillListContainer> = ({
  skills,
  countArchivedSkills,
  loading,
  fetchError,
  viewingArchived,
  setViewingArchived,
  removeSkill,
  restoreSkill,
  checkSkillDeleteEffects,
  isAdmin
}) => {
  const [selected, setSelected] = useState<ISkill[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSkills, setFilteredSkills] = useState(skills);
  const fuse = new Fuse(skills, { threshold: 0.3, keys: ['name', 'position.name'] });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (searchTerm.length) {
      const results = fuse.search(searchTerm);

      setFilteredSkills(_.map(results, 'item'));
    } else {
      setFilteredSkills(skills);
    }
  }, [skills.length, searchTerm]);

  const debounceSearch = useCallback(_.debounce((nextSearch: string) => {
    setSearchTerm(nextSearch)
  }, 300), []);

  const removeSkills = (_drills: IDrill[]) => {
    _drills.map(d => {
      checkSkillDeleteEffects(d._id, d.companyId)
        .then(({data: {checkSkillDeleteEffects: effects}}: {data: {checkSkillDeleteEffects: ISkillDeleteEffects}}) => {
          let affects = "";
          const assessmentEffectsLength = effects.assessments.length
          if (assessmentEffectsLength) affects += `${assessmentEffectsLength} assessments: ${effects.assessments.map((a: string) => `"${a}"`).join(", ")}`
          if (effects.drills.length) affects += `${assessmentEffectsLength ? ", and " : ""}${effects.drills.length} drills: ${effects.drills.map((p: string) => `"${p}"`).join(", ")}`

          if (!affects.length) removeSkill(d._id, d.companyId)
          else if (window.confirm(`Deleting skill "${d.name}" will affect ${affects}. Are you sure you want to delete?`)) removeSkill(d._id, d.companyId)
        })
        .catch((err: Error) => console.error("Failed checking skill delete effects"))
    });
  };
  const restoreSkills = (_drills: IDrill[]) =>
    _drills.map(({_id, companyId}) => restoreSkill(_id, companyId));

  return <SkillList
    loading={loading}
    fetchError={fetchError}
    skills={filteredSkills}
    countArchivedSkills={countArchivedSkills}
    searchSkills={debounceSearch}
    selected={selected}
    setSelected={setSelected}
    viewingArchived={viewingArchived}
    setViewingArchived={setViewingArchived}
    removeSkills={removeSkills}
    restoreSkills={restoreSkills}
    isAdmin={isAdmin}
  />;
};

export default SkillListContainer;
