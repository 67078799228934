import React from 'react';
import styled from 'styled-components';

// Material UI
import Typography from '@material-ui/core/Typography';

// Data
import { successColor, warnColor } from '../../../lib/theme';
import { getOnlineStatus, getSyncing } from '../PersistGateContainer/persist-gate.container';

// Components
const AlertWrapper = styled.div`
  text-align: center;
  padding: 12px 16px;
  background: ${warnColor};
  z-index: 10000;
  position: fixed;
  width: 100%;
`;

const AlertPlaceholder = styled.div`
  padding: 12px 16px;
  visibility: hidden;
`;

const SyncWrapper = styled.div`
  text-align: center;
  padding: 12px 16px;
  background: ${successColor};
`;

const OnlineStatus = () => {
  const isOnline = getOnlineStatus();
  const isSyncing = getSyncing();

  if (isSyncing) {
    return (
      <SyncWrapper>
        <Typography>
          Syncing
        </Typography>
      </SyncWrapper>
    );
  }

  if (isOnline) return <></>;

  return (
    <>
      <AlertWrapper>
        <Typography>
          No internet connection.
          Changes you make will saved and sync when your connection is restored
        </Typography>
      </AlertWrapper>
    </>
  );
};

export default OnlineStatus;
