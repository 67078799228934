import _ from 'lodash';
import { SkillOption, SportInvolvement,  Sport, SportTermLookup, SportTerminology } from './types';

// App Specific Constants
export const SPORT_INVOLVEMENT_OPTIONS: { value: SportInvolvement; label: string }[] = [
  { value: SportInvolvement.none, label: "None" },
  { value: SportInvolvement.recreational, label: "Recreational" },
  { value: SportInvolvement.collegiate, label: "Collegiate/University" },
  { value: SportInvolvement.provincial, label: "Provincial" },
  { value: SportInvolvement.national, label: "National" },
  { value: SportInvolvement.international, label: "International" },
];

// TODO: Make a more direct reference to the default sport terminology for route lookups
export const SPORT_TERMINOLOGY: Record<Sport, SportTermLookup> = {
  [Sport.default]: {
    [SportTerminology.ATHLETE]: "Athlete",
    [SportTerminology.EVENT]: "Event",
    [SportTerminology.FACILITY]: "Facility",
    [SportTerminology.FACILITY_ADDRESS]: "Address",
    [SportTerminology.FIELD]: "Field",
    [SportTerminology.METRIC]: "Metric",
    [SportTerminology.SESSION]: "Assessment Session",
  },
  [Sport.hockey]: {
    [SportTerminology.ATHLETE]: "Player",
    [SportTerminology.EVENT]: "Drill",
    [SportTerminology.FACILITY]: "Arena",
    [SportTerminology.FACILITY_ADDRESS]: "Arena Address",
    [SportTerminology.FIELD]: "Rink",
    [SportTerminology.METRIC]: "Skill",
    [SportTerminology.SESSION]: "Ice Time",
  },
};

export const GENDERS = ['Any', 'Male', 'Female'];
export const GENDER_OPTIONS = GENDERS.map(gender => ({ value: gender, label: gender }));


export const USE_24_HOUR = false;

export const SKILL_TYPE_OPTIONS = [
  // Direct map to measurement types
  { value: SkillOption.SCORE, label: 'Numbered Score' },
  { value: SkillOption.TIMED, label: 'Timed Score (Stopwatch)' },
  { value: SkillOption.INTEGER, label: 'Integer Value' },
  { value: SkillOption.FLOAT, label: 'Number Value (2 Decimals)' },

  // Variations of float measurement
  { value: SkillOption.CENTIMETERS, label: 'Centimeters (2 Decimals)' },
  { value: SkillOption.INCHES, label: 'Inches (2 Decimals)' },
  { value: SkillOption.KILOGRAMS, label: 'Kilograms (2 Decimals)' },
  { value: SkillOption.POUNDS, label: 'Pounds (2 Decimals)' },
  { value: SkillOption.SECONDS, label: 'Seconds (2 Decimals)' }
];

// Boilerplate Constants
export const BASE_API_URL = process.env.REACT_APP_ENABLE_SERVERLESS === 'true' ? process.env.REACT_APP_SERVERLESS_HOST : process.env.REACT_APP_API_HOST;
export const HTTP_PROTOCOL = process.env.NODE_ENV === 'development' ? 'http' : 'https';
export const GRAPHQL_HTTP_URI = `${HTTP_PROTOCOL}://${BASE_API_URL}/graphql`;
export const WS_PROTOCOL = process.env.NODE_ENV === 'development' ? 'ws' : 'wss';
export const GRAPHQL_WS_URI = `${WS_PROTOCOL}://${BASE_API_URL}/graphql`;
export const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;

export const INVITE_STATUSES = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  CANCELLED: 'CANCELLED'
};

export const COMMON_MAIL_DOMAINS = [
  /* Default domains included */
  "aol.com", "att.net", "comcast.net", "facebook.com", "gmail.com", "gmx.com", "googlemail.com",
  "google.com", "hotmail.com", "hotmail.co.uk", "mac.com", "me.com", "mail.com", "msn.com",
  "live.com", "sbcglobal.net", "verizon.net", "yahoo.com", "yahoo.co.uk",

  /* Other global domains */
  "email.com", "fastmail.fm", "games.com" /* AOL */, "gmx.net", "hush.com", "hushmail.com", "icloud.com",
  "iname.com", "inbox.com", "lavabit.com", "love.com" /* AOL */, "outlook.com", "pobox.com", "protonmail.ch", "protonmail.com", "tutanota.de", "tutanota.com", "tutamail.com", "tuta.io",
  "keemail.me", "rocketmail.com" /* Yahoo */, "safe-mail.net", "wow.com" /* AOL */, "ygm.com" /* AOL */,
  "ymail.com" /* Yahoo */, "zoho.com", "yandex.com",

  /* United States ISP domains */
  "bellsouth.net", "charter.net", "cox.net", "earthlink.net", "juno.com",

  /* British ISP domains */
  "btinternet.com", "virginmedia.com", "blueyonder.co.uk", "freeserve.co.uk", "live.co.uk",
  "ntlworld.com", "o2.co.uk", "orange.net", "sky.com", "talktalk.co.uk", "tiscali.co.uk",
  "virgin.net", "wanadoo.co.uk", "bt.com",

  /* Domains used in Asia */
  "sina.com", "sina.cn", "qq.com", "naver.com", "hanmail.net", "daum.net", "nate.com", "yahoo.co.jp", "yahoo.co.kr", "yahoo.co.id", "yahoo.co.in", "yahoo.com.sg", "yahoo.com.ph", "163.com", "yeah.net", "126.com", "21cn.com", "aliyun.com", "foxmail.com",

  /* French ISP domains */
  "hotmail.fr", "live.fr", "laposte.net", "yahoo.fr", "wanadoo.fr", "orange.fr", "gmx.fr", "sfr.fr", "neuf.fr", "free.fr",

  /* German ISP domains */
  "gmx.de", "hotmail.de", "live.de", "online.de", "t-online.de" /* T-Mobile */, "web.de", "yahoo.de",

  /* Italian ISP domains */
  "libero.it", "virgilio.it", "hotmail.it", "aol.it", "tiscali.it", "alice.it", "live.it", "yahoo.it", "email.it", "tin.it", "poste.it", "teletu.it",

  /* Russian ISP domains */
  "mail.ru", "rambler.ru", "yandex.ru", "ya.ru", "list.ru",

  /* Belgian ISP domains */
  "hotmail.be", "live.be", "skynet.be", "voo.be", "tvcablenet.be", "telenet.be",

  /* Argentinian ISP domains */
  "hotmail.com.ar", "live.com.ar", "yahoo.com.ar", "fibertel.com.ar", "speedy.com.ar", "arnet.com.ar",

  /* Domains used in Mexico */
  "yahoo.com.mx", "live.com.mx", "hotmail.es", "hotmail.com.mx", "prodigy.net.mx",

  /* Domains used in Canada */
  "yahoo.ca", "hotmail.ca", "bell.net", "shaw.ca", "sympatico.ca", "rogers.com",

  /* Domains used in Brazil */
  "yahoo.com.br", "hotmail.com.br", "outlook.com.br", "uol.com.br", "bol.com.br", "terra.com.br", "ig.com.br", "itelefonica.com.br", "r7.com", "zipmail.com.br", "globo.com", "globomail.com", "oi.com.br"
];
