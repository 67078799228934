import _ from 'lodash';
import { FetchResult, MutationResult } from '@apollo/client';
import React, { useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';

// Data
import { ICreatePlayerContainer, ICreatePlayerFields } from './create-player.interface';
import { GENDER_OPTIONS } from '../../../lib/constants';
import { authUser } from '../../../lib/services/auth.service';

// Components
import CreatePlayer from './create-player.component';
import { SportTerminology } from '../../../lib/types';
import { getCompanySportTerminology } from '../../../lib/services/company.service';

const CreatePlayerContainer: React.FC<ICreatePlayerContainer> = ({
  createPlayerMutation,
  ageGroups,
  createAgeGroup,
  positions,
  createPosition,
  fetchError,
  loading
}) => {
  const termLookup = getCompanySportTerminology();
  const athleteTerm = termLookup[SportTerminology.ATHLETE];

  const [submitError, setSubmitError] = useState();
  const [showSaved, setShowSaved] = useState(false);

  const INITIAL_FORM_VALUES = {
    firstName: '',
    lastName: '',
    date: dayjs().subtract(8, 'year').format('YYYY/MM/DD'),
    gender: GENDER_OPTIONS[1],
    externalId: '',
    contacts: [{ name: '', email: '', sendEmail: true, phone: '', sendSMS: false, height: '', relationship: null, sportInvolvement: null }]
  };

  async function handleSubmit(values: ICreatePlayerFields, { setSubmitting, resetForm, setFieldValue }: FormikHelpers<ICreatePlayerFields>) {
    setSubmitError(null);

    function complete(result: MutationResult) {
      setSubmitting(false);

      if (result.error) {
        setSubmitError(result.error.message);
      } else {
        setShowSaved(true);
        setTimeout(() => {
          setShowSaved(false);
        }, 2000);

        resetForm({
          values: {
            ...INITIAL_FORM_VALUES,
            positionId: values.positionId,
            ageGroupId: values.ageGroupId
          }
        });
        setFieldValue('primarySport', null)
      }
    }

    createPlayerMutation({
      player: {
        ..._.omit(values, ['positionId', 'ageGroupId', 'gender', 'contacts', 'date', 'primarySport']),
        dateOfBirth: values.date,
        gender: values.gender.value,
        primarySport: values.primarySport?.value,
        contacts: values.contacts?.map((c) => ({
          ..._.omit(c, ['height', 'relationship', 'sportInvolvement']),
          height: c.height ? Number(c.height) : undefined,
          relationship: c.relationship?.value,
          sportInvolvement: c.sportInvolvement?.value
        }))
      },
      assessmentId: authUser()?.settings.activeAssessmentId,
      ageGroupId: values.ageGroupId?.value,
      positionId: values.positionId?.value
    })
      .then(complete);
  }

  const ageGroupOptions = ageGroups.map(ageGroup => ({ value: ageGroup._id, label: ageGroup.name }));
  const handleCreateAgeGroup = (form: FormikProps<ICreatePlayerFields>, name: string) => {
    createAgeGroup(name).then((result: FetchResult) => {
      if (result.data && result.data.createAgeGroup) {
        form.setFieldValue('ageGroupId', ({ value: result.data.createAgeGroup._id, label: name }));
        form.setFieldTouched('ageGroupId');
      }
    });
  }

  const positionOptions = positions.map(position => ({ value: position._id, label: position.name }));
  const handleCreatePosition = (form: FormikProps<ICreatePlayerFields>, name: string) => {
    createPosition(name).then((result: FetchResult) => {
      if (result.data && result.data.createPosition) {
        form.setFieldValue('positionId', ({ value: result.data.createPosition._id, label: name }));
        form.setFieldTouched('positionId');
      }
    });
  }

  // TODO: This doesn't sufficiently capture requirements for player creation
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(`Enter the ${athleteTerm}'s first name`),
    lastName: Yup.string().required(`Enter the ${athleteTerm}'s last name`),
  });

  return (
    <Formik
      initialValues={INITIAL_FORM_VALUES}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {form => <CreatePlayer
        form={form}
        submitError={submitError}
        showSaved={showSaved}
        ageGroupOptions={ageGroupOptions}
        createAgeGroup={handleCreateAgeGroup}
        positionOptions={positionOptions}
        createPosition={handleCreatePosition}
        fetchError={fetchError}
        loading={loading}
      />}
    </Formik>
  );
};

export default CreatePlayerContainer;
