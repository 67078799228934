import { gql } from '@apollo/client';

export const DRILL_FIELDS = gql`
  fragment DrillFields on Drill {
    _id
    name
  }
`;

export const REMOVE_DRILL = gql`
  mutation RemoveDrill($_id: ID!, $companyId: ID) {
    removeDrill(_id: $_id, companyId: $companyId) {
      _id
    }
  }
`;

export const RESTORE_DRILL = gql`
  mutation RestoreDrill($_id: ID!, $companyId: ID) {
    restoreDrill(_id: $_id, companyId: $companyId) {
      ...DrillFields
    }
  }

  ${DRILL_FIELDS}
`;

export const REMOVE_DRILL_IMAGE = gql`
  mutation RemoveDrillImage($_id: ID!) {
    removeDrillImage(_id: $_id) {
      _id
      image
    }
  }
`;

export const CHECK_DRILL_DELETE_EFFECTS = gql`
  mutation CheckDrillDeleteEffects($_id: ID!, $companyId: ID) {
    checkDrillDeleteEffects(_id: $_id, companyId: $companyId) {
      assessments
      practicePlans
    }
  }
`;
