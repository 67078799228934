import { SkillMeasurementType, SkillOption } from '../types';

export const lookupSkillSuffix = (type: SkillOption): string | null => {
  switch (type) {
    case SkillOption.CENTIMETERS: return "cm";
    case SkillOption.INCHES: return "in";
    case SkillOption.KILOGRAMS: return "kilograms";
    case SkillOption.POUNDS: return "lbs";
    case SkillOption.SECONDS: return "seconds";
    default: return null;
  }
}

export const skillToSkillOption = ({type, options}: ISkill): SkillOption | undefined => {
  const typeLookup = (type === SkillMeasurementType.FLOAT && options && "subType" in options) ? options.subType ?? type : type
  return Object.entries(SkillOption).find(([_key, value]) => value === typeLookup)?.[0] as SkillOption
}

export const skillOptionToSkillFields = (type: SkillOption, options: any): Pick<ISkill, 'options' | 'type'> => {
  let newType: SkillMeasurementType | null = null;
  switch (type) {
    case SkillOption.CENTIMETERS:
    case SkillOption.INCHES:
    case SkillOption.KILOGRAMS:
    case SkillOption.POUNDS:
    case SkillOption.SECONDS:
      newType = SkillMeasurementType.FLOAT;
      options.subType = type;
      break;
  }

  return { type: newType ?? type, options }
}
