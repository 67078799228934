export enum SkillMeasurementType {
  INTEGER = 'INTEGER',
  FLOAT = 'FLOAT',
  SCORE = 'SCORE',
  TIMED = 'TIMED'
};

export enum SkillOption {
  // Direct map to measurement types
  FLOAT = 'FLOAT',
  INTEGER = 'INTEGER',
  SCORE = 'SCORE',
  TIMED = 'TIMED',

  // Variations of float measurement
  CENTIMETERS = 'CENTIMETERS',
  INCHES = 'INCHES',
  KILOGRAMS = 'KILOGRAMS',
  POUNDS = 'POUNDS',
  SECONDS = 'SECONDS',
}
