import _ from 'lodash';
import { ApolloLink, makeVar, Operation, useReactiveVar } from '@apollo/client';
import uuidv4 from 'uuid/v4';
import { store } from './storage';

const STORAGE_REF = 'max_TrackedQueries';
let currentTrackedQueries: ITrackedQuery[] = [];

export const trackedQueries = makeVar<ITrackedQuery[]>([]);

export const removeTrackedQuery = async (id: string) => {
  const nextTrackedQueries = [...trackedQueries()];
  _.remove(nextTrackedQueries, query => query.id);

  trackedQueries([...nextTrackedQueries]);
  await store.set(STORAGE_REF, JSON.stringify({ queries: nextTrackedQueries }));
};

export const addTrackedQuery = async (query: ITrackedQuery) => {
  const nextTrackedQueries = [...trackedQueries(), query];

  trackedQueries([...nextTrackedQueries]);
  await store.set(STORAGE_REF, JSON.stringify({ queries: nextTrackedQueries }));
};

export default () =>
  new ApolloLink((operation: Operation, forward) => {
    if (forward === undefined) {
      return null;
    }

    const name: string = operation.operationName;
    const queryJSON: string = JSON.stringify(operation.query);
    const variablesJSON: string = JSON.stringify(operation.variables);
    const context = operation.getContext();
    const contextJSON = JSON.stringify({}); // JSON.stringify(context);
    const id = uuidv4();

    if (context.tracked !== undefined) {
      const trackedQuery: ITrackedQuery = {
        contextJSON,
        id,
        name,
        queryJSON,
        variablesJSON,
      };
      let addQuery = true;

      try {
        if (name === 'RemoveScore' && operation.variables?._id.startsWith('src_')) {

          const currQueries = trackedQueries();

          let found = false;
          for (let i = 0; i < currQueries.length && !found; i +=1) {
            const query = currQueries[i];
            const currVariables = JSON.parse(query.variablesJSON);

            if (currVariables.data.drillId == operation.variables.data.drillId
              && currVariables.data.skillId == operation.variables.data.skillId
              && currVariables.data.assessmentSessionId == operation.variables.data.assessmentSessionId
              && currVariables.data.playerAssessmentId == operation.variables.data.playerAssessmentId
              && currVariables.data.round == operation.variables.data.round) {
              removeTrackedQuery(query.id);
              found = true;
              addQuery = false;
            }
          }
        } else if (operation.variables?.data?._id && operation.variables.data._id.startsWith('src_')) {
          const currQueries = trackedQueries();

          let found = false;
          for (let i = 0; i < currQueries.length && !found; i +=1) {
            const query = currQueries[i];
            const currVariables = JSON.parse(query.variablesJSON);

            if (currVariables.data.drillId == operation.variables.data.drillId
              && currVariables.data.skillId == operation.variables.data.skillId
              && currVariables.data.assessmentSessionId == operation.variables.data.assessmentSessionId
              && currVariables.data.playerAssessmentId == operation.variables.data.playerAssessmentId
              && currVariables.data.round == operation.variables.data.round) {
              removeTrackedQuery(query.id);
              delete operation.variables.data._id;
              trackedQuery.variablesJSON = JSON.stringify(operation.variables);
              found = true;
            }
          }
        }

        if (addQuery) {
          addTrackedQuery(trackedQuery);
        }
      } catch (err) {
        console.error('Should not be breaking here...', err);
      }
    }

    return forward(operation).map(data => {
      if (context.tracked !== undefined) {
        removeTrackedQuery(id);
      }

      return data;
    });
  });

export const checkHasTrackedQueries = async () => {
  try {
    currentTrackedQueries = JSON.parse(await store.get(STORAGE_REF) || '').queries;
  } catch(e) {
    currentTrackedQueries = [];
  }

  return currentTrackedQueries.length > 0;
}
