import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import aOrAn from 'indefinite';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

// Components
import { ButtonRow, OutlineButtonVertical, SecondaryButtonVertical } from '../../_core/_ui/buttons.component';
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { Sport, SportTerminology } from '../../../lib/types';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { SPORT_TERMINOLOGY } from '../../../lib/constants';

interface ISkillGroupNavigation {
  currentLocation: string;
  countDrills: number;
  loading: boolean;
  fetchError: boolean;
}

const SkillGroupNavigation: React.FC<ISkillGroupNavigation> = ({
  currentLocation,
  countDrills
}) => {
  const termLookup = getCompanySportTerminology()
  const eventsTerm = pluralTerm(termLookup[SportTerminology.EVENT]);
  const metricsTerm = pluralTerm(termLookup[SportTerminology.METRIC]);
  const history = useHistory();

  const NAVIGATION_DATA: { [key: string]: any } = {
    SKILLS: {
      currentLocation: '/' + pluralTerm(SPORT_TERMINOLOGY[Sport.default][SportTerminology.METRIC].toLowerCase()),
      primary: 'Manage ' + metricsTerm,
      secondary: '(single performance measure)',
      disableHover: true
    },
    DRILLS: {
      currentLocation: '/' + pluralTerm(SPORT_TERMINOLOGY[Sport.default][SportTerminology.EVENT].toLowerCase()),
      primary: 'Manage ' + eventsTerm,
      secondary: `(a set of ${metricsTerm} evaluated at once)`,
      hoverTitle: 'Requires ' + metricsTerm
    },
    PLANS: {
      currentLocation: '/practice-plans',
      primary: 'Manage Practice Plans',
      secondary: `(a set of ${eventsTerm.toLowerCase()} for ${aOrAn(termLookup[SportTerminology.SESSION])})`,
      hoverTitle: 'Requires ' + eventsTerm,
      isDisabled: countDrills === 0
    }
  };


  return <ButtonRow>
    {_.map(NAVIGATION_DATA, (data, key) => {
      const isActive = currentLocation === data.currentLocation;
      let Component: any = isActive ? OutlineButtonVertical : SecondaryButtonVertical;
      Component = data.isDisabled ? FakeDisabledButton : Component;

      return (
        <Tooltip key={key} disableHoverListener={data.disableHover || !data.isDisabled} title={data.hoverTitle || ''}>
          <Component
            style={{ flexGrow: 1 }}
            onClick={() => !isActive && history.push(data.currentLocation)}
          >
            {data.primary}<br />
            <Typography variant="body2">{data.secondary}</Typography>
          </Component>
        </Tooltip>
      );
    })}
  </ButtonRow>;
};

const FakeDisabledButton = styled.div`
  color: rgba(0, 0, 0, 0.26);
  margin: 0 8px;
  padding: 8px 16px;
  font-weight: 500;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 1.75;
  cursor: default;
`;

export default SkillGroupNavigation;
