import { defineMessages } from 'react-intl';
import { SportTermLookup, SportTerminology } from '../../../lib/types';
import { pluralTerm } from '../../../lib/helpers/term.helper';

export const scope = 'app.components.CreateDrillForm';

export default (termLookup: SportTermLookup) => {
  return defineMessages({
    lblName: {
      id: `${scope}.lblName`,
      defaultMessage: termLookup[SportTerminology.EVENT] + ' Name'
    },
    lblSkills: {
      id: `${scope}.lblSkills`,
      defaultMessage: `Evaluate these ${pluralTerm(termLookup[SportTerminology.METRIC])}:`
    },
    lblPosition: {
      id: `${scope}.lblPosition`,
      defaultMessage: 'For the following positions:'
    },
    lblGlobal: {
      id: `${scope}.lblGlobal`,
      defaultMessage: 'Accessible to all associations'
    },
    btnSubmit: {
      id: `${scope}.btnSubmit`,
      defaultMessage: 'Create ' + termLookup[SportTerminology.EVENT],
    },
    btnSubmitting: {
      id: `${scope}.btnSubmitting`,
      defaultMessage: 'Saving...',
    },
    btnEdit: {
      id: `${scope}.btnEdit`,
      defaultMessage: 'Save Changes'
    }
  });
}
