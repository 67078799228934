export enum SportInvolvement {
  none = "none",
  recreational = "recreational",
  collegiate = "collegiate",
  provincial = "provincial",
  national = "national",
  international = "international",
}

export const SportInvolvementValues: string[] = Object.values(SportInvolvement)
 
export enum PrimarySport {
  athletics = "athletics",
  baseball = "baseball",
  basketball = "basketball",
  cycling = "cycling",
  golf = "golf",
  rugby = "rugby",
  sailing = "sailing",
  soccer = "soccer",
  softball = "softball",
  swimming = "swimming",
  triathlon = "triathlon",
  volleyball = "volleyball",
  other = "other"
}

export const PrimarySportValues: string[] = Object.values(PrimarySport)
 
export const PrimarySportLabels = {
  [PrimarySport.athletics]: "Athletics (track and field)",
  [PrimarySport.baseball]: "Baseball",
  [PrimarySport.basketball]: "Basketball",
  [PrimarySport.cycling]: "Cycling",
  [PrimarySport.golf]: "Golf",
  [PrimarySport.rugby]: "Rugby",
  [PrimarySport.sailing]: "Sailing",
  [PrimarySport.soccer]: "Soccer",
  [PrimarySport.softball]: "Softball",
  [PrimarySport.swimming]: "Swimming",
  [PrimarySport.triathlon]: "Triathlon",
  [PrimarySport.volleyball]: "Volleyball",
  [PrimarySport.other]: "Other"
}

export const PrimarySportFormOptions = Object.entries(PrimarySportLabels).map(([value, label]) => ({label, value}))

export enum Sport {
  default = "default",
  hockey = "hockey",
}

export enum SportTerminology {
  ATHLETE,
  EVENT,
  FACILITY,
  FACILITY_ADDRESS,
  FIELD,
  METRIC,
  SESSION,
}

export type SportTermLookup = Record<SportTerminology, string>;
