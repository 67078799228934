import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';

// Material UI
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';

// Data
import { ASSESSMENT_SESSION_FIELDS } from '../../../lib/queries/assessment-session.queries';
import { numToTime } from '../../../lib/helpers/conversion.helpers';
import { Box } from '@material-ui/core';
import { PrimaryButton } from '../../_core/_ui/buttons.component';
import { SEND_SESSION_COMMUNICATION } from '../../Session/AssessmentSessionList/assessment-session-list.component';

const GET_PLAYER_ASSESSMENT_SESSION_LIST = gql`
  query GetPlayerAssessmentSessionList($playerAssessmentId: ID!) {
    playerAssessmentSessions(playerAssessmentId: $playerAssessmentId) {
      ...AssessmentSessionFields
    }
  }

  ${ASSESSMENT_SESSION_FIELDS}
`;

interface IPlayerAssessmentSessionListGraphQL {
  playerAssessmentId: string;
}

const PlayerAssessmentSessionListGraphQL: React.FC<IPlayerAssessmentSessionListGraphQL> = ({ playerAssessmentId }) => {
  const { data, loading, error } = useQuery(GET_PLAYER_ASSESSMENT_SESSION_LIST, {
    variables: { playerAssessmentId },
    fetchPolicy: 'cache-and-network'
  });

  const [sendSessionCommunication] = useMutation(SEND_SESSION_COMMUNICATION)

  const sessions: IAssessmentSession[] = (data && data.playerAssessmentSessions) || [];

  return (
    <List>
      {sessions.map(session => (
        <ListItem key={session._id}>
          <Box display="flex" alignItems="center" justifyItems="space-between" width="100%">
            <ListItemText
              primary={session.location || session.address}
              secondary={(
                <>
                  {session.location && <>{session.address}<br /></>}
                  {dayjs(session.date).format('MMMM Do')} at {numToTime(session.start)} for {session.duration} minutes
                </>
              )}
            />
            <Box>
              <PrimaryButton
                onClick={() => {
                  if (window.confirm("Are you sure you want to resend notification?")) {
                    sendSessionCommunication({variables: {assessmentSessionId: session._id, playerAssessmentId, type: "SESSION_START"}})
                      .then((result) => alert(result ? "Resent notification" : "Failed to resend notification"))
                      .catch(() => alert("Failed to resend notification"))
                  }
                }
              }>
              Resend Assignment Notification
            </PrimaryButton>
          </Box>
        </Box>
        </ListItem>
      ))}
      {sessions.length === 0 && (
        <ListItem>
          <ListItemText primary="None" />
        </ListItem>
      )}
    </List>
  );
};

export default PlayerAssessmentSessionListGraphQL;
