import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import Fuse from 'fuse.js';

// Components
import DrillList from './drill-list.component';

interface IDrillListContainer {
  drills: IDrill[];
  loading: boolean;
  fetchError: boolean;
  removeDrill: Function;
  restoreDrill: Function;
  countArchivedDrills: number;
  viewingArchived: boolean;
  setViewingArchived: Function;
  removeDrillImage: Function;
  addDrillImage: Function;
  checkDrillDeleteEffects: Function;
  isAdmin: boolean;
}

const DrillListContainer: React.FC<IDrillListContainer> = ({
  drills,
  countArchivedDrills,
  loading,
  fetchError,
  removeDrill,
  restoreDrill,
  viewingArchived,
  setViewingArchived,
  addDrillImage,
  removeDrillImage,
  checkDrillDeleteEffects,
  isAdmin
}) => {
  const [selectedDrills, setSelectedDrills] = useState<IDrill[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDrills, setFilteredDrills] = useState(drills);
  const fuse = new Fuse(drills, { threshold: 0.3, keys: ['name'] });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (searchTerm.length) {
      const results = fuse.search(searchTerm);

      setFilteredDrills(_.map(results, 'item'));
    } else {
      setFilteredDrills(drills);
    }
  }, [drills, searchTerm]);

  const debounceSearch = useCallback(_.debounce((nextSearch: string) => {
    setSearchTerm(nextSearch)
  }, 300), []);

  const removeDrills = (_drills: IDrill[]) => {
    _drills.map(d => {
      checkDrillDeleteEffects(d._id, d.companyId)
        .then(({data: {checkDrillDeleteEffects: effects}}: {data: {checkDrillDeleteEffects: IDrillDeleteEffects}}) => {
          let affects = "";
          const assessmentEffectsLength = effects.assessments.length
          if (assessmentEffectsLength) affects += `${assessmentEffectsLength} assessments: ${effects.assessments.map((a: string) => `"${a}"`).join(", ")}`
          if (effects.practicePlans.length) affects += `${assessmentEffectsLength ? ", and " : ""}${effects.practicePlans.length} practice plans: ${effects.practicePlans.map((p: string) => `"${p}"`).join(", ")}`

          if (!affects.length) removeDrill(d._id, d.companyId)
          else if (window.confirm(`Deleting drill "${d.name}" will affect ${affects}. Are you sure you want to delete?`)) removeDrill(d._id, d.companyId)
        })
        .catch((err: Error) => console.error("Failed checking drill delete effects"))
    });
  };

  const restoreDrills = (_drills: IDrill[]) =>
    _drills.map(({_id, companyId}) => restoreDrill(_id, companyId));

  return <DrillList
    loading={loading}
    fetchError={fetchError}
    drills={filteredDrills}
    countArchivedDrills={countArchivedDrills}
    searchDrills={debounceSearch}
    removeDrills={removeDrills}
    restoreDrills={restoreDrills}
    viewingArchived={viewingArchived}
    setViewingArchived={setViewingArchived}
    selectedDrills={selectedDrills}
    setSelectedDrills={setSelectedDrills}
    addDrillImage={addDrillImage}
    removeDrillImage={removeDrillImage}
    isAdmin={isAdmin}
  />;
};

export default DrillListContainer;
