import _ from 'lodash';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

// Data
import { isValidEmail, isValidPhone } from '../../../lib/helpers/validation.helpers';

// Components
import PlayerContactForm, { IPlayerContactFormFields } from './player-contact-form.component';
import { ContactRelationship, ContactRelationshipFormOptions, ContactRelationshipLabels, SportInvolvement } from '../../../lib/types';
import { IFormOption } from '../../_core/_ui/forms.component';
import { SPORT_INVOLVEMENT_OPTIONS } from '../../../lib/constants';

interface PlayerContactFormContainerProps {
  contact?: IPlayerContact;
  onSubmit: Function;
  onCancel?: Function;
}

const PlayerContactFormContainer: React.FC<PlayerContactFormContainerProps> = ({
  onSubmit,
  onCancel,
  contact
}) => {
  const INITIAL_FORM_VALUES: IPlayerContactFormFields = {
    name: contact?.name || '',
    email: contact?.email,
    phone: contact?.phone,
    sendSMS: contact && _.isBoolean(contact?.sendSMS) ? contact.sendSMS : false,
    sendEmail: contact && _.isBoolean(contact?.sendEmail) ? contact.sendEmail : true,
    height: contact?.height,
    sportInvolvement: contact?.sportInvolvement ?
      SPORT_INVOLVEMENT_OPTIONS.find((option: IFormOption) => option.value === contact.sportInvolvement as unknown as SportInvolvement) :
      undefined,
    relationship: contact?.relationship ?
      ContactRelationshipFormOptions.find(o => o.value == contact.relationship as ContactRelationship) :
      undefined
  };

  async function handleSubmit(values: IPlayerContactFormFields) {
    onSubmit(values);
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Enter a name'),
    email: Yup.string().test('valid-email', 'Email is invalid', (value) => value === '' || !value || isValidEmail(value)),
    phone: Yup.string().test('valid=phone', 'Phone is invalid', (value) => value === '' || !value || isValidPhone(value)),
    height: Yup.number().nullable().test('valid-height', 'Height must be a number', (value) => value === '' || !value || !isNaN(value)),
  });

  return (
    <Formik
      initialValues={INITIAL_FORM_VALUES}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {form => <PlayerContactForm {...{ form, onCancel }} />}
    </Formik>
  );
};

export default PlayerContactFormContainer;
