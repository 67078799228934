import React from 'react';

// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Components
import DashboardLayout from '../_layout/DashboardLayout/dashboard.layout';
import { PageTitle, SectionHeading } from '../../_core/_ui/typography.component';
import InviteListContainer from '../../Invite/InviteList/invite-list.container';
import InviteFormContainer from '../../Invite/InviteForm/invite-form.container';
import { SectionWrapper } from '../../_core/_ui/structure.components';
import MemberListGraphQL from '../../Member/MemberList/member-list.graphql';
import AssessmentInfoGraphQL from '../../Assessment/AssessmentInfo/assessment-info.graphql';
import UpdateCompanyFormContainer from '../../Company/UpdateCompanyForm/update-company-form.container';
import { getActiveCompanyId, getCompany } from '../../../lib/services/company.service';

const SettingsPage = () => {
  const companyId = getActiveCompanyId();
  const { company, loading, error, refetch } = getCompany(companyId);

  return (
    <DashboardLayout hideSidebar>
      <PageTitle>Manage Settings</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionWrapper style={{ maxWidth: '800px' }}>
            {loading && !company && <Typography>Loading...</Typography>}
            {company &&  <UpdateCompanyFormContainer company={company} refetch={refetch} />}
          </SectionWrapper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default SettingsPage;
