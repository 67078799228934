import _ from 'lodash';
import React, { ReactNode, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import Select from 'react-select';
import { isPlatform } from '@ionic/react';

// Material UI
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Data
import { numToTime } from '../../../lib/helpers/conversion.helpers';
import { FormLabel, IFormOption } from '../../_core/_ui/forms.component';

// Components
import { JerseyIcon } from '../../Session/CheckIn/check-in.helpers';
import {
  JerseyIconList,
  JerseyIconWrapper,
  JerseyPlayerList,
  JerseyPlayerWrapper,
  StatusIcon
} from './manage-evaluations.helpers';
import { PageHeaderRow, SpacedRow, Spacer } from '../../_core/_ui/structure.components';
import { DangerButton, OutlineButton, PrimaryWhiteButton, SecondaryButton } from '../../_core/_ui/buttons.component';
import { FormControl, IconButton, Modal } from '@material-ui/core';
import EvaluateScoreSkill from './evaluate-score-skill.component';
import EvaluateTimedSkill from './evaluate-timed-skill.component';
import { getAuthUser } from '../../../lib/services/auth.service';
import ReviewEvaluations from './review-evaluations.component';
import { getOnlineStatus, getSyncing } from '../../_core/PersistGateContainer/persist-gate.container';
import { hasAnyRoles } from '../../../lib/services/user.service';
import ImageIcon from '@material-ui/icons/Image';
import { SkillMeasurementType, SportTerminology } from '../../../lib/types';
import EvaluateNumberSkill from './evaluate-number-skill.component';
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { videoSrc } from '../../../lib/utils/video.utils';

interface IManageEvaluations {
  loading: boolean;
  fetchError: boolean;
  session?: IAssessmentSession;
  jerseys: { [key: string]: IJersey };
  removeScore: Function;
  recordScore: Function;
  playersByPosition: { [key: string]: IPlayerAssessment[] };
  playersById: { [key: string]: IPlayerAssessment };
  playersLinear: IPlayerAssessment[];
  currPlayerIndex: number;
  setCurrPlayerIndex: Function;
  playerOptions: IFormOption[];
  drills: IDrill[];
  drillsById: { [key: string]: IDrill };
  drillOptions: IFormOption[];
  calibrationVideos: ICalibrationVideo[];
  activeComposite?: number;
  setActiveComposite: Function;
  setChosenVideo: Function;
  chosenVideos: { [key: string]: { [key: string]: { [key: number]: ICalibrationVideo } } };
  isComposite?: boolean;
  activePlayer?: IPlayerAssessment;
  setActivePlayer: Function;
  activeDrill?: IDrill;
  setActiveDrill: Function
  activeRound: number;
  setActiveRound: Function;
  hasCalibrationVideos: boolean;
  getScoreStatus: (playerAssessment: IPlayerAssessment, drillId: string, round: number) => string;
  getCompositeScoreStatus: (index: number, drillId: string, round: number) => string;
  scoreHash: { [key: string]: { [key: string]: { [key: string]: { [key: number]: IScore } } } };
  compositeScoreHash: { [key: string]: { [key: string]: { [key: string]: { [key: number]: ICompositeScore } } } };
  removeCompositeScore: Function;
  recordCompositeScore: Function;
  showReview: boolean;
  setShowReview: Function;
  canCheckIn: boolean;
  submitScores: Function;
  hasSaveError: boolean;
  hasUnsavedScores: boolean;
  isSaving: boolean;
}

const COMPOSITE_JERSEY_COLOR = 'purple';

const ManageEvaluations: React.FC<IManageEvaluations> = ({
  loading,
  fetchError,
  session,
  jerseys,
  removeScore,
  recordScore,
  playersByPosition,
  playersById,
  playersLinear,
  currPlayerIndex,
  setCurrPlayerIndex,
  playerOptions,
  drillsById,
  drillOptions,
  calibrationVideos,
  setChosenVideo,
  chosenVideos,
  activeComposite,
  isComposite,
  setActiveComposite,
  activePlayer,
  setActivePlayer,
  drills,
  activeDrill,
  setActiveDrill,
  activeRound,
  setActiveRound,
  hasCalibrationVideos,
  getScoreStatus,
  getCompositeScoreStatus,
  scoreHash,
  compositeScoreHash,
  removeCompositeScore,
  recordCompositeScore,
  showReview,
  setShowReview,
  canCheckIn,
  submitScores,
  hasUnsavedScores,
  hasSaveError,
  isSaving
}) => {
  const termLookup = getCompanySportTerminology();
  const athleteTerm = termLookup[SportTerminology.ATHLETE];
  const athletesTerm = pluralTerm(athleteTerm);
  const eventTerm = termLookup[SportTerminology.EVENT];

  const isOnline = getOnlineStatus();
  const isSyncing = getSyncing();
  const user = getAuthUser();
  const history = useHistory();
  const [showDiagram, setShowDiagram] = useState<string | null>(null);
  const showPlayerNames = hasAnyRoles(['PLAYER_NAMES']);

  const renderJerseyList = useCallback(() => {
    if (_.isEmpty(playersByPosition))
      return <JerseyIconWrapper style={{ width: '100%', justifyContent: 'center' }} isLabel>No {athletesTerm} Checked-In</JerseyIconWrapper>

    let playerRenderCount = 0;
    let compositeRenderCount = 0;

    const WrapperComponent = showPlayerNames ? JerseyPlayerWrapper : JerseyIconWrapper
    const jerseyList = _.map(playersByPosition, (players, positionName) => (
      <React.Fragment key={`jerseys_${positionName}`}>
        <JerseyIconWrapper isLabel>{positionName}</JerseyIconWrapper>
        {players.reduce<ReactNode[]>((result: ReactNode[], player, index) => {
          result.push(
            <WrapperComponent key={player._id} active={player._id === activePlayer?._id && !isComposite} onClick={() => setActivePlayer(player)}>
              <JerseyIcon color={jerseys[player._id]?.color.toLowerCase()}>{jerseys[player._id]?.number}</JerseyIcon>
              {showPlayerNames && <Box mx={2} textAlign="left"><Typography>{player.player.firstName.toUpperCase()} {player.player.lastName.toUpperCase()}</Typography></Box>}
              {activeDrill && <StatusIcon status={getScoreStatus(player, activeDrill._id, activeRound)} />}
            </WrapperComponent>
          );

          playerRenderCount += 1;
          if (hasCalibrationVideos) {
            const isNextTwelfth = (playerRenderCount % 11) === 0;
            const isLastPlayer = index === players.length - 1;
            const noneRendered = compositeRenderCount === 0;

            if (isNextTwelfth || (isLastPlayer && noneRendered)) {
              result.push(
                <WrapperComponent key={`composite_${compositeRenderCount}`} active={isComposite && activeComposite === compositeRenderCount} onClick={((index) => { return () => setActiveComposite(index) })(compositeRenderCount)}>
                  <JerseyIcon color={COMPOSITE_JERSEY_COLOR}>C{compositeRenderCount + 1}</JerseyIcon>
                  {showPlayerNames && <Box mx={2} textAlign="left"><Typography>Composite {athleteTerm}</Typography></Box>}
                  {activeDrill && <StatusIcon status={getCompositeScoreStatus(compositeRenderCount, activeDrill._id, activeRound)} />}
                </WrapperComponent>
              );
              compositeRenderCount += 1;
            }
          }

          return result;
        }, [])}
      </React.Fragment>
    ))

    if (showPlayerNames) return <JerseyPlayerList>{jerseyList}</JerseyPlayerList>
    return  <JerseyIconList style={{ top: (isOnline && !isSyncing) ? 0 : '48px' }}>{jerseyList}</JerseyIconList>
  }, [activeDrill, activePlayer?._id, activeRound, athletesTerm, hasCalibrationVideos, isComposite, isOnline, isSyncing, jerseys, playersByPosition, showPlayerNames, getScoreStatus, getCompositeScoreStatus])

  if (loading && !session) return <Typography>Loading...</Typography>;
  if (!session) return <Typography>Unable to load evaluations at this time. Please try again later</Typography>;

  const eleSkills = isComposite
    ? renderComposite({
      calibrationVideos,
      activeComposite,
      activeDrill,
      activeRound,
      user,
      session,
      compositeScoreHash,
      removeCompositeScore,
      recordCompositeScore,
      setChosenVideo,
      chosenVideos
    }) : renderSkills({
    activeDrill,
    activePlayer,
    activeRound,
    user,
    session,
    scoreHash,
    removeScore,
    recordScore
  });

  let scoreStatusBg = 'transparent';
  let scoreStatusColor = '#000';

  if (isSaving) {
    scoreStatusBg = '#fff';
  } else if (!isOnline && hasUnsavedScores && !isSaving) {
    scoreStatusBg = '#ed4337';
    scoreStatusColor = '#fff';
  } else if (isOnline && hasUnsavedScores && !isSaving) {
    scoreStatusBg = '#fff';
  }

  return (
    <div>
      {renderJerseyList()}

      <Box ml={showPlayerNames ? 30 : 0}>
        <Box mt={showPlayerNames ? 0 : 10} display="flex">
          <Box>
            <Typography variant="h6">{session.location} - {session.area}</Typography>
            <Typography variant="subtitle1">{session.ageGroup.name} | {dayjs(session.date).format('MMMM Do')} @ {numToTime(session.start)}</Typography>
          </Box>

          <Spacer />

          <SecondaryButton startIcon={<ArrowBackIosIcon />} onClick={() => history.push('/upcoming')}>Home</SecondaryButton>
          {canCheckIn && (
            <PrimaryWhiteButton
              style={{ marginLeft: '16px' }}
              onClick={() => history.push(`/check-in/${session._id}`)}
            >Check-In {athletesTerm}</PrimaryWhiteButton>
          )}
          <PrimaryWhiteButton
            disabled={isSaving || !hasUnsavedScores || !isOnline}
            style={{ marginLeft: '16px', backgroundColor: scoreStatusBg, color: scoreStatusColor, width: '139px' }}
            onClick={() => submitScores()}
          >
            {isSaving && 'Saving...'}
            {isOnline && hasUnsavedScores && !isSaving && 'Submit Scores'}
            {!isOnline && hasUnsavedScores && !isSaving && 'No Internet'}
            {!hasUnsavedScores && !isSaving && 'Scores Saved'}
          </PrimaryWhiteButton>
        </Box>
        {hasSaveError && (
          <Box>
            <Typography variant="caption">An error occurred when saving. Please check your internet connection and try again</Typography>
          </Box>
        )}

        <Box my={2}>
          {/* Navigation here */}
          {!showReview && (
            <Paper>
              <PageHeaderRow style={{ marginBottom: -8, paddingLeft: 16, paddingRight: 16, paddingTop: 16 }}>
                {
                  showPlayerNames && activePlayer
                    ? <Typography variant="h6">{isComposite ? `Composite ${athleteTerm} #${activeComposite || 0 + 1}` : `${activePlayer?.player.firstName} ${activePlayer?.player.lastName}`}</Typography>
                    : <div />
                }
              </PageHeaderRow>
              <SpacedRow>
                {!isComposite && <JerseyIcon color={activePlayer && jerseys[activePlayer._id]?.color?.toLowerCase()}>{activePlayer && jerseys[activePlayer._id]?.number}</JerseyIcon>}
                {isComposite && <JerseyIcon color="purple">{`C${activeComposite || 0 + 1}`}</JerseyIcon>}
                {!isComposite && <FormControl style={{ width: '142px' }}>
                  <FormLabel>Active {athleteTerm}</FormLabel>
                  <Select
                    loading={!activePlayer}
                    options={playerOptions}
                    value={activePlayer && { value: activePlayer._id, label: `${_.capitalize(jerseys[activePlayer._id]?.color)} - ${jerseys[activePlayer._id]?.number}` }}
                    isSearchable={false}
                    isOptionDisabled={option => option.value === activePlayer?._id}
                    onChange={option => option && setActivePlayer(playersById[option.value])}
                    noOptionsMessage={() => `No ${athletesTerm} Assigned`}
                  />
                </FormControl>}
                <FormControl style={{ width: '310px' }}>
                  <FormLabel>Active {eventTerm}</FormLabel>
                  <Select
                    options={drillOptions}
                    loading={!activeDrill}
                    value={activeDrill && { value: activeDrill._id, label: activeDrill.name }}
                    isSearchable={false}
                    isOptionDisabled={option => option.value === activeDrill?._id}
                    onChange={option => option && setActiveDrill(drillsById[option.value])}
                    noOptionsMessage={() => `No ${pluralTerm(eventTerm)} Assigned`}
                  />
                </FormControl>
                {activeDrill?.image && <IconButton style={{ marginTop: '20px' }} onClick={() => setShowDiagram(activeDrill.image)}>
                  <ImageIcon />
                </IconButton>}
                <FormControl style={{ width: '120px' }}>
                  <FormLabel>Active Round</FormLabel>
                  <Select
                    options={Array(5).fill(undefined).map((_, i) => ({ value: String(i + 1), label: String(i + 1) }))}
                    value={{ value: String(activeRound), label: String(activeRound) }}
                    isSearchable={false}
                    isOptionDisabled={option => Number(option.value) === activeRound}
                    onChange={option => option && setActiveRound(Number(option.value))}
                  />
                </FormControl>
                <Spacer />
                <SecondaryButton
                  disabled={currPlayerIndex === 0}
                  onClick={() => setActivePlayer(playersLinear[currPlayerIndex - 1])}
                >Previous {athleteTerm}</SecondaryButton>
                <SecondaryButton
                  disabled={currPlayerIndex === playersLinear.length - 1}
                  onClick={() => setActivePlayer(playersLinear[currPlayerIndex + 1])}
                >Next {athleteTerm}</SecondaryButton>
                <OutlineButton onClick={() => setShowReview(!showReview)}>Review Scores</OutlineButton>
              </SpacedRow>
              <Divider />
              {activeDrill && (
                <Box p={2}>
                  <Typography variant="h6" gutterBottom>{activeDrill.name}</Typography>
                  {eleSkills}
                  {_.isArray(eleSkills) && eleSkills.length === 0 && activePlayer && <Typography>{eventTerm} does not apply to this {athleteTerm}'s position</Typography>}
                  {!activePlayer && <Typography>No {athleteTerm} selected</Typography>}
                </Box>
              )}
              {!activeDrill && (
                <Box p={2}><Typography>No {eventTerm.toLowerCase()} selected</Typography></Box>
              )}
            </Paper>
          )}

          {showReview && (
            <Paper>
              <Box p={2} display="flex">
                <Typography variant="h6">Review Scores</Typography>
                <Spacer />
                <OutlineButton onClick={() => setShowReview(false)}>Score {athletesTerm}</OutlineButton>
              </Box>
              <ReviewEvaluations
                players={_.values(playersById)}
                jerseys={jerseys}
                drills={drills}
                scoreHash={scoreHash}
                setActiveDrill={setActiveDrill}
                setActivePlayer={setActivePlayer}
                setActiveRound={setActiveRound}
                setShowReview={setShowReview}
              />
            </Paper>
          )}

          <Modal
            open={Boolean(showDiagram)}
            onClose={() => setShowDiagram(null)}
            className="diagram-modal"
          >
            <Paper style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Box p={2}>
                {showDiagram && <img src={showDiagram} style={{ minWidth: '400px', maxWidth: '100%' }} alt="Broken Image. Please re-upload" />}
              </Box>
            </Paper>
          </Modal>
        </Box>
      </Box>
    </div>
  );
};

interface IRenderComposite {
  calibrationVideos: ICalibrationVideo[];
  activeComposite?: number;
  activeDrill?: IDrill;
  activeRound: number;
  user: IUser | null;
  compositeScoreHash: { [key: string]: { [key: string]: { [key: string]: { [key: number]: ICompositeScore } } } };
  session: IAssessmentSession;
  removeCompositeScore: Function;
  recordCompositeScore: Function;
  setChosenVideo: Function;
  chosenVideos: { [key: string]: { [key: string]: { [key: number]: ICalibrationVideo } } };
}

const renderComposite = ({
  activeComposite,
  calibrationVideos,
  activeDrill,
  activeRound,
  user,
  session,
  compositeScoreHash,
  removeCompositeScore,
  recordCompositeScore,
  setChosenVideo,
  chosenVideos
}: IRenderComposite) => {
  const eleSkills: ReactNode[] = [];

  if (activeDrill && typeof activeComposite === 'number' && user) {
    const video = chosenVideos?.[activeComposite]?.[activeDrill._id]?.[activeRound] || getCalibrationVideo(calibrationVideos, activeDrill._id);
    if (!video) return [];

    const relevantVideos = calibrationVideos.filter((currVideo) => currVideo.filename === video.filename).sort((a, b) => a.skill.name.localeCompare(b.skill.name));

    for (let i = 0; i < relevantVideos.length; i += 1) {
      const context: any = {
        drillId: activeDrill._id,
        skillId: relevantVideos[i].skill._id,
        assessmentSessionId: session._id,
        compositeIndex: activeComposite,
        calibrationVideoId: relevantVideos[i]._id,
        round: activeRound
      };

      const currScore = _.get(compositeScoreHash, [activeComposite, activeDrill._id, relevantVideos[i].skill._id, activeRound]);
      if (currScore) {
        context._id = currScore._id;
      }

      eleSkills.push(
        <RenderCompositeSkill
          key={`${activeDrill._id}_${relevantVideos[i].skill._id}`}
          context={context}
          score={currScore}
          video={relevantVideos[i]}
          setChosenVideo={setChosenVideo}
          activeRound={activeRound}
          activeComposite={activeComposite}
          recordCompositeScore={recordCompositeScore}
          removeCompositeScore={removeCompositeScore}
        />
      );
    }

    return <Box key="calibration_videos" mb={2}>
      <p>Please watch the video below and score the skater as if they were part of this evaluation</p>
      <Box mb={2}><video key={video.filename} controls loop muted playsInline preload="auto"><source src={`${videoSrc}/${video.filename}`} type="video/mp4" /></video></Box>
      {eleSkills}
    </Box>;
  }

  return [];
}

const getCalibrationVideo = (calibrationVideos: ICalibrationVideo[], drillId: string) => {
  const activeDrillVideos = _.filter(calibrationVideos, { drillId });
  if (activeDrillVideos.length === 0) return;

  const randomIndex = Math.floor(Math.random() * activeDrillVideos.length);

  return activeDrillVideos[randomIndex];
};

interface IRenderCompositeSkill {
  context: {  [key: string]: any };
  video: ICalibrationVideo;
  setChosenVideo: Function;
  score?: IScore;
  activeRound: number;
  activeComposite: number;
  recordCompositeScore: Function;
  removeCompositeScore: Function;
}

const RenderCompositeSkill: React.FC<IRenderCompositeSkill> = ({
  context,
  video,
  setChosenVideo,
  score,
  activeRound,
  activeComposite,
  recordCompositeScore,
  removeCompositeScore
}) => {
  return (
    <Box style={{ borderBottom: '1px solid #ddd' }} mb={1} pb={1}>
      <Typography key={`${video.skill._id}_title`} gutterBottom>{video.skill.name}</Typography>
      {video.skill.type === 'TIMED' && (
        <EvaluateTimedSkill
          key={video.skill._id}
          skill={video.skill}
          roundNum={activeRound}
          score={score}
          removeScore={removeCompositeScore}
          saveScore={(value: string) => {
            recordCompositeScore({ ...context, raw: String(value), type: 'TIME', clientId: score?.clientId })
          }}
        />
      )}
      {video.skill.type === 'SCORE' && (
        <EvaluateScoreSkill
          key={video.skill._id}
          skill={video.skill}
          score={score}
          removeScore={removeCompositeScore}
          saveScore={(value: string) => {
            setChosenVideo(activeComposite, video.drillId, activeRound, video);
            recordCompositeScore({ ...context, raw: String(value), type: 'NUMBER', clientId: score?.clientId })
          }}
        />
      )}
      {(video.skill.type === SkillMeasurementType.INTEGER || video.skill.type === SkillMeasurementType.FLOAT) && (
        <EvaluateNumberSkill
          key={video.skill._id}
          allowedDecimals={video.skill.type === SkillMeasurementType.FLOAT ? 2 : undefined}
          skill={video.skill}
          score={score}
          removeScore={removeCompositeScore}
          saveScore={(value: string) => {
            setChosenVideo(activeComposite, video.drillId, activeRound, video);
            recordCompositeScore({ ...context, raw: String(value), type: 'NUMBER', clientId: score?.clientId })
          }}
        />
      )}
    </Box>
  );
}

interface IRenderSkills {
  activeDrill?: IDrill;
  activePlayer?: IPlayerAssessment;
  activeRound: number;
  user: IUser | null;
  scoreHash: { [key: string]: { [key: string]: { [key: string]: { [key: number]: IScore } } } };
  session: IAssessmentSession;
  removeScore: Function;
  recordScore: Function;
}

const renderSkills = ({
  activeDrill,
  activePlayer,
  activeRound,
  user,
  session,
  scoreHash,
  removeScore,
  recordScore
}: IRenderSkills) => {
  const eleSkills: ReactNode[] = [];

  if (activeDrill && activePlayer && user) {
    activeDrill.skillSets.forEach((skillSet: ISkillSet) => {
      if (_.includes(skillSet.positionIds, activePlayer.position._id)) {
        skillSet.skills?.forEach((skill, index) => {
          if (!skill.deletedAt) {
            const context: any = {
              drillId: activeDrill._id,
              skillId: skill._id,
              assessmentSessionId: session._id,
              playerAssessmentId: activePlayer._id,
              assessmentId: session.assessmentId,
              ageGroupId: activePlayer.ageGroup._id,
              round: activeRound
            };

            const currScore = _.get(scoreHash, [activePlayer._id, activeDrill._id, skill._id, activeRound]);
            if (currScore) {
              context._id = currScore._id;
            }


            eleSkills.push(<RenderSkill
              key={`${activeDrill._id}_${skill._id}`}
              context={context}
              score={currScore}
              skill={skill}
              activeRound={activeRound}
              activePlayer={activePlayer}
              recordScore={recordScore}
              removeScore={removeScore}
            />);
            }
        });
      }
    });
  }

  return eleSkills;
}

interface IRenderSkill {
  context: {  [key: string]: any };
  skill: ISkill;
  score?: IScore;
  activeRound: number;
  activePlayer?: IPlayerAssessment;
  recordScore: Function;
  removeScore: Function;
}

const RenderSkill: React.FC<IRenderSkill> = ({
  context,
  skill,
  score,
  activeRound,
  activePlayer,
  recordScore,
  removeScore
}) => {
  return (
    <Box style={{ borderBottom: '1px solid #ddd' }} mb={1} pb={1}>
      <Typography key={`${skill._id}_title`} gutterBottom>{skill.name}</Typography>
      {skill.type === SkillMeasurementType.TIMED && (
        <EvaluateTimedSkill
          key={skill._id}
          skill={skill}
          roundNum={activeRound}
          activePlayer={activePlayer}
          score={score}
          removeScore={removeScore}
          saveScore={(value: string) => {
            recordScore({ ...context, raw: String(value), type: 'TIME', clientId: score?.clientId })
          }}
        />
      )}
      {skill.type === SkillMeasurementType.SCORE && (
        <EvaluateScoreSkill
          key={skill._id}
          skill={skill}
          score={score}
          removeScore={removeScore}
          saveScore={(value: string) => {
            recordScore({ ...context, raw: String(value), type: 'NUMBER', clientId: score?.clientId })
          }}
        />
      )}
      {(skill.type === SkillMeasurementType.INTEGER || skill.type === SkillMeasurementType.FLOAT) && (
        <EvaluateNumberSkill
          key={skill._id}
          allowedDecimals={skill.type === SkillMeasurementType.FLOAT ? 2 : undefined}
          skill={skill}
          score={score}
          removeScore={removeScore}
          saveScore={(value: string) => {
            recordScore({ ...context, raw: String(value), type: 'NUMBER', clientId: score?.clientId })
          }}
        />
      )}
    </Box>
  );
}

export default ManageEvaluations;
