import React from 'react';

// Components
import DashboardLayout from '../_layout/DashboardLayout/dashboard.layout'; 
import ManageAgeGroupsGraphQL from '../../AgeGroup/ManageAgeGroups/manage-age-groups.graphql';
import { PageHeaderRow } from '../../_core/_ui/structure.components';
import { SectionHeading } from '../../_core/_ui/typography.component';

const DashboardPage = () => {
  return (
    <DashboardLayout>
      <PageHeaderRow><SectionHeading>Dashboard</SectionHeading></PageHeaderRow>
      <ManageAgeGroupsGraphQL />
    </DashboardLayout>
  );
};

export default DashboardPage;
