import React from 'react';
import { Field, Form, FormikProps } from 'formik';

// Material UI
import Box from '@material-ui/core/Box';

// Data
import getMessages from './player-contact-form.messages';

// Components
import FormikInput from '../../_core/Formik/formik-input.component';
import FormControl from '@material-ui/core/FormControl';
import { PrimaryButton, SecondaryButton } from '../../_core/_ui/buttons.component';
import IntlMsg from '../../_core/IntlMsg/intl-msg.component';
import { Spacer } from '../../_core/_ui/structure.components';
import FormikCheckbox from '../../_core/Formik/formik-checkbox.component';
import FormikSelect from '../../_core/Formik/formik-select.component';
import { SPORT_INVOLVEMENT_OPTIONS } from '../../../lib/constants';
import { IFormOption } from '../../_core/_ui/forms.component';
import { getCompanySport, getCompanySportTerminology } from '../../../lib/services/company.service';
import { ContactRelationshipFormOptions, Sport } from '../../../lib/types';

export interface IPlayerContactFormFields {
  name: string;
  email?: string;
  phone?: string;
  sendSMS: boolean;
  sendEmail: boolean;
  height?: number;
  sportInvolvement?: IFormOption;
  relationship?: IFormOption;
}

interface IPlayerContactForm {
  form: FormikProps<IPlayerContactFormFields>;
  onCancel?: Function;
}

const PlayerContactForm: React.FC<IPlayerContactForm> = ({ form, onCancel }) => {
  const { isSubmitting, isValid, errors } = form;

  const sport = getCompanySport()
  const termLookup = getCompanySportTerminology();
  const messages = getMessages(termLookup)

  return (
    <Form>
      <Field fid="cpcf" name="name" margin="dense" component={FormikInput} label={messages.lblName} />
      {sport === Sport.default &&
        <>
          <Field
            fid="cpf"
            name="relationship"
            component={FormikSelect}
            label={messages.lblContactRelationship}
            options={ContactRelationshipFormOptions}
          />
          <Field
            margin="dense"
            fid="cpcf"
            name="height"
            component={FormikInput}
            label={messages.lblContactHeight}
            type="number"
          />
          <Field
            fid="cpcf"
            name="sportInvolvement"
            component={FormikSelect}
            label={messages.lblContactSportInvolvement}
            options={SPORT_INVOLVEMENT_OPTIONS}
          />
        </>
      }
      <Field fid="cpcf" name="email" margin="dense" component={FormikInput} label={messages.lblEmail} />
      <Field fid="cpcf" id="cpcf_sendEmail" name="sendEmail" component={FormikCheckbox} label={messages.lblSendEmail} />
      <Field fid="cpcf" name="phone" margin="dense" component={FormikInput} label={messages.lblPhone} />
      <Field fid="cpcf" id="cpcf_sendSMS" name="sendSMS" component={FormikCheckbox} label={messages.lblSendSMS} />

      <FormControl margin="dense">
        <Box display="flex" flexDirection="row">
          <PrimaryButton disabled={isSubmitting || !isValid} type="submit">
            {isSubmitting ? <IntlMsg {...messages.btnSubmitting} /> : <IntlMsg {...messages.btnSubmit} />}
          </PrimaryButton>
          <Spacer />
          {onCancel && <SecondaryButton onClick={() => onCancel()}>Cancel</SecondaryButton>}
        </Box>
      </FormControl>
    </Form>
  );
};

export default PlayerContactForm;
