import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/Image';
import { Popover } from '@material-ui/core';
import { PopoverItem } from '../../_core/_ui/general.components';

interface IDrillListImageButton {
  user: IUser | null;
  drill: IDrill;
  removeDrillImage: Function;
  setShowDiagram: (image: string) => void;
}

const DrillListImageButton: React.FC<IDrillListImageButton> = ({ user, drill, removeDrillImage, setShowDiagram }) => {
  const [eleAnchor, setAnchor] = useState(null);

  const onRemoveDrillImage = (drillId: string) => {
    removeDrillImage(drillId);
    setAnchor(null);
  };

  return (
    <>
      <IconButton id={`anchor=${drill._id}`} onClick={(e: any) => user?.isAdmin ? setAnchor(e.currentTarget) : setShowDiagram(drill.image)}>
        <ImageIcon />
      </IconButton>
      <Popover
        anchorEl={eleAnchor}
        open={Boolean(eleAnchor)}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <PopoverItem onClick={() => { setShowDiagram(drill.image); setAnchor(null); }}>View Image</PopoverItem>
        <PopoverItem onClick={() => onRemoveDrillImage(drill._id)}>Remove Image</PopoverItem>
      </Popover>
    </>
  );
};

export default DrillListImageButton;
