import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';

// Components
import DashboardLayout from '../_layout/DashboardLayout/dashboard.layout';
import { getAgeGroups } from '../../../lib/services/age-group.service';
import { IFormOption } from '../../_core/_ui/forms.component';
import Typography from '@material-ui/core/Typography';
import Reports from '../../Score/Reports/reports.component';
import { Sport, SportTerminology } from '../../../lib/types';
import { getCompanySport, getCompanySportTerminology } from '../../../lib/services/company.service';
import { PageHeaderRow } from '../../_core/_ui/structure.components';

interface IReportsPage {
  match: any;
}

const ReportsPage: React.FC<IReportsPage> = ({ match }) => {
  const history = useHistory();
  const { ageGroupId } = match.params;
  const { ageGroups } = getAgeGroups();
  const sport = getCompanySport();
  const termLookup = getCompanySportTerminology();
  const positions: String[] = ['goalie'];

  const [ageGroupOptions, setAgeGroupOptions] = useState<IFormOption[]>([]);
  const [currAgeGroupOption, setCurrAgeGroupOption] = useState<IFormOption>();
  const [currAgeGroup, setCurrAgeGroup] = useState<IAgeGroup>();
  const [positionFilter, setPositionFilter] = useState<String|null>(positions[0]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setAgeGroupOptions(
      ageGroups.map((ageGroup: IAgeGroup) => ({ value: ageGroup._id, label: ageGroup.name }))
    );

    const foundAgeGroup = _.find(ageGroups, ageGroup => ageGroup._id === ageGroupId);
    if (foundAgeGroup) {
      setCurrAgeGroupOption({value: foundAgeGroup._id, label: foundAgeGroup.name});
      setCurrAgeGroup(foundAgeGroup);
    }
  }, [ageGroups.length]);

  return (
    <DashboardLayout hideSidebar>
      <PageHeaderRow style={{marginTop: 4}}><Typography variant="h6">Reports</Typography></PageHeaderRow>
      {currAgeGroupOption && (
        <Box width="200px" mt={1}>
          <Select
            options={ageGroupOptions}
            defaultValue={currAgeGroupOption}
            onChange={option => option && history.push(`/reports/${option.value}`)}
          />
        </Box>
      )}
      {sport !== Sport.default &&
        <Box my={2}>
          <Reports ageGroupId={ageGroupId} ageGroup={currAgeGroup} />
        </Box>
      }
       <Box width="200px" mt={1}>
          <Select
            options={positions}
            defaultValue={positions[0]}
            onChange={option => setPositionFilter(option)}
          />
        </Box>
      <Box my={2}>
        {sport === Sport.default &&
          (<>
            <iframe frameBorder="0" src={`https://athlete-comparison-data-viz-icvpkfz2za-uc.a.run.app/dash/?associationId=${currAgeGroup?.assessmentId}&ageGroupId=${ageGroupId}`} width="100%" height="960px" title={`${termLookup[SportTerminology.ATHLETE]} Reports`} />
            <iframe frameBorder="0" src={`https://athlete-and-cohort-data-viz-icvpkfz2za-uc.a.run.app/dash/?associationId=${currAgeGroup?.assessmentId}&ageGroupId=${ageGroupId}`} width="100%" height="960px" title="Cohort Reports" />
          </>)
        }
        {sport === Sport.hockey &&
          (<>
            <iframe frameBorder="0" src={`https://player-comparison-data-viz-icvpkfz2za-uc.a.run.app/dash/?associationId=${currAgeGroup?.assessmentId}&ageGroupId=${ageGroupId}`} width="100%" height="960px" title={`${termLookup[SportTerminology.ATHLETE]} Reports`} />
            <iframe frameBorder="0" src={`https://player-and-cohort-data-viz-icvpkfz2za-uc.a.run.app/dash/?associationId=${currAgeGroup?.assessmentId}&ageGroupId=${ageGroupId}`} width="100%" height="960px" title="Cohort Reports" />
          </>)
        }
      </Box>
    </DashboardLayout>
  );
};

export default ReportsPage;
