import React, { useState } from 'react';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

// Data
import { getAuthUser } from '../../../lib/services/auth.service';

// Components
import DrillListContainer from './drill-list.container';
import { buildCheckDrillDeleteEffects, buildRemoveDrill, buildRemoveDrillImage, buildRestoreDrill } from '../../../lib/services/drill.service';
import _ from 'lodash';
import { handleDrillImageUpload } from '../drill.helpers';

// Queries
const GET_DRILL_LIST = gql`
  query GetDrillList($companyId: ID!, $includeGlobal: Boolean) {
    drills(companyId: $companyId, includeGlobal: $includeGlobal) {
      _id
      name
      image
      skillSets {
        positions {
          name
        }
        skills {
          deletedAt
          name
        }
      }
      companyId
    }
    countArchivedDrills(companyId: $companyId)
  }
`;

const GET_ARCHIVED_DRILL_LIST = gql`
  query GetArchivedDrillList($companyId: ID!) {
    archivedDrills(companyId: $companyId) {
      _id
      name
      image
      skillSets {
        positions {
          name
        }
        skills {
          deletedAt
          name
        }
      }
      companyId
    }
  }
`;

const DrillListData = () => {
  const [viewingArchived, setViewingArchived] = useState(false);
  const user = getAuthUser();
  const { checkDrillDeleteEffects } = buildCheckDrillDeleteEffects()
  const { removeDrill } = buildRemoveDrill();
  const { restoreDrill } = buildRestoreDrill();
  const { removeDrillImage } = buildRemoveDrillImage();

  const { data: drillData, loading: drillsLoading, error: drillsError, refetch } = useQuery(GET_DRILL_LIST, {
    skip: !user || viewingArchived,
    variables: { companyId: user?.settings?.activeCompanyId, includeGlobal: user?.settings.includeGlobal },
    fetchPolicy: 'cache-and-network'
  });

  const { data: archivedData, loading: archivedLoading, error: archivedError } = useQuery(GET_ARCHIVED_DRILL_LIST, {
    skip: !user || !viewingArchived,
    variables: { companyId: user?.settings?.activeCompanyId },
    fetchPolicy: 'cache-and-network'
  });

  let drills: IDrill[] = [];
  if (viewingArchived) {
    drills = (archivedData && archivedData.archivedDrills) || [];
  } else {
    drills = (drillData && drillData.drills) || [];
  }

  const countArchivedDrills = (drillData && _.isNumber(drillData.countArchivedDrills)) ? drillData.countArchivedDrills : 0;
  const loading = !drills.length && (drillsLoading || archivedLoading);

  const addDrillImage = (drillId: string, files: FileList, token: string | undefined | null) => {
    return handleDrillImageUpload(drillId, files, token)
      .then(() => refetch());
  }

  return <DrillListContainer
    removeDrill={removeDrill}
    restoreDrill={restoreDrill}
    drills={drills}
    countArchivedDrills={countArchivedDrills}
    viewingArchived={viewingArchived}
    setViewingArchived={setViewingArchived}
    loading={loading}
    fetchError={Boolean(drillsError) || Boolean(archivedError)}
    addDrillImage={addDrillImage}
    removeDrillImage={removeDrillImage}
    checkDrillDeleteEffects={checkDrillDeleteEffects}
    isAdmin={!!user?.isAdmin}
  />
};

export default DrillListData;
