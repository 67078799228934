import { makeVar, useReactiveVar } from '@apollo/client';
import { USER_SESSIONS_LOCAL_REF } from '../../components/_core/OfflineHandler/offline-handler.container';
import { IUserSessionStore, UserActionType } from '../types';
import { store } from './storage';
import { IRecordCompositeScoreInput, IRecordScoreInput } from '../../components/Score/ManageEvaluations/manage-evaluations.interface';
import _ from 'lodash';

export const currUserSessionId = makeVar<String>("");
export const getCurrUserSessionId = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(currUserSessionId);
};

// Create and track new user session
export const recordUserSession = async (user: IUser) => {
    const userSessions = await getCachedUserSessions()

    if (user) {
        const newSession: IUserSession = {
            startedAt: new Date(),
            actions: []
        }

        // Filter out any old sessions for current user that don't have any logged actions
        const newUserSessions: IUserSession[] = []
        if (user._id in userSessions) newUserSessions.push(...userSessions[user._id].filter((curr) => !!curr.actions?.length))
        newUserSessions.push(newSession)
        userSessions[user._id] = newUserSessions

        await store.set(USER_SESSIONS_LOCAL_REF, JSON.stringify({userSessions}))
    }
}

export const recordUserSessionActions = async (user: IUser, actions: IUserAction[]) => {
    const userSessions = await getCachedUserSessions()

    if (user?._id && user._id in userSessions) {
        const currUserSessions = userSessions[user._id]
        if (currUserSessions.length) {
            currUserSessions[currUserSessions.length - 1].actions?.push(...actions)
            userSessions[user._id] = currUserSessions

            await store.set(USER_SESSIONS_LOCAL_REF, JSON.stringify({userSessions}))
        }
    }
}

// Remove all action from stored user sessions matching the provided session and actions
export const removeUserSessionActions = async (userId: string, userSession: IUserSession, actions: IUserAction[]) => {
    const userSessions = await getCachedUserSessions()

    if (userId in userSessions) {
        const currUserSessions = userSessions[userId]
        const sessionIndex = currUserSessions.findIndex(s => s.startedAt === userSession.startedAt)
        if (sessionIndex > -1) {
            // Filter out matching user actions
            console.log("Actions length before remove", currUserSessions[sessionIndex].actions.length)

            currUserSessions[sessionIndex].actions = currUserSessions[sessionIndex].actions.filter(a => !actions.find(successfulA => successfulA.actionType === a.actionType && new Date(successfulA.loggedAt).getTime() === new Date(a.loggedAt).getTime()))
            userSessions[userId] = currUserSessions

            console.log("Set new user sessions after remove", {userId, sessions: userSessions[userId]})
            await store.set(USER_SESSIONS_LOCAL_REF, JSON.stringify({userSessions}))
            return currUserSessions[sessionIndex].actions.length
        }
    }

    return null
}

export const getCachedUserSessions = async () => {
    const { userSessions } = JSON.parse((await store.get(USER_SESSIONS_LOCAL_REF)) || '{"userSessions":{}}') as IUserSessionStore
    return userSessions
}

// Get the newest user session for curr user
export const getCurrUserSession = async (user: IUser) => {
    const userSessions = await getCachedUserSessions()

    if (user?._id && user._id in userSessions) {
        const currUserSessions = userSessions[user._id]
        return currUserSessions[currUserSessions.length - 1]
    }
}

// Get all sessions for user, sorted by oldest first
export const getAllCurrUsersSessions = async (user: IUser) => { 
    const userSessions = await getCachedUserSessions()

    if (user._id in userSessions) {
        const currUserSessions = userSessions[user._id]
        return currUserSessions.sort((a, b) => new Date(a.startedAt).getTime() - new Date(b.startedAt).getTime())
    }
}

export const filterUserSessions = async (userId: string, sessionsToRemove: IUserSession[]) => {
    if (sessionsToRemove.length) {
        const userSessions = await getCachedUserSessions()
        if (userId in userSessions) {
            userSessions[userId] = userSessions[userId].filter((s) => !sessionsToRemove.find(r => new Date(r.startedAt).getTime() === new Date(s.startedAt).getTime()))
            if (!userSessions[userId].length) delete userSessions[userId]
            await store.set(USER_SESSIONS_LOCAL_REF, JSON.stringify({userSessions}))
        }
    }
}

export const transformScoreOperationToAction = ({remove, loggedAt, ...score}: Partial<IRecordScoreInput>) => {
    return {actionType: remove ? UserActionType.removeScore : UserActionType.score, payload: {score}, loggedAt} as IUserAction
}

export const transformCompScoreOperationToAction = ({remove, loggedAt, ...score}: Partial<IRecordCompositeScoreInput>) => {
    return {actionType: remove ? UserActionType.removeCompositeScore : UserActionType.compositeScore, payload: {score}, loggedAt} as IUserAction
}

export const reduceUserActions = (actions: IUserAction[]) => {
    const flatSessionActions: Record<string, any> = {}
    for (const action of actions) {
      if (action.actionType == "score" || action.actionType == "removeScore") {
        // @ts-ignore
        const {drillId, skillId, round, assessmentId = "", ageGroupId = "", assessmentSessionId, playerAssessmentId, type, clientId = "", _id = "",  ...rest} = action.payload.score as IRecordScoreInput
        flatSessionActions[assessmentSessionId] = flatSessionActions[assessmentSessionId] ?? {}
        flatSessionActions[assessmentSessionId][drillId] = flatSessionActions[assessmentSessionId][drillId] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId] = flatSessionActions[assessmentSessionId][drillId][skillId] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round] = flatSessionActions[assessmentSessionId][drillId][skillId][round] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"]= flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId][type] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId][type] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId][type][clientId] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId][type][clientId] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId][type][clientId][_id] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId][type][clientId][_id] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId][type][clientId][_id][action.actionType] = (flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId][type][clientId][_id][action.actionType] ?? []) as IFlatScoreAction[]
  
        // Ignore duplicate entries
        if (!flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId][type][clientId][_id][action.actionType].find((a: IFlatScoreAction) => a.loggedAt === action.loggedAt && a.raw === rest.raw))
          flatSessionActions[assessmentSessionId][drillId][skillId][round]["score"][ageGroupId][assessmentId][playerAssessmentId][type][clientId][_id][action.actionType].push({..._.omit(rest, '__typename'), loggedAt: action.loggedAt})
      } else {
        // @ts-ignore
        const {drillId, skillId, round, assessmentSessionId, calibrationVideoId, compositeIndex, type, clientId = "", _id = "", ...rest} = action.payload.score as IRecordCompositeScoreInput
        flatSessionActions[assessmentSessionId] = flatSessionActions[assessmentSessionId] ?? {}
        flatSessionActions[assessmentSessionId][drillId] = flatSessionActions[assessmentSessionId][drillId] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId] = flatSessionActions[assessmentSessionId][drillId][skillId] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round] = flatSessionActions[assessmentSessionId][drillId][skillId][round] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex][type] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex][type] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex][type][clientId] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex][type][clientId] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex][type][clientId][_id] = flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex][type][clientId][_id] ?? {}
        flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex][type][clientId][_id][action.actionType] = (flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex][type][clientId][_id][action.actionType] ?? []) as IFlatScoreAction[]

        // Ignore duplicate entries
        if (!flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex][type][clientId][_id][action.actionType].find((a: IFlatScoreAction) => a.loggedAt === action.loggedAt && a.raw === rest.raw))
          flatSessionActions[assessmentSessionId][drillId][skillId][round]["composite"][calibrationVideoId][compositeIndex][type][clientId][_id][action.actionType].push({..._.omit(rest, '__typename'), loggedAt: action.loggedAt})
      }
    }

    return flatSessionActions
}