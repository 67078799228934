import ResizeObserver from 'resize-observer-polyfill';
import _ from 'lodash';
import onlineCheck from 'is-online';
import React, { ReactNode, useEffect, useState } from 'react';
import { ApolloProvider, NormalizedCacheObject, makeVar, useReactiveVar } from '@apollo/client';
import { ApolloClient } from '@apollo/client';

import createApolloClient from '../../../lib/createApolloClient';
import AuthApiService from '../../../lib/utils/auth-api.service';
import ErrorPage from '../../_pages/_boilerplate/ErrorPage/error-page.container';
import LoadingPage from '../../_pages/_boilerplate/LoadingPage/loading-page.container';
import QueueLink from 'apollo-link-queue';

interface PersistedApolloState {
  bootstrapped: boolean;
  client?: ApolloClient<any>;
  error?: any;
}

interface PersistedApolloProps {
  children: ReactNode;
}

const DENSE_HEIGHT_TRIGGER = 660;
export const isDense = makeVar(document.body.clientHeight < DENSE_HEIGHT_TRIGGER);
export const getDenseStatus = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useReactiveVar(isDense);

// @ts-ignore
const resizeObserver = new ResizeObserver(entries => {
  const isCurrentlyDense = isDense();
  const currHeight = entries[0].target.clientHeight;

  if (isCurrentlyDense && currHeight >= DENSE_HEIGHT_TRIGGER) {
    isDense(false);
  } else if (!isCurrentlyDense && currHeight < DENSE_HEIGHT_TRIGGER) {
    isDense(true);
  }
});

// start observing a DOM node
resizeObserver.observe(document.body)

export const isSyncing = makeVar(false);
export const getSyncing = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useReactiveVar(isSyncing);

export const isOnline = makeVar(true);
export const getOnlineStatus = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useReactiveVar(isOnline);

export const isQueueOpen = makeVar(true);
export const getQueueOpen = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useReactiveVar(isQueueOpen);

let syncTimeout: any;

let syncInterval: any;
let canHasInterval: any;

window.addEventListener('offline', () => {
  isOnline(false)
  isSyncing(false);
  clearTimeout(syncInterval);
  syncInterval = null;
  clearTimeout(canHasInterval);
  canHasInterval = null;
});

window.addEventListener('online', () => {
  if (!isOnline()) {
    clearTimeout(syncInterval);
    syncInterval = null;
    clearTimeout(canHasInterval);
    canHasInterval = null;

    syncInterval = setTimeout(() => {
      isOnline(true);
      isSyncing(true);

      clearTimeout(syncTimeout);
      syncTimeout = setTimeout(() => {
        isSyncing(false);
      }, 3000);
    }, 5 * 1000);
  }
});

setInterval(async () => {
  const nextOnlineStatus = await onlineCheck();

  if (!isOnline() && nextOnlineStatus && !canHasInterval) {
    clearTimeout(syncInterval);
    syncInterval = null;

    canHasInterval = setTimeout(() => {
      isSyncing(true);

      clearTimeout(syncTimeout);
      syncTimeout = setTimeout(() => {
        isSyncing(false);
      }, 5000);

      isOnline(nextOnlineStatus);
      canHasInterval = null;
    }, 5 * 1000);
  } else if (isOnline() && !nextOnlineStatus) {
    clearTimeout(canHasInterval);
    canHasInterval = null;
    clearTimeout(syncInterval);
    syncInterval = null;
    isOnline(false);
    isSyncing(false);
  }
}, 10 * 1000);

const PersistedApolloProvider: React.FC<PersistedApolloProps> = ({ children }) => {
  const online = getOnlineStatus();
  const [isBootstrapped, setBootstrapped] = useState(false);
  const [error, setError] = useState(false);
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [queueLink, setQueueLink] = useState<QueueLink>();

  useEffect(() => {
    AuthApiService.refreshSession()
      .then(async (result) => {
        return await createApolloClient(_.get(result, 'user'))
      })
      .then(({ client, queueLink }) => {
        setBootstrapped(true);
        setClient(client);
        setQueueLink(queueLink);
      })
      .catch(err => {
        console.log(err);
        setError(true);
      });
  }, []);

  let currTimeout: any;

  useEffect(() => {
    if (queueLink) {
      if (online) {
        clearTimeout(currTimeout);
        currTimeout = setTimeout(() => {
          queueLink?.open();
          isQueueOpen(true);
        }, 5 * 1000)
      } else {
        clearTimeout(currTimeout);
        queueLink?.close();
        isQueueOpen(false);
      }
    }
  }, [online, Boolean(queueLink)]);

  if (error) return <ErrorPage />;

  return isBootstrapped && client ? (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  ) : (
    <LoadingPage />
  );
};

export default PersistedApolloProvider;
