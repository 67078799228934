import React, { useEffect, useMemo, useState } from 'react';
import { List,  ListItem,  ListItemText,  Paper } from '@material-ui/core';

import { getCachedUserSessions,  } from '../../lib/utils/user-session.service';
import _ from 'lodash';

export const PendingScoresListContainer = () => {

  const [allCachedSessions, setAllCachedSessions] = useState<Record<string, IUserSession[]>>({})

  useEffect(() => {
    getCachedUserSessions().then((result) => setAllCachedSessions(result))
  }, [])

  return (
    <Paper>
      <List>
        {_.map(Object.entries(allCachedSessions), ([userId, sessions]) => (
          <ListItem key={userId}>
            <ListItemText
              primary={"UserID: " + userId}
              secondary={`Sessions: [${sessions.map((s) => `{SessionStart: ${s.startedAt}, Actions: ${s.actions.length}}`).join(", ")}]`}
            />
          </ListItem>
        ))}
        {!Object.keys(allCachedSessions).length && (
          <ListItem>
            <ListItemText primary="No sessions found" />
          </ListItem>
        )}
      </List>
    </Paper>
  )
};

