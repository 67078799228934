import React from 'react';

// Material UI
import Typography from '@material-ui/core/Typography';

// Components
import SingleFormLayout from '../_layout/SingleFormLayout/single-form.layout';
import ManagePlayerContactsGraphQL from '../../PlayerContact/ManagePlayerContacts/manage-player-contacts.graphql';
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { SportTerminology } from '../../../lib/types';


interface IManagePlayerContactsPage {
  match: any;
}

const ManagePlayerContactsPage: React.FC<IManagePlayerContactsPage> = ({ match }) => {
  const termLookup = getCompanySportTerminology()
  const { code } = match.params;

  return (
    <SingleFormLayout>
      <Typography>Please set your communication preferences for {pluralTerm(termLookup[SportTerminology.SESSION]).toLowerCase()} and report cards</Typography>
      <ManagePlayerContactsGraphQL code={code} />
    </SingleFormLayout>
  );
}

export default ManagePlayerContactsPage;
