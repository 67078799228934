import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';

// Components
import CompanyPlayerListContainer from './company-player-list.container'

// Data
import { getAuthUser } from '../../../lib/services/auth.service';
import { PLAYER_FIELDS } from '../../../lib/queries/player.queries';
import { buildAddPlayerToAssessment } from "../../../lib/services/player-assessment.service";
import { getAgeGroups } from '../../../lib/services/age-group.service';
import { IFormOption } from '../../_core/_ui/forms.component';

const GET_COMPANY_PLAYER_DROPDOWNS = gql`
  query GetCompanyPlayerDropdowns($assessmentId: ID!, $companyId: ID!) {
    ageGroups(assessmentId: $assessmentId) {
      _id
      name
    }
    positions(companyId: $companyId, includeGlobal: true) {
      _id
      name
    }
    assessments(companyId: $companyId) {
      _id
      name
    }
  }
`;

const GET_PLAYER_ASSESSMENTS = gql`
  query GetAssessmentTeams($assessmentId: ID!, $ageGroupIds: [ID!]!) {
    playerAssessments(assessmentId: $assessmentId, ageGroupIds: $ageGroupIds) {
      player {
        _id
      }
      team
      position {
        _id
      }
    }
  }
`;

const GET_COMPANY_PLAYER_LIST = gql`
  query GetCompanyPlayerList($companyId: ID!, $assessmentId: ID!, $ageGroupIds: [ID!]!) {
    playerAssessments(assessmentId: $assessmentId, ageGroupIds: $ageGroupIds) {
      player {
        _id
      }
    }
    players(companyId: $companyId) {
      ...PlayerFields
    }
  }

  ${PLAYER_FIELDS}
`;

const CompanyPlayerListGraphQL = () => {
  const user = getAuthUser();
  const { addPlayerToAssessment } = buildAddPlayerToAssessment();
  const [activeAgeGroup, setActiveAgeGroup] = useState();
  const [activeCopyAssessment, setActiveCopyAssessment] = useState();
  const [activeCopyAgeGroup, setActiveCopyAgeGroup] = useState();

  const { data: teamData, loading: teamloading, error: teamError } = useQuery(GET_PLAYER_ASSESSMENTS, {
    skip: !activeCopyAssessment || !activeCopyAgeGroup,
    variables: {
      assessmentId: activeCopyAssessment,
      ageGroupIds: [activeCopyAgeGroup]
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data: copyDropdownResult, loading: copyDropdownLoading, error: copyDropdownError } = useQuery(GET_COMPANY_PLAYER_DROPDOWNS, {
    skip: !user || !activeCopyAssessment,
    variables: {
      assessmentId: activeCopyAssessment,
      companyId: user?.settings.activeCompanyId
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data: dropdownResult, loading: dropdownLoading, error: dropdownError } = useQuery(GET_COMPANY_PLAYER_DROPDOWNS, {
    skip: !user,
    variables: {
      assessmentId: user?.settings.activeAssessmentId,
      companyId: user?.settings.activeCompanyId
    },
    fetchPolicy: 'cache-and-network'
  });

  const ageGroups = (dropdownResult && dropdownResult.ageGroups) || [];
  const positions = (dropdownResult && dropdownResult.positions) || [];
  const copyAgeGroups = (copyDropdownResult && copyDropdownResult.ageGroups) || [];
  const copyPlayers = (teamData && teamData.playerAssessments) || [];
  const copyAssessments = (dropdownResult && dropdownResult.assessments) || [];

  const { data, loading, error } = useQuery(GET_COMPANY_PLAYER_LIST, {
    skip: !user || !activeAgeGroup,
    variables: {
      companyId: user?.settings.activeCompanyId,
      assessmentId: user?.settings.activeAssessmentId,
      ageGroupIds: [activeAgeGroup],
    },
    fetchPolicy: 'cache-and-network'
  });

  const playerAssessments = (data && data.playerAssessments) || [];
  const players = (data && data.players) || [];

  return <CompanyPlayerListContainer
    playerAssessments={playerAssessments}
    addPlayerToAssessment={addPlayerToAssessment}
    players={players}
    positions={positions}
    ageGroups={ageGroups}
    loading={loading || dropdownLoading}
    fetchError={Boolean(error) || Boolean(dropdownError)}
    setActiveAgeGroup={setActiveAgeGroup}
    activeAgeGroup={activeAgeGroup}
    activeCopyAgeGroup={activeCopyAgeGroup}
    setActiveCopyAgeGroup={setActiveCopyAgeGroup}
    activeCopyAssessment={activeCopyAssessment}
    setActiveCopyAssessment={setActiveCopyAssessment}
    copyAgeGroups={copyAgeGroups}
    copyPlayers={copyPlayers}
    copyAssessments={copyAssessments}
  />;
};

export default CompanyPlayerListGraphQL;
