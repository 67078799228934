import React from 'react';
import dayjs from 'dayjs';
import { Form, Field, FormikProps } from 'formik';
import aOrAn from 'indefinite';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';

// Data
import { SPORT_TERMINOLOGY } from '../../../lib/constants';
import { mainColor } from '../../../lib/theme';

// Components
import { TextFieldComponent } from '../../_core/Formik/formik-datepicker.component';
import { StageItemPre, StageItemWrapper } from '../assessment-stage.shared-components';
import { IFormOption } from '../../_core/_ui/forms.component';
import { ISessionStageBuilderFormValues } from './session-stage-builder.interface';
import FormikSelect from '../../_core/Formik/formik-select.component';
import { PrimaryButton } from '../../_core/_ui/buttons.component';
import { Link } from 'react-router-dom';
import FormikCheckbox from '../../_core/Formik/formik-checkbox.component';
import { NOTIFICATION_OPTIONS } from '../assessment-stage.constants';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getHasUnsavedFormat, setHasUnsavedFormat } from '../../../lib/services/assessment-stage.service';
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { Sport, SportTerminology } from '../../../lib/types';
import { IAssessmentStageBuilderBaseProps } from '../assessment-stage.types';

interface ISessionStageBuilder extends IAssessmentStageBuilderBaseProps {
  form: FormikProps<ISessionStageBuilderFormValues>;
  stage: IAssessmentStage;
  drillOptions: IFormOption[];
  practicePlanOptions: IFormOption[];
}

const SessionStageBuilder: React.FC<ISessionStageBuilder> = ({
  form,
  stage,
  drillOptions,
  practicePlanOptions,
  style
}) => {
  const termLookup = getCompanySportTerminology();
  const eventTerm = termLookup[SportTerminology.EVENT]
  const sessionTerm = termLookup[SportTerminology.SESSION].toLowerCase()

  const hasUnsavedFormat = getHasUnsavedFormat();
  const { dirty, values } = form;

  if (dirty && !hasUnsavedFormat) {
    setHasUnsavedFormat(true);
  } else if (!dirty && hasUnsavedFormat) {
    setHasUnsavedFormat(false);
  }

  if (!drillOptions.length) return (
    <Box p={2}>
      <Typography>{pluralTerm(eventTerm)} are required to configure an {sessionTerm}. <Link to={"/" + pluralTerm(SPORT_TERMINOLOGY[Sport.default][SportTerminology.EVENT])}>Create one here</Link></Typography>
    </Box>
  );

  return (
    <Form>
      <StageItemWrapper style={style}>
        <Box display="flex" flexDirection="column">
          {practicePlanOptions.length > 0 && <>
            <Box display="flex" alignItems="center">
              <Typography gutterBottom>Evaluate the following practice plans:</Typography>
              <Box mr={2}>
                <Tooltip title={`Practice plans can be assigned when groups are approved, or manually by editing ${aOrAn(sessionTerm)} after scheduling`}>
                  <InfoIcon fontSize="small" style={{ marginLeft: '8px' }} />
                </Tooltip>
              </Box>
            </Box>
            <Field
              fid={`${stage._id}_${stage.order}`}
              name="practicePlans"
              options={practicePlanOptions}
              component={FormikSelect}
              isMulti
              disabled={stage.isCompleted}
            />
          </>}
          <Box mt={1}>
            <Box display="flex" alignItems="center">
              <Typography gutterBottom>{practicePlanOptions.length > 0 ? 'and ' : 'Evaluate '}the following {pluralTerm(eventTerm).toLowerCase()}:</Typography>
              <Box mr={2}>
                <Tooltip title={eventTerm + ` can be assigned when groups are approved, or manually by editing an ${aOrAn(sessionTerm)} after scheduling`}>
                  <InfoIcon fontSize="small" style={{ marginLeft: '8px' }} />
                </Tooltip>
              </Box>
            </Box>

            <Field
              fid={`${stage._id}_${stage.order}`}
              name="drills"
              options={drillOptions}
              component={FormikSelect}
              isMulti
              disabled={stage.isCompleted}
            />
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Box width="154px">
              <Field
                autoWidth
                margin="none"
                name="enableNotifications"
                fid={`${stage._id}_${stage.order}`}
                component={FormikCheckbox}
                label={"Notify " + pluralTerm(termLookup[SportTerminology.ATHLETE].toLowerCase())}
                disabled={stage.isCompleted}
              />
            </Box>
            {values.enableNotifications && (<>
              <Box width="400px" mr={1}>
                <Field
                  fid={`${stage._id}_${stage.order}`}
                  name="notifications"
                  options={NOTIFICATION_OPTIONS}
                  component={FormikSelect}
                  isMulti
                  disabled={stage.isCompleted}
                />
              </Box>
              <Box mr={2}>
                <Tooltip title="Notifications after the first will be sent as reminders">
                  <InfoIcon fontSize="small" style={{ marginLeft: '8px' }} />
                </Tooltip>
              </Box>
              <Typography>or after groupings are finalized, whichever occurs later</Typography>
            </>)}
          </Box>
        </Box>
        {!stage.isCompleted && dirty && <Box mt={2}>
          <PrimaryButton type="submit" startIcon={<SaveIcon />}>
            Save Changes
          </PrimaryButton>
        </Box>}
      </StageItemWrapper>
    </Form>
  );
};

export default SessionStageBuilder;
