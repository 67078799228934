import { useMutation } from '@apollo/client';

// Data
import { CHECK_DRILL_DELETE_EFFECTS, REMOVE_DRILL, REMOVE_DRILL_IMAGE, RESTORE_DRILL } from '../queries/drill.queries';
import { removeCacheModifier } from '../cache/basic.cache';


export const buildCheckDrillDeleteEffects = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutation, rest] = useMutation<IDrillDeleteEffects>(CHECK_DRILL_DELETE_EFFECTS);

  return {
    ...rest,
    checkDrillDeleteEffects: (_id: string, companyId: string) =>
      mutation({variables: { _id, companyId}})
  };
};

export const buildRemoveDrill = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutation, rest] = useMutation(REMOVE_DRILL);

  return {
    ...rest,
    removeDrill: (_id: string, companyId: string) =>
      mutation({
        variables: { _id, companyId},
        update: cache => {
          cache.modify({
            fields: {
              drills: removeCacheModifier(cache, _id)
            }
          });
        },
        optimisticResponse: {
          __typename: 'Mutation',
          removeDrill: {
            __typename: 'DeleteResponse',
            _id
          }
        }
      })
  };
};

export const buildRemoveDrillImage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutation, rest] = useMutation(REMOVE_DRILL_IMAGE);

  return {
    ...rest,
    removeDrillImage: (_id: string) =>
      mutation({
        variables: { _id },
        optimisticResponse: {
          __typename: 'Mutation',
          removeDrillImage: {
            __typename: 'Drill',
            _id,
            image: null
          }
        }
      })
  };
};

export const buildRestoreDrill = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutation, rest] = useMutation(RESTORE_DRILL);

  return {
    ...rest,
    restoreDrill: (_id: string, companyId: string) =>
      mutation({
        variables: { _id, companyId },
        update: cache => {
          cache.modify({
            fields: {
              archivedDrills: removeCacheModifier(cache, _id)
            }
          });
        },
        optimisticResponse: {
          __typename: 'Mutation',
          restoreDrill: {
            __typename: 'Drill',
            _id
          }
        }
      })
  };
};
