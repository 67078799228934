import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { gql, useQuery } from '@apollo/client';

// Queries
import { ASSESSMENT_STAGE_FIELDS } from '../../../lib/queries/assessment-stage.queries';
import { getAuthUser } from '../../../lib/services/auth.service';

// Components
import AssessmentStageBuilderContainer from './assessment-stage-builder.container';
import { buildUpdateAgeGroup, getAgeGroups } from '../../../lib/services/age-group.service';
import {
  buildCompleteAssessmentStage,
  buildCreateAssessmentStage,
  buildRemoveAssessmentStage,
  buildUpdateAssessmentStage
} from '../../../lib/services/assessment-stage.service';
import { getCompanyHasAutomation } from '../../../lib/services/company.service';

const GET_ASSESSMENT_STAGE_BUILDER = gql`
  query GetAssessmentStageBuilder($assessmentId: ID!, $companyId: ID!, $ageGroupId: ID!, $includeGlobal: Boolean) {
    assessmentStages(assessmentId: $assessmentId, ageGroupId: $ageGroupId) {
      ...AssessmentStageFields
    }
    practicePlans(companyId: $companyId, includeGlobal: $includeGlobal) {
      _id
      name
    }
    drills(companyId: $companyId, includeGlobal: $includeGlobal) {
      _id
      name
    }
    positions(companyId: $companyId, includeGlobal: $includeGlobal) {
      _id
      name
    }
  }

  ${ASSESSMENT_STAGE_FIELDS}
`;

const AssessmentStageBuilderGraphQL = () => {
  const hasAutomation = getCompanyHasAutomation();

  const [ageGroupId, setAgeGroupId] = useState();
  const user = getAuthUser();
  const { ageGroups } = getAgeGroups();
  const { createAssessmentStage } = buildCreateAssessmentStage();
  const { completeAssessmentStage } = buildCompleteAssessmentStage();
  const { updateAssessmentStage } = buildUpdateAssessmentStage();
  const { removeAssessmentStage } = buildRemoveAssessmentStage();
  const { updateAgeGroup } = buildUpdateAgeGroup();

  const [ageGroup, setAgeGroup] = useState<IAgeGroup | null>(null)

  useEffect(() => {
    if (ageGroupId) setAgeGroup(ageGroups.find(ag => ag._id === ageGroupId) ?? null)
  }, [ageGroups, ageGroupId])

  const { data, loading, error } = useQuery(GET_ASSESSMENT_STAGE_BUILDER, {
    skip: !user || !ageGroupId,
    variables: {
      companyId: user?.settings.activeCompanyId,
      assessmentId: user?.settings.activeAssessmentId,
      ageGroupId,
      includeGlobal: user?.settings.includeGlobal
    },
    fetchPolicy: 'cache-and-network'
  });

  const stages = (data && data.assessmentStages) || [];
  const drills = (data && data.drills) || [];
  const practicePlans = (data && data.practicePlans) || [];
  const positions = (data && data.positions) || [];

  const handleManuallyCompleteAssessmentStage = useCallback(({_id}: IAssessmentStage) => {
    if (ageGroup && window.confirm("Are you sure you want to mark this stage as complete?")) {
      // Update age groups current stage after completing assessment stage
      completeAssessmentStage(_id).then(() => updateAgeGroup(ageGroup, stages))
    }
  }, [ageGroup, stages]);

  if (!hasAutomation) return <Redirect to="/" />
  return <AssessmentStageBuilderContainer
    loading={loading}
    fetchError={Boolean(error)}
    setAgeGroupId={setAgeGroupId}
    stages={stages}
    drills={drills}
    practicePlans={practicePlans}
    ageGroups={ageGroups}
    ageGroup={ageGroup}
    positions={positions}
    completeStage={handleManuallyCompleteAssessmentStage}
    createStage={createAssessmentStage}
    updateStage={updateAssessmentStage}
    removeStage={removeAssessmentStage}
  />;
};

export default AssessmentStageBuilderGraphQL;
