import { useApolloClient, useMutation, makeVar, useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// Data
import { DEFAULT_USER_MODE, HAS_USER_MODES } from '../../../_configuration';
import { store } from '../utils/storage';
import SessionReporter from '../utils/session.reporter';

// Queries
import { LOGOUT } from '../queries/auth.queries';
import { checkHasTrackedQueries } from '../utils/tracker.link';
import { getOnlineStatus } from '../../components/_core/PersistGateContainer/persist-gate.container';
import { recordUserSession } from '../utils/user-session.service';

export const authUser = makeVar<IUser | null>(null);
export const getAuthUser = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(authUser);
}

export const buildSetAuthUser = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = useApolloClient();

  return (user: any, currUserMode?: string, redirect?: string) =>
    client.clearStore()
      .then(async () => {
        await recordUserSession(user);
        await store.set('mea_token', user.token);

        authUser(user);
      })
      .then(() => {
        SessionReporter.identify({
          _id: user._id,
          name: user.name,
          email: user.email
        });

        if (redirect) {
          window.location.href = redirect;
        }
      });
};

export const buildSignOut = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = useApolloClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [logout] = useMutation(LOGOUT);
  const isOnline = getOnlineStatus();

  return async (redirect?: boolean) => {
    const hasUnsavedData = await checkHasTrackedQueries();

    if (hasUnsavedData && isOnline) Sentry.captureException('User is attempting to sign out with data in queue.');
    if ((hasUnsavedData && !isOnline && window.confirm('You have unsaved scores. These will be lost if you sign out before connecting to stable internet. Are you sure you want to sign out?')) || !hasUnsavedData) {
      return logout()
        .then(() => {
          authUser(null);
          SessionReporter.logout();
        })
        .then(() => client.clearStore())
        .then(() => store.remove('mea_token'))
        .then(() => {
          return store.set('logout', String(Date.now()));
        })
        .then(() => {
          if (redirect) history.push('/');
        });
    }

    return () => Sentry.captureMessage(`Unable to log the user out (unsaved: ${hasUnsavedData}, online: ${isOnline}`);
  }
};

function syncLogout(event: StorageEvent) {
  if (event.key === 'logout') {
    window.location.href = '/';
  }
}

window.removeEventListener('storage', syncLogout);
window.addEventListener('storage', syncLogout);
