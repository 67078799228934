import _ from 'lodash';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// Data
import { buildUpdateCompany, handleCompanyLogoUpload } from '../../../lib/services/company.service';
import { TIMEZONE_NAME_OPTIONS } from '../../../lib/timezone.constants';
import UpdateCompanyForm from './update-company-form.component';
import { IUpdateCompanyFields, IUpdateCompanyProps } from './update-company-form.interfaces';
import { getAuthUser } from '../../../lib/services/auth.service';

// Components

dayjs.extend(utc)
dayjs.extend(timezone)

interface IUpdateCompanyContainer {
  company: ICompany;
  refetch: Function;
}

const UpdateCompanyFormContainer: React.FC<IUpdateCompanyContainer> = ({ company, refetch }) => {
  const { updateCompany } = buildUpdateCompany();
  const user = getAuthUser();

  const setCompanyLogo = (companyId: string, files: FileList, token: string | undefined | null) => {
    return handleCompanyLogoUpload(companyId, files, token)
      .then(() => refetch());
  }

  const timezone = _.find(TIMEZONE_NAME_OPTIONS, option => option.value === company.timezone) || TIMEZONE_NAME_OPTIONS[0];
  const initialValues: IUpdateCompanyFields = {
    name: company.name,
    timezone,
    communicationEmail: company.communicationEmail,
    companyWebsite: company.companyWebsite,
    evaluationGroupName: company.evaluationGroupName,
    introLink: company.introLink
  };

  const handleSubmit = (values: IUpdateCompanyFields, { setSubmitting, setErrors }: any) => {
    const data: IUpdateCompanyProps = { ..._.omit(values, ['timezone']) };
    data.timezone = values.timezone.value;

    updateCompany(data)
      .then(() => {
        setSubmitting(false);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
     });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Company name is required'),
    timezone: Yup.string().required('Select your timezone'),
    companyWebsite: Yup.string().required('Provide your company website'),
    communicationEmail: Yup.string().required('Email communication is required'),
    evaluationGroupName: Yup.string().nullable(),
    introLink: Yup.string().nullable(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {form => (<UpdateCompanyForm
        form={form}
        company={company}
        setCompanyLogo={setCompanyLogo}
        user={user}
      />)}
    </Formik>
  );
};

export default UpdateCompanyFormContainer;
