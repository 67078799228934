import _ from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

// Material UI
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ViewIcon from '@material-ui/icons/Visibility';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

// Component
import { FormInput } from '../../_core/_ui/forms.component';
import { EnhancedTable, IEnhancedTableRow } from '../../_core/_ui/table.components';
import { toScore } from '../../../lib/helpers/conversion.helpers';
import { PrimaryButton } from '../../_core/_ui/buttons.component';
import { Spacer } from '../../_core/_ui/structure.components';
import { makeVar, useReactiveVar } from '@apollo/client';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import { buildSendEmails, getCountHasUnsentType } from '../../../lib/services/email-log.service';
import { getCompanyHasReportCards, getCompanySportTerminology } from '../../../lib/services/company.service';
import { Sport, SportTerminology } from '../../../lib/types';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { SPORT_TERMINOLOGY } from '../../../lib/constants';
import { FormControlLabel, useMediaQuery, useTheme } from '@material-ui/core';
import Select from 'react-select';
import { getPlayerStatus } from '../../../lib/utils/player.utils';

interface IPlayerRankingReport {
  loading: boolean;
  fetchError: boolean;
  playerAssessments: IPlayerAssessment[];
  exportReports: ClickHandler;
  selected: IPlayerAssessment[];
  setSelected: Function;
  level: string;
  setLevel: Function;
  assignLevel: ClickHandler;
  ageGroup?: IAgeGroup;
  team: string;
  setTeam: Function;
  assignTeam: ClickHandler;
  sendNotifications: boolean;
  setSendNotifications: Function;
  concludePlayers: ClickHandler;
}

const now = dayjs();
const lastUpdated = makeVar(now.subtract(now.get('minute') % 10 % 5, 'minute'));
export const getLastUpdated = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useReactiveVar(lastUpdated);

setInterval(() => {
  const now = dayjs();
  lastUpdated(now.subtract(now.get('minute') % 10 % 5, 'minute'));
}, 60 * 1000);

const PlayerRankingReport: React.FC<IPlayerRankingReport> = ({
  fetchError,
  loading,
  playerAssessments,
  exportReports,
  selected,
  setSelected,
  level,
  setLevel,
  assignLevel,
  team,
  setTeam,
  assignTeam,
  ageGroup,
  sendNotifications,
  setSendNotifications,
  concludePlayers
}) => {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const hasReportCards = getCompanyHasReportCards()
  const termLookup = getCompanySportTerminology()
  const athletesTerm = pluralTerm(termLookup[SportTerminology.ATHLETE].toLowerCase());

  const playersWithLevel: any = _.filter(playerAssessments, playerAssessment => playerAssessment.level);
  const levelPlayerAssessmentIds = _.map(playersWithLevel, '_id');
  const playersWithTeam: any = _.filter(playerAssessments, playerAssessment => playerAssessment.team);
  const teamPlayerAssessmentIds = _.map(playersWithTeam, '_id');

  const { count: countLevel, loading: loadingLevelEmail, refetch: refetchLevelEmail } = getCountHasUnsentType('LEVEL_ASSIGNMENT', ageGroup?._id, levelPlayerAssessmentIds);
  const [sendingLevel, setSendingLevel] = useState(false);
  const { count: countTeam, loading: loadingTeamEmail, refetch: refetchTeamEmail } = getCountHasUnsentType('TEAM_ASSIGNMENT', ageGroup?._id, teamPlayerAssessmentIds);
  const [sendingTeam, setSendingTeam] = useState(false);
  const { sendEmails } = buildSendEmails();

  const currUpdateValue = getLastUpdated();
  const history = useHistory();
  const rows: IEnhancedTableRow[] = [];

  const handleSendLevel = () => {
    setSendingLevel(true);

    return sendEmails('LEVEL_ASSIGNMENT', ageGroup?._id)
      .then(() => refetchLevelEmail())
      .then(() => setSendingLevel(false));
  }

  const handleSendTeam = () => {
    setSendingTeam(true);

    return sendEmails('TEAM_ASSIGNMENT', ageGroup?._id)
      .then(() => refetchTeamEmail())
      .then(() => setSendingTeam(false));
  }

  const positions = _.uniq(playerAssessments.map(playerAssessment => playerAssessment.position?.name));
  const levels = _.uniq(playerAssessments.map(playerAssessment => playerAssessment.level).filter(level => !!level));
  const teams = _.uniq(playerAssessments.map(playerAssessment => playerAssessment.team).filter(team => !!team));

  const assessmentsByPosition: Record<string, any> = positions.reduce((map, position) => {
    return { ...map, [position]: playerAssessments.filter(playerAssessment => playerAssessment.position?.name === position) }
  }, {});

  const [positionFilter, setPositionFilter] = useState<string[]>([]);
  const [levelFilter, setLevelFilter] = useState<string[]>([]);
  const [teamFilter, setTeamFilter] = useState<string[]>([]);

  function togglePositionFilter(position: string) {
    if (positionFilter.indexOf(position) > -1) {
      // Remove it.
      setPositionFilter([...positionFilter].filter(p => position !== p));
    } else {
      setPositionFilter([...positionFilter, position]);
    }
  }

  function toggleLevelFilter(level: string) {
    if (levelFilter.indexOf(level) > -1) {
      // Remove it.
      setLevelFilter([...levelFilter].filter(p => level !== p));
    } else {
      setLevelFilter([...levelFilter, level]);
    }
  }

  function toggleTeamFilter(team: string) {
    if (teamFilter.indexOf(team) > -1) {
      // Remove it.
      setTeamFilter([...teamFilter].filter(p => team !== p));
    } else {
      setTeamFilter([...teamFilter, team]);
    }
  }

  function filterPlayerAssessments() {
    const filteredByPosition = positionFilter.length > 0 ? positionFilter.map(position => assessmentsByPosition[position]).flat() : playerAssessments;

    const filteredByLevel = levelFilter.length > 0 ?  filteredByPosition.filter(playerAssessment => levelFilter.indexOf(playerAssessment.level) > -1) : filteredByPosition;

    const filteredByTeam = teamFilter.length > 0 ?  filteredByLevel.filter(playerAssessment => teamFilter.indexOf(playerAssessment.team) > -1) : filteredByLevel;

    return filteredByTeam;
  }

  const filteredPlayerAssessments = filterPlayerAssessments();

  _.each(filteredPlayerAssessments, (playerAssessment: IPlayerAssessment, index) => {
    let weightedScore = '-';

    if (playerAssessment.weightedScore && playerAssessment.weightedScore !== '-') {
      weightedScore = toScore(playerAssessment.weightedScore || '-', 1);
    }

    let adjustedScore = '-';

    if (playerAssessment.adjustedScore && playerAssessment.adjustedScore !== '-') {
      adjustedScore = toScore(playerAssessment.adjustedScore || '-', 1);
    }

    const cells = [
      <TableCell key="name">{playerAssessment.player?.lastName}, {playerAssessment.player?.firstName}</TableCell>,
      <TableCell key="position">{playerAssessment.position?.name}</TableCell>,
      <TableCell key="attendance">{playerAssessment.attendanceCount}</TableCell>,
      <TableCell key="rank">{index + 1}</TableCell>,
      <TableCell key="adjusted">{adjustedScore}</TableCell>,
      <TableCell key="weight">{weightedScore}</TableCell>,
      <TableCell key="level">{playerAssessment.level}</TableCell>,
      <TableCell key="team">{playerAssessment.team}</TableCell>,
      <TableCell key="status">{getPlayerStatus(playerAssessment)}</TableCell>,
      <TableCell key="edit" padding="checkbox">
        <Tooltip title={`View Score Breakdown (Bottom of ${termLookup[SportTerminology.ATHLETE]} Page)`}>
          <IconButton onClick={() => history.push(`/${pluralTerm(SPORT_TERMINOLOGY[Sport.default][SportTerminology.ATHLETE].toLowerCase())}/${playerAssessment.player._id}/${playerAssessment._id}`)}>
            <ViewIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    ]
    if (hasReportCards) cells.push(
      <TableCell key="report" padding="checkbox">
        <Tooltip title="View Report Card">
          <IconButton onClick={() => history.push(`/report-card/${playerAssessment.player.code}/${playerAssessment._id}`)}>
            <AssignmentTurnedInIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    )

    rows.push({
      _id: playerAssessment._id,
      data: {...playerAssessment, weighted: Number(playerAssessment.weightedScore), adjusted: Number(playerAssessment.adjustedScore), rank: index + 1},
      cells
    });
  });

  const columns = [
    { key: 'name', label: 'Name', path: 'player.lastName' },
    { key: 'position', label: 'Position', path: 'position.name' },
    { key: 'attendanceCount', label: '# Attended' },
    { key: 'rank', label: 'Rank' },
    { key: 'adjusted', label: 'Bias Adjusted Score' },
    { key: 'weighted', label: 'Unadjusted Score' },
    { key: 'level', label: 'Level' },
    { key: 'team', label: 'Team' },
    { key: 'status', label: 'Status', disableSort: true },
    { key: 'edit', disableSort: true, width: '60px', disablePadding: true }
  ];
  if (hasReportCards) columns.push({ key: 'report', disableSort: true, width: '60px', disablePadding: true })

  const buttonStyle = { minWidth: '130px', marginLeft: '16px' }

  return (
    <>
      <Box mb={2} display="flex" alignItems="center">
        <Typography>Reports are automatically updated every 10 minutes (last updated at {currUpdateValue.format('h:mm A')})</Typography>
        <Spacer />
        <PrimaryButton onClick={exportReports}>Export with Contact Info</PrimaryButton>
      </Box>
      <Box mb={2} display="flex" alignItems="center">
        {
          positions.length > 0 && (<Box><strong>Position:</strong></Box>)
        }
        {
          positions.map(position => {
            return (
              <Box>
                <Checkbox
                  checked={positionFilter.indexOf(position) > -1}
                  onChange={(e) => togglePositionFilter(position)}
                />
                {position}
              </Box>
            )
          })
        }
        {
          levels.length > 0 && (<Box ml={2}><strong>Level:</strong></Box>)
        }
        {
          levels.map(level => {
            return (
              <Box>
                <Checkbox
                  checked={levelFilter.indexOf(level) > -1}
                  onChange={(e) => toggleLevelFilter(level)}
                />
                {level}
              </Box>
            )
          })
        }
        {
          teams.length > 0 && (<Box ml={2}><strong>Team:</strong></Box>)
        }
        {
          teams.map(team => {
            return (
              <Box>
                <Checkbox
                  checked={teamFilter.indexOf(team) > -1}
                  onChange={(e) => toggleTeamFilter(team)}
                />
                {team}
              </Box>
            )
          })
        }

      </Box>
      {(countLevel > 0 || loadingLevelEmail) && (
        <Box display="flex" alignItems="center" mb={2}>
          {loadingLevelEmail && <Typography>Checking whether any {athletesTerm} need to receive a level notification...</Typography>}
          {!loadingLevelEmail && countLevel > 0 && <Typography>Send level notifications to {loadingLevelEmail ? '...' : countLevel} {athletesTerm} that have not received one</Typography>}
          <Spacer />
          <PrimaryButton disabled={loadingLevelEmail || sendingLevel} endIcon={<SendIcon />} onClick={() => handleSendLevel()}>Send Level Emails</PrimaryButton>
        </Box>
      )}
      {(countTeam > 0 || loadingTeamEmail) && (
        <Box display="flex" alignItems="center" mb={2}>
          {loadingTeamEmail && <Typography>Checking whether any {athletesTerm} need to receive a team notification...</Typography>}
          {!loadingTeamEmail && countTeam > 0 && <Typography>Send team notifications to {loadingTeamEmail ? '...' : countTeam} {athletesTerm} that have not received one</Typography>}
          <Spacer />
          <PrimaryButton disabled={loadingTeamEmail || sendingTeam} endIcon={<SendIcon />} onClick={() => handleSendTeam()}>Send Team Emails</PrimaryButton>
        </Box>
      )}

      <EnhancedTable
        selected={selected}
        setSelected={setSelected}
        numCols={columns.length}
        rows={rows}
        columns={columns}
        defaultOrder="rank"
        entityName="players"
        showError={fetchError}
        showLoading={loading && rows.length === 0}
        checkboxTitle="Assign"
        title={termLookup[SportTerminology.ATHLETE] + " Rankings"}
        selectedAction={(
          <Box display="flex" flexWrap={isLarge ? undefined : "wrap"}>
            <Box display="flex" p={2} style={{ height: '72px'}}>
              <FormInput type="text" placeholder="Level Placement" onKeyUp={(e: any) => setLevel(e.currentTarget.value)} />
              <PrimaryButton
                disabled={!level || level.length === 0}
                style={buttonStyle}
                onClick={assignLevel}
              >Assign Level</PrimaryButton>
              <Box mr={4} />
              <FormInput type="text" placeholder="Team Placement" onKeyUp={(e: any) => setTeam(e.currentTarget.value)} />
              <PrimaryButton
                disabled={!team || team.length === 0}
                style={buttonStyle}
                onClick={assignTeam}
              >Assign Team</PrimaryButton>
            </Box>
            <Box display="flex" flex={1} justifyContent="end" p={2} style={{ height: '72px'}}>
              <FormControlLabel control={<Checkbox onClick={(e) => setSendNotifications(!sendNotifications)} /> } label="Send Notifications" />
              <PrimaryButton
                style={buttonStyle}
                onClick={concludePlayers}
              >Conclude</PrimaryButton>
            </Box>
          </Box>
        )}
      />
    </>
  );
};

export default PlayerRankingReport;
