import _ from 'lodash';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import ImageIcon from '@material-ui/icons/Image';
import BackupIcon from '@material-ui/icons/Backup';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';

// Components
import { FormInput } from '../../_core/_ui/forms.component';
import { EnhancedTable, IEnhancedTableRow } from '../../_core/_ui/table.components';
import { PrimaryButton, SecondaryButton } from '../../_core/_ui/buttons.component';
import { Spacer } from '../../_core/_ui/structure.components';
import ToggleGlobal from '../../User/ToggleGlobal/toggle-global.component';
import { Bold } from '../../_core/_ui/typography.component';
import { mainColor } from '../../../lib/theme';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import { getAuthUser } from '../../../lib/services/auth.service';
import DropFile from '../../_core/DropFile/drop-file.component';
import { LargeIcon } from '../../_core/_ui/icons.component';
import AddFileIcon from '../../../assets/undraw/undraw_Add_files_re_v09g.svg';
import { Modal, Popover } from '@material-ui/core';
import { PopoverItem } from '../../_core/_ui/general.components';
import { handleDrillImageUpload } from '../drill.helpers';
import DrillListImageButton from './drill-list-image.component';
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { Sport, SportTerminology } from '../../../lib/types';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { SPORT_TERMINOLOGY } from '../../../lib/constants';

interface IDrillList {
  drills: Array<any>;
  countArchivedDrills: number;
  searchDrills: Function;
  removeDrills: Function;
  restoreDrills: Function;
  loading: boolean;
  fetchError: boolean
  viewingArchived: boolean;
  setViewingArchived: Function;
  selectedDrills: IDrill[];
  setSelectedDrills: Function;
  removeDrillImage: Function;
  addDrillImage: Function;
  isAdmin: boolean;
}

const DrillList: React.FC<IDrillList> = ({
  drills,
  countArchivedDrills,
  searchDrills,
  loading,
  fetchError,
  removeDrills,
  restoreDrills,
  viewingArchived,
  setViewingArchived,
  selectedDrills,
  setSelectedDrills,
  removeDrillImage,
  addDrillImage,
  isAdmin
}) => {
  const termLookup = getCompanySportTerminology()
  const eventsTerm = pluralTerm(termLookup[SportTerminology.EVENT]);

  const user = getAuthUser();
  const history = useHistory();
  const [rows, setRows] = useState<IEnhancedTableRow[]>([]);
  const [isUploading, setUploading] = useState<string | null>(null);
  const [showDiagram, setShowDiagram] = useState<string | null>(null);

  const onReceivedFiles = (drillId: string, files: FileList) => {
    setUploading(drillId);
    addDrillImage(drillId, files, user?.token)
      .then(() => setUploading(null));
  }

  const createOnClick: Function = (image: string, callback: (image: string) => void) => {
    return () => callback(image);
  };

  useEffect(() => {
    const nextRows: IEnhancedTableRow[] = [];

    _.each(drills, drill => {
      nextRows.push({
        disableSelect: !drill.companyId && !isAdmin,
        _id: drill._id,
        data: drill,
        cells: [
          <TableCell key="actions" padding="checkbox">
            {(user?.isAdmin || drill.companyId) && (
              <IconButton onClick={() => history.push(`/${pluralTerm(SPORT_TERMINOLOGY[Sport.default][SportTerminology.EVENT]).toLowerCase()}/${drill._id}`)}>
                <EditIcon />
              </IconButton>
            )}
          </TableCell>,
          <TableCell key="name">{drill.name}</TableCell>,
          <TableCell key="skills">{
            drill.skillSets?.map((skillSet: ISkillSet, index: number) => {
              const positionNames = skillSet?.positions?.map(position => position.name).join(', ');
              const skillNames = skillSet?.skills?.filter(skill => !skill.deletedAt).map(skill => skill.name).join(', ');

              return <Typography key={index}><Bold style={{ color: mainColor }}>{positionNames}:</Bold> {skillNames}</Typography>;
            })
          }</TableCell>,
          <TableCell key="image">
            {!drill.image && user?.isAdmin && (
              <IconButton>
                <DropFile onReceivedFiles={(files: FileList) => !isUploading && onReceivedFiles(drill._id, files)}>
                  {isUploading === drill._id ? <BackupIcon /> : <AddPhotoAlternateIcon />}
                </DropFile>
              </IconButton>
            )}
            {!drill.image && !user?.isAdmin && (
              <Tooltip title="No Diagram Available">
                <BrokenImageIcon style={{ color: '#777' }} />
              </Tooltip>
            )}
            {drill.image && <DrillListImageButton removeDrillImage={removeDrillImage} drill={drill} setShowDiagram={setShowDiagram} user={user} />}
          </TableCell>
        ]
      });
    });

    setRows(nextRows);
  }, [drills]);



  const columns = [
    { key: 'actions', width: '60px', disableSort: true },
    { key: 'name', disablePadding: true, label: 'Name', path: 'name' },
    { key: 'skills', disablePadding: true, disableSort: true, label: pluralTerm(termLookup[SportTerminology.METRIC]) },
    { key: 'image', disablePadding: true, disableSort: true, label: 'Diagram' }
  ]

  return (
    <>
      <Modal
        open={Boolean(showDiagram)}
        onClose={() => setShowDiagram(null)}
        className="diagram-modal"
        >
        <Paper style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Box p={2}>
            {showDiagram && <img src={showDiagram} style={{ minWidth: '400px', maxWidth: '100%' }} alt="Broken Image. Please re-upload" />}
          </Box>
        </Paper>
      </Modal>
      <EnhancedTable
        numCols={4}
        columns={columns}
        rows={rows}
        defaultOrder="name"
        entityName="drills"
        showError={fetchError}
        showLoading={loading}
        title={viewingArchived ? 'Archived ' + eventsTerm : eventsTerm}
        selected={selectedDrills}
        setSelected={setSelectedDrills}
        selectedAction={(
          <Tooltip title={viewingArchived ? 'Restore' : 'Delete'}>
            <IconButton aria-label="delete" onClick={() => {
              viewingArchived ? restoreDrills(selectedDrills) : removeDrills(selectedDrills);
              setSelectedDrills([]);
            }}>
              {viewingArchived ? <UnarchiveIcon /> : <DeleteIcon />}
            </IconButton>
          </Tooltip>
        )}
        filterComponent={(<>
          <Box mr={2} flexShrink={0}><ToggleGlobal label={"Show Max Analytics Marketplace " + eventsTerm} /></Box>
          <FormInput
            placeholder={viewingArchived ? `Search archived ${eventsTerm.toLowerCase()}...` : `Search ${eventsTerm}...`}
            onKeyUp={(e: any) => searchDrills(e.currentTarget.value)} />
        </>)}
      />
      {countArchivedDrills > 0 && !viewingArchived && (
        <Box display="flex">
          <Spacer />
          <SecondaryButton startIcon={<ArchiveIcon />} onClick={() => setViewingArchived(true)}>View Archived {eventsTerm}</SecondaryButton>
        </Box>
      )}
      {viewingArchived && (
        <Box display="flex">
          <Spacer />
          <PrimaryButton startIcon={<ArrowBackIosIcon />} onClick={() => setViewingArchived(false)}>Back to {eventsTerm}</PrimaryButton>
        </Box>
      )}
    </>
  );
};

export default DrillList;
