import { defineMessages } from 'react-intl';
import { SportTermLookup, SportTerminology } from '../../../lib/types';

export const scope = 'app.components.CreatePlayerForm';

export default (termLookup: SportTermLookup) => {
  return defineMessages({
    lblFirstName: {
      id: `${scope}.lblFirstName`,
      defaultMessage: 'First Name'
    },
    lblLastName: {
      id: `${scope}.lblLastName`,
      defaultMessage: 'Last Name'
    },
    lblDate: {
      id: `${scope}.lblDate`,
      defaultMessage: 'Date of Birth'
    },
    lblGender: {
      id: `${scope}.lblGender`,
      defaultMessage: 'Gender'
    },
    lblPosition: {
      id: `${scope}.lblPosition`,
      defaultMessage: 'Position'
    },
    lblContactName: {
      id: `${scope}.lblContactName`,
      defaultMessage: 'Full Name'
    },
    lblContactEmail: {
      id: `${scope}.lblContactEmail`,
      defaultMessage: 'Email'
    },
    lblSendEmail: {
      id: `${scope}.lblSendEmail`,
      defaultMessage: 'Send Email Communications'
    },
    lblContactPhone: {
      id: `${scope}.lblContactPhone`,
      defaultMessage: 'Mobile Phone'
    },
    lblContactHeight: {
      id: `${scope}.lblContactHeight`,
      defaultMessage: 'Height (inches)'
    },
    lblContactSportInvolvement: {
      id: `${scope}.lblContactSportInvolvement`,
      defaultMessage: 'Involvement in Sport'
    },
    lblContactRelationship: {
      id: `${scope}.lblContactRelationship`,
      defaultMessage: 'Relationship to ' + termLookup[SportTerminology.ATHLETE]
    },
    lblSendSMS: {
      id: `${scope}.lblSendSMS`,
      defaultMessage: 'Send SMS Communications'
    },
    lblAgeGroup: {
      id: `${scope}.lblAgeGroup`,
      defaultMessage: 'Age Group'
    },
    lblExternalId: {
      id: `${scope}.lblExternalId`,
      defaultMessage: 'External ID'
    },
    lblPrimarySport: {
      id: `${scope}.lblPrimarySport`,
      defaultMessage: 'Primary Sport'
    },
    btnSubmit: {
      id: `${scope}.btnSubmit`,
      defaultMessage: 'Create ' + termLookup[SportTerminology.ATHLETE],
    },
    btnSubmitting: {
      id: `${scope}.btnSubmitting`,
      defaultMessage: 'Saving...',
    }
  });
}
