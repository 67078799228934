import React, { useState } from 'react';
import { Field, Form } from 'formik';

// Material UI
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// Data
import getMessages from './update-company-form.messages';
import { TIMEZONE_NAME_OPTIONS } from '../../../lib/timezone.constants';
import logo from '../../../assets/logo.png';

// Components
import { PrimaryButton } from '../../_core/_ui/buttons.component';
import FormikInput from '../../_core/Formik/formik-input.component';
import FormikSelect from '../../_core/Formik/formik-select.component';
import DropFile from '../../_core/DropFile/drop-file.component';
import BackupIcon from '@material-ui/icons/Backup';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { FormLabel } from '../../_core/_ui/forms.component';
import Button from '@material-ui/core/Button';
import { mainColor } from '../../../lib/theme';
import { getCompanySportTerminology } from '../../../lib/services/company.service';

interface IUpdateCompanyFormProps {
  company: ICompany;
  user: IUser | null;
  form: any;
  setCompanyLogo: Function;
}

const UpdateCompanyForm: React.FC<IUpdateCompanyFormProps> = ({ company, user, form, setCompanyLogo }) => {
  const [isUploading, setUploading] = useState<string | null>(null);
  const [eleAnchor, setAnchor] = useState(null);

  const termLookup = getCompanySportTerminology()
  const messages = getMessages(termLookup);

  const { isSubmitting, values, setFieldValue } = form;

  const onReceivedFiles = (drillId: string, files: FileList) => {
    setUploading(drillId);
    setCompanyLogo(drillId, files, user?.token)
      .then(() => setUploading(null));
  }

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Field fid="ucf" name="name" component={FormikInput} label={messages.lblCompanyName} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mt={1}>
            <Field fid="ucf" name="timezone" label={messages.lblTimezone} component={FormikSelect} options={TIMEZONE_NAME_OPTIONS} />
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <FormLabel>Communication Branding</FormLabel>
        <Box display="flex" mt={2}>
          <DropFile onReceivedFiles={(files: FileList) => !isUploading && onReceivedFiles(company._id, files)}>
            {isUploading
              ? <Button style={{ backgroundColor: 'white' }} disabled>Uploading... <BackupIcon /></Button>
              : <Button style={{ backgroundColor: 'white' }}>Change Company Logo <AddPhotoAlternateIcon /></Button>
            }
          </DropFile>
          <Box flexGrow={1} />
          <img src={company.logo ? company.logo : logo} alt="Missing Logo" style={{ marginRight: '32px', width: '200px' }} />
        </Box>
      </Box>

      <Field fid="ucf" name="companyWebsite" label={messages.lblCompanyWebsite} component={FormikInput} />
      <Field fid="ucf" name="communicationEmail" component={FormikInput} label={messages.lblCommunicationEmail} />

      <Field fid="ucf" name="evaluationGroupName" label={messages.lblCompanyEvaluationGroupName} component={FormikInput} />
      <Field fid="ucf" name="introLink" label={messages.lblCompanyIntroLink} component={FormikInput} />

      <FormControl margin="normal">
        <PrimaryButton disabled={isSubmitting} type="submit">
          {isSubmitting ? 'Saving...' : 'Save Settings'}
        </PrimaryButton>
      </FormControl>
    </Form>
  );
};

export default UpdateCompanyForm;
