import React from 'react';

// Material UI
import Grid from '@material-ui/core/Grid';

// Components
import DashboardLayout from '../_layout/DashboardLayout/dashboard.layout';
import { PageTitle, SectionHeading } from '../../_core/_ui/typography.component';
import InviteListContainer from '../../Invite/InviteList/invite-list.container';
import InviteFormContainer from '../../Invite/InviteForm/invite-form.container';
import { PageHeaderRow, SectionWrapper } from '../../_core/_ui/structure.components';
import MemberListGraphQL from '../../Member/MemberList/member-list.graphql';
import { getAuthUser } from '../../../lib/services/auth.service';
import { PendingScoresListContainer } from '../../AdminOnly/pending-scores-list.container';

const ManageUsersPage = () => {
  const user = getAuthUser()

  return (
    <DashboardLayout hideSidebar>
      <PageHeaderRow><PageTitle>Manage Users</PageTitle></PageHeaderRow>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionWrapper>
            <MemberListGraphQL />
          </SectionWrapper>
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionWrapper>
            <SectionHeading>Send an Invite</SectionHeading>
            <InviteFormContainer />
          </SectionWrapper>
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionWrapper>
            <SectionHeading>Pending Invites</SectionHeading>
            <InviteListContainer />
          </SectionWrapper>
        </Grid>
        {!!user?.isAdmin &&
          <Grid item xs={12}>
            <SectionWrapper>
              <SectionHeading>Pending Scores</SectionHeading>
              <PendingScoresListContainer />
            </SectionWrapper>
          </Grid>
        }
      </Grid>
    </DashboardLayout>
  );
};

export default ManageUsersPage;
