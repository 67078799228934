import React, { CSSProperties } from "react"
import styled from 'styled-components';
import { sectionColor, sectionDarkColor } from '../../../lib/theme';
import { getAuthUser } from '../../../lib/services/auth.service';

export const Spacer = styled.div`
  flex-grow: 1;
`;

interface ISingleFormWrapper {
  width: string;
  padding?: string;
  maxWidth?: string;
}

export const SingleFormWrapper = styled.div<ISingleFormWrapper>`
  box-sizing: content-box;
  padding: ${props => props.padding || '48px'};
  width: ${props => props.width};
  max-width: ${props => props.maxWidth || 'auto'};

  & > *:first-child, > form:first-child > *:first-child {
    margin-top: 0;
  }

  & > *:last-child, > form:last-child > *:last-child {
    margin-bottom: 0;
  }
`;

interface ISectionWrapper {
  dark?: boolean;
  padding?: string;
}

export const SectionWrapper = styled.div<ISectionWrapper>`
  background: ${props => props.dark ? sectionDarkColor : sectionColor};
  border-radius: 8px;
  padding: ${props => props.padding  || '24px'};
  height: 100%;

  & > *:first-child, > form:first-child > *:first-child {
    margin-top: 0;
  }

  & > *:last-child, > form:last-child > *:last-child {
    margin-bottom: 0;
  }
`;

interface ISpacedRow {
  padding?: string;
}

export const SpacedRow = styled.div<ISpacedRow>`
  display: flex;
  align-items: center;
  padding: ${props => props.padding || '16px'};
  gap: 16px;
`;

export const SemiBold = styled.span`
  font-weight: 600;
`;

export const SpaceBetweenRow = styled.div<ISpacedRow>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PageHeaderRow = ({children, style}: React.PropsWithChildren<{style?: CSSProperties}>) => {
  const user = getAuthUser();
  return <SpaceBetweenRow style={style}>{children}<span>{!!user && <><SemiBold>Evaluator:  </SemiBold>{user.name}</>}</span></SpaceBetweenRow>
}