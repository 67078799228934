import RestService from '../../lib/utils/api.service';

export const handleDrillImageUpload = (drillId: string, files: FileList, token: string | undefined | null) => {
  const data = new FormData();

  data.set('uploadToCloudStorage', 'true');
  data.append('assetFile', files[0], files[0].name);

  return RestService.upload(`/drills/${drillId}/image`, data, token);
}
