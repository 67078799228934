import { defineMessages } from 'react-intl';
import { SportTermLookup, SportTerminology } from '../../../lib/types';
import { pluralTerm } from '../../../lib/helpers/term.helper';

export const scope = 'app.components.CreatePracticePlan';

export default (termLookup: SportTermLookup) => {
  return defineMessages({
    lblName: {
      id: `${scope}.lblName`,
      defaultMessage: 'Plan Name'
    },
    lblDrills: {
      id: `${scope}.lblDrills`,
      defaultMessage: `Include the following ${pluralTerm(termLookup[SportTerminology.EVENT]).toLowerCase()}:`
    },
    lblGlobal: {
      id: `${scope}.lblGlobal`,
      defaultMessage: 'Accessible to all associations'
    },
    btnSubmit: {
      id: `${scope}.btnSubmit`,
      defaultMessage: 'Create Plan',
    },
    btnSubmitting: {
      id: `${scope}.btnSubmitting`,
      defaultMessage: 'Saving...',
    }
  });
}
