import { defineMessages } from 'react-intl';
import { SportTermLookup, SportTerminology } from '../../../lib/types';

export const scope = 'app.components.PlayerContactForm';

export default (termLookup: SportTermLookup) => {
  return defineMessages({
    lblName: {
      id: `${scope}.lblName`,
      defaultMessage: 'Name'
    },
    lblEmail: {
      id: `${scope}.lblEmail`,
      defaultMessage: 'Email'
    },
    lblPhone: {
      id: `${scope}.lblPhone`,
      defaultMessage: 'Phone'
    },
    lblSendEmail: {
      id: `${scope}.lblSendEmail`,
      defaultMessage: 'Send Email Communications'
    },
    lblSendSMS: {
      id: `${scope}.lblSendSMS`,
      defaultMessage: 'Send Text Message Communications'
    },
    lblContactHeight: {
      id: `${scope}.lblContactHeight`,
      defaultMessage: 'Height (inches)'
    },
    lblContactSportInvolvement: {
      id: `${scope}.lblContactSportInvolvement`,
      defaultMessage: 'Involvement in Sport'
    },
    lblContactRelationship: {
      id: `${scope}.lblContactRelationship`,
      defaultMessage: 'Relationship to ' + termLookup[SportTerminology.ATHLETE]
    },
    btnSubmit: {
      id: `${scope}.btnSubmit`,
      defaultMessage: `Save`,
    },
    btnSubmitting: {
      id: `${scope}.btnSubmitting`,
      defaultMessage: 'Saving...',
    }
  });
}
