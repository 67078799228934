import styled from 'styled-components';

export const Logo = styled.img`
  margin: 40px 0;
  width: 400px;
`;

export const PopoverItem = styled.div`
  cursor: pointer;
  padding: 8px 12px;
  border-bottom: 1px solid #ddd;
  &:hover {
    background: #fafafa;
  }
`;
