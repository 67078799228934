import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import jersey from '../../../assets/jersey-wrapper.png';
import { ITeam } from './check-in.interface';
import { Checkbox } from '@material-ui/core';

export const COLOUR_PALETTE_RGB_MAP: { [key: string]: string } = {
  green: 'rgb(76, 175, 80)',
  red: 'rgb(244, 67, 54)',
  yellow: 'rgb(255, 235, 59)',
  black: 'rgb(0, 0, 0)',
  purple: 'rgb(156, 39, 176)',
  grey: 'rgb(158, 158, 158)',
  pink: 'rgb(233, 30, 99)',
  orange: 'rgb(255, 152, 0)',
  blue: 'rgb(33, 150, 243)',
  white: 'rgb(230, 230, 230)'
};

export const buildColors = (teamPlayers: IPlayerAssessment[], jerseys: { [key: string]: IJersey }, checkedInPlayerIds: string[], holdJerseys: boolean) => {
  const colors: { [key: string]: string[] } = {};

  teamPlayers.forEach(playerAssessment => {
    const jersey = holdJerseys ? { color: playerAssessment.jerseyColour, number: playerAssessment.jerseyNumber } : jerseys[playerAssessment._id];
    const isCheckedIn = _.includes(checkedInPlayerIds, playerAssessment._id);

    if ((isCheckedIn || holdJerseys) && jersey && jersey.color) {
      colors[playerAssessment.position.name] = colors[playerAssessment.position.name] || [];
      if (!_.includes(colors[playerAssessment.position.name], jersey.color)) {
        colors[playerAssessment.position.name].push(jersey.color);
      }
    }
  });

  return colors;
};

export function getPlayersByPosition(playerAssessments: IPlayerAssessment[]): Record<string, IPlayerAssessment[]> {
  return playerAssessments.reduce((result: { [key: string]: IPlayerAssessment[] }, playerAssessment) => {
    const positionId = playerAssessment.position._id;

    result[positionId] = result[positionId] || [];
    result[positionId].push(playerAssessment);

    return result;
  }, {});
}

// Returns a hash where the key is the position ID, and the value is an array of PlayerAssesments, sorted by rank.
export function getPlayersByPositionAndRank(positionPlayers: { [key: string]: IPlayerAssessment[] }): { [key: string]: IPlayerAssessment[] } {
  const result: { [key: string]: IPlayerAssessment[] } = {};

  Object.keys(positionPlayers).forEach((position: string) => {
    // Sort by rank.
    result[position] = positionPlayers[position].sort((a, b) => {
      return (b.adjustedScore && b.adjustedScore !== "-" ? Number(b.adjustedScore) : 0) - (a.adjustedScore && a.adjustedScore !== "-" ? Number(a.adjustedScore) : 0);
    });
  });

  return result;
}

export const buildTeamPlayers = (playerAssessments: IPlayerAssessment[], numTeams: number) => {
  const teams: IPlayerAssessment[][] = [];

  const playersByPosition = getPlayersByPosition(playerAssessments);
  const playersByPositionAndRank = getPlayersByPositionAndRank(playersByPosition);

  let currTeam = 0;
  _.each(playersByPositionAndRank, (positionPlayers) => {
    _.each(positionPlayers, (playerAssessment) => {
      teams[currTeam] = teams[currTeam] || [];
      teams[currTeam].push(playerAssessment);

      currTeam += 1;
      currTeam %= numTeams;
    });
  });

  return teams;
};

export const rebuildTeamPlayers = (teams: string[][], playerAssessments: IPlayerAssessment[]) => {
  const result: IPlayerAssessment[][] = [];
  const tracked: { [key: string]: boolean } = {};

  teams.forEach((team, index) => {
    result.push([]);

    team.forEach(playerAssessmentId => {
      const playerAssessment = _.find(playerAssessments, playerAssessments => playerAssessments._id === playerAssessmentId);

      if (playerAssessment) {
        tracked[playerAssessment._id] = true;
        result[index].push(playerAssessment);
      }
    });
  });

  const playersByPosition = getPlayersByPosition(playerAssessments);
  const playersByPositionAndRank = getPlayersByPositionAndRank(playersByPosition);

  let currTeam = 0;
  _.each(playersByPositionAndRank, (rankedPlayers) => {
    _.each(rankedPlayers, (playerAssessment) => {
      if (!tracked[playerAssessment._id] && result[currTeam]) {
        result[currTeam].push(playerAssessment);
      }

      currTeam = (currTeam + 1) % teams.length;
    });
  });

  return result;
};

export const exportTeams = (teams: ITeam[]) => {
  const result: string[][] = [];

  _.each(teams, (team, index) => {
    result.push([]);

    _.each(team.playersByPosition, (players) => {
      _.each(players, player => {
        result[index].push(player._id);
      });
    });
  });

  return result;
}

export const CHECK_IN_SORT_TYPES = {
  JERSEY: 'JERSEY',
  LAST_NAME: 'LAST_NAME',
};

export const sortByPosition = (playerAssessments: IPlayerAssessment[], sortType: string, jerseys: { [key: string]: IJersey }, checkedInPlayerIds: string[] = [], holdJerseys: boolean) => {
  const playersByPosition: { [key: string]: IPlayerAssessment[] } = {};

  playerAssessments.forEach(playerAssessment => {
    const positionName = playerAssessment.position.name;

    playersByPosition[positionName] = playersByPosition[positionName] || [];
    playersByPosition[positionName].push(playerAssessment);
  });

  const positionNames = Object.keys(playersByPosition);
  positionNames.forEach(positionName => {
    if (sortType === CHECK_IN_SORT_TYPES.JERSEY) {
      let playersWithJersey: { [key: string]: IPlayerAssessment[] } = {};
      let playersWithoutJersey: IPlayerAssessment[] = [];

      _.each(playersByPosition[positionName], playerAssessment => {
        if (holdJerseys && _.isNumber(playerAssessment.jerseyNumber) && playerAssessment.jerseyColour) {
          playersWithJersey[playerAssessment.jerseyColour.toLowerCase()] = playersWithJersey[playerAssessment.jerseyColour?.toLowerCase()] || [];
          playersWithJersey[playerAssessment.jerseyColour.toLowerCase()].push(playerAssessment);
        } else if (!holdJerseys && jerseys?.[playerAssessment._id] && _.includes(checkedInPlayerIds || [], playerAssessment._id)) {
          playersWithJersey[jerseys[playerAssessment._id].color.toLowerCase()] = playersWithJersey[jerseys[playerAssessment._id].color?.toLowerCase()] || [];
          playersWithJersey[jerseys[playerAssessment._id].color.toLowerCase()].push(playerAssessment);
        }
      });
      _.each(playersByPosition[positionName], playerAssessment => {
        if (holdJerseys && (!_.isNumber(playerAssessment.jerseyNumber) || !playerAssessment.jerseyColour)) {
          playersWithoutJersey.push(playerAssessment);
        } else if (!holdJerseys && (!jerseys?.[playerAssessment._id] || !_.includes(checkedInPlayerIds || [], playerAssessment._id))) {
          playersWithoutJersey.push(playerAssessment);
        }
      });

      _.each(playersWithJersey, (players, color) => {
        playersWithJersey[color] = _.sortBy(players, playerAssessment => {
          return holdJerseys ? playerAssessment.jerseyNumber : jerseys?.[playerAssessment._id].number;
        });
      });

      playersWithoutJersey = _.sortBy(playersWithoutJersey, playerAssessment => {
        return holdJerseys ? playerAssessment.jerseyColour?.toLowerCase() : playerAssessment.player.lastName;
      })

      playersByPosition[positionName] = [..._.flatten(_.values(playersWithJersey)), ...playersWithoutJersey];
    } else {
      playersByPosition[positionName] = _.sortBy(playersByPosition[positionName], playerAssessment => {
        return playerAssessment.player.lastName;
      });
    }
  });

  return playersByPosition;
}

interface IJerseyIcon {
  color?: string;
}

export const JerseyIcon = styled.div<IJerseyIcon>`
  background-image: url(${jersey});
  background-size: cover;
  background-color: ${props => props.color ? (COLOUR_PALETTE_RGB_MAP[props.color] || props.color) :  '#aaa'};
  width: 60px;
  height: 60px;
  padding: 22px 0;
  color: ${props => props.color === 'white' ? '#000' : '#fff'};
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  flex-shrink: 0;
`;

interface IColorPaletteItem {
  color: string;
}

interface IColorPalette {
  active?: boolean;
  color: string;
}

const ColorPaletteWrapper = styled.div<IColorPalette>`
  cursor: pointer;
  height: 57px;
  float: left;
  width: 57px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;
  background: ${props => COLOUR_PALETTE_RGB_MAP[props.color] || 'transparent'};
  box-shadow: ${props => props.active ? 'inset 0 0 0 4px rgba(0,0,0,.6)' : 'inset 0 0 0 4px rgba(0,0,0,.2)'};

  & .checkbox {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAABUElEQVRIid2WzUrDQBSF+2qtIupDuCgWBXXvI4gKig/gQtNa3YjYpmblTktdSYs/NeoDaFUkZTqTTI6LGppJ0vyYiaAXzuYOzHfvPRdmcoVyH27lFQPeXFJ578j9H0heMeBWWoAb5MjXSRaSBpmp9jFdzQiyrBFsNCl2rxn22wxzp0QupFgjYBz4pDaICWhPpvxOOi8cTjx/cPnjOr434Y4VzT+mVJD1SyoAdq7oz7Zr9jA4X1IJLHsEaDwG+zAWUqwRrJ4PsNWi2GszrF34K7x5Hfmgv3NMHUR3LkDm6wQWBwxmY2ANL1J1E5OV4flJV/Rh6Wy8D6HjKqlEqBYAHt44lA4TctutcB9ieeKt2h11PdqH2Nu12aSwbRHQ7XFMVJJtY+QKL6gEdz0O/g1bbMTzIRHE0dGtGbhtUiFp9DuQLF7FQjnkZZQFCv1I/GnIFzUZPB04niNIAAAAAElFTkSuQmCC) no-repeat;
    border-top: 4px solid rgba(0,0,0,.6);
    border-radius: 0 4px 0 0;
    position: absolute;
    bottom: 4px;
    left: 4px;
    width: 29px;
    height: 29px;
    border-right: 4px solid rgba(0,0,0,.6);
    display: ${props => props.active ? 'block' : 'none'};
  }
`;

interface IColorPaletteItem {
  onClick: ClickHandler;
  color: string;
  active?: boolean;
}

export const ColorPaletteItem: React.FC<IColorPaletteItem> = ({ onClick, color, active }) => {
  return <ColorPaletteWrapper
    color={color}
    active={active}
    onClick={onClick && onClick}
  >
    <div className="checkbox" />
  </ColorPaletteWrapper>
};

interface IValidateJesery {
  jerseys: { [key: string]: IJersey };
  checkedInPlayersHash: { [key: string]: IPlayerAssessment };
  color?: string,
  number?: number;
  playerAssessment: IPlayerAssessment;
  colorInUse: boolean;
  duplicateConfirmed: boolean;
  playerAssessments: IPlayerAssessment[];
  holdJerseys: boolean;
}

export const validateJersey = ({ jerseys, checkedInPlayersHash, playerAssessment, color, number, colorInUse, duplicateConfirmed, playerAssessments, holdJerseys }: IValidateJesery) => {
  let status;

  if (_.isNull(number) || _.isUndefined(number) || _.isNull(color) || _.isUndefined(color)) {
    status = 'WAITING';
  } else if (_.isNumber(number) && number >= 0 && _.isString(color) && color !== '') {
    status = 'VALID';

    const playerAssessmentIds = holdJerseys ? _.map(playerAssessments, '_id') : Object.keys(jerseys);
    const playerAssessmentsById = _.keyBy(playerAssessments, '_id');

    for (let i = 0; i < playerAssessmentIds.length && status === 'VALID'; i += 1) {
      const currPlayerAssessmentId = playerAssessmentIds[i];
      const currPlayer = playerAssessmentsById[currPlayerAssessmentId];
      const currJersey = holdJerseys
        ? { color: currPlayer.jerseyColour, number: currPlayer.jerseyNumber }
        : jerseys[currPlayerAssessmentId];

      if (number === currJersey.number
        && color === currJersey.color
        && currPlayer
        && currPlayer?._id !== playerAssessment._id) {
        status = `Jersey is in use by ${currPlayer.player.firstName} ${currPlayer.player.lastName}`;
      }
    }
  } else {
    status = 'Select a color and entered a single number for jersey';
  }

  if (colorInUse && !duplicateConfirmed) {
    status = 'WAITING';
  }

  return status;
}

interface IValidateColor {
  color: string;
  teams: ITeam[];
  playerAssessment?: IPlayerAssessment;
  teamIndex: number;
}

export const validateColor = ({ color, teams, playerAssessment, teamIndex }: IValidateColor) => {
  if (!playerAssessment) return null;

  const activePosition = playerAssessment?.position.name;
  let result = null;

  _.each(teams, (team, currTeamIndex) => {
    _.each(team.colors, (positionColors, positionName) => {
      if ((positionName !== activePosition || Number(teamIndex) !== currTeamIndex) && _.includes(positionColors, color)) {
        result = {
          team: teamIndex + 1,
          position: positionName
        };
      }
    });
  });

  const currTeamColors = _.get(teams, [teamIndex, 'colors', activePosition], []);
  if (_.includes(currTeamColors, color)) {
    result = null;
  }

  return result;
}
