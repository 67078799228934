import { defineMessages } from 'react-intl';
import { SportTermLookup, SportTerminology } from '../../../lib/types';

export const scope = 'app.components.CreateAssessmentSessionForm';

export default (termLookup: SportTermLookup) => {
  return defineMessages({
    lblLocation: {
      id: `${scope}.lblLocation`,
      defaultMessage: termLookup[SportTerminology.FACILITY] + ' Name'
    },
    lblArea: {
      id: `${scope}.lblArea`,
      defaultMessage: termLookup[SportTerminology.FIELD] + ' Name'
    },
    lblAddress: {
      id: `${scope}.lblAddress`,
      defaultMessage: termLookup[SportTerminology.FACILITY_ADDRESS]
    },
    lblDate: {
      id: `${scope}.lblDate`,
      defaultMessage: 'Date'
    },
    lblStart: {
      id: `${scope}.lblStart`,
      defaultMessage: 'Start Time'
    },
    lblDuration: {
      id: `${scope}.lblDuration`,
      defaultMessage: 'Duration'
    },
    lblGender: {
      id: `${scope}.lblGender`,
      defaultMessage: 'Gender'
    },
    lblAgeGroup: {
      id: `${scope}.lblAgeGroup`,
      defaultMessage: 'Age Group'
    },
    btnSubmit: {
      id: `${scope}.btnSubmit`,
      defaultMessage: `Create ${termLookup[SportTerminology.SESSION]}`,
    },
    btnSubmitting: {
      id: `${scope}.btnSubmitting`,
      defaultMessage: 'Saving...',
    }
  });
}
