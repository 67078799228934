import _ from 'lodash';
import styled from 'styled-components';
import { Sport } from '../../../lib/types';

interface IStatusIcon {
  status: string;
}

export const STATUS_COLORS: { [key: string]: string } = {
  NONE: '#f44336',
  PARTIAL: '#fbc02d',
  FILLED: '#4caf50'
}

export const JerseyIconList = styled.div`
  display: flex;
  background: #fff;
  border-bottom: 4px solid #9db719;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: auto;
  width: 100%;
  z-index: 100;
  height: 80px;
`;

export const JerseyPlayerList = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-bottom: 4px solid #9db719;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: auto;
  height: 100%;
  z-index: 100;
  width: 240px;
`;

interface IJerseyIconWrapper {
  isLabel?: boolean;
  active?: boolean;
}

export const JerseyIconWrapper = styled.div<IJerseyIconWrapper>`
  padding: 8px;
  border-right: 1px solid #ddd;
  display: flex;
  align-items: center;
  background: ${props => props.isLabel ? '#eee' : 'transparent'};
  border-top: 2px solid ${props => props.active ? '#9db719' : (props.isLabel ? '#eee' : '#fff')};
  border-bottom: none;
  font-weight: 500;
  position: relative;
  cursor: ${props => props.isLabel ? 'auto' : 'pointer'};
  text-align: center;
`;

export const JerseyPlayerWrapper = styled.div<IJerseyIconWrapper>`
  padding: 8px;
  display: flex;
  align-items: center;
  background: ${props => props.isLabel ? '#eee' : '#fff'};
  ${props => props.active && 'border-left: 8px solid #9db719;'}
  border-top: 1px solid #ddd;
  font-weight: 500;
  position: relative;
  cursor: ${props => props.isLabel ? 'auto' : 'pointer'};
  text-align: center;
`;

export const StatusIcon = styled.div<IStatusIcon>`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${props => STATUS_COLORS[props.status]};
  box-shadow: 0 0 4px ${props => STATUS_COLORS[props.status]};
`;

export const groupByPosition = (players: IPlayerAssessment[], sport: Sport) => {
  return _.reduce(players, (result: { [key: string]: IPlayerAssessment[] }, playerAssessment: IPlayerAssessment) => {
    if (sport === Sport.hockey) {
      if (playerAssessment.position.name === 'Goalie') {
        result.Goalie = result.Goalie || [];
        result.Goalie.push(playerAssessment);
      } else {
        result.Skater = result.Skater || [];
        result.Skater.push(playerAssessment);
      }
    } else {
      result[playerAssessment.position.name] = result[playerAssessment.position.name] || [];
      result[playerAssessment.position.name].push(playerAssessment)
    }

    return result;
  }, {});
};

export const sortByJersey = (players: IPlayerAssessment[], jerseys: { [key: string]: IJersey }) => {
  const sortedByNum = _.sortBy(players, (player => jerseys[player._id]?.number));
  const jerseyGroups = _.groupBy(sortedByNum, (player => jerseys[player._id]?.color));

  return _.flatten(_.values(jerseyGroups));
};

export const ReviewScoreItem = styled.div`
  padding: 12px 4px;
  line-height: 0;
  margin-right: 4px;
  color: #fff;
  border-radius: 2px;
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
  background: #BDD739;
`;

export const getMinutes = (time: number) => formatTime(
  Math.floor(time / 60000) % 60
);

export const getSeconds = (time: number) => formatTime(
  Math.floor(time / 1000) % 60
);

export const getHundredthSeconds = (time: number) => formatTime(
  Math.floor(time / 10) % 100
);

const formatTime = (time: number) => {
  return time < 10 ? `0${time}` : time.toString();
}

export const flattenScores = (scoreHash: { [key: string]: any }, depth = 0): any[] => {
  const scores: any[] = [];

  for (let key in scoreHash) {
    const value = scoreHash[key];

    if (value && typeof value === 'object' && value.hasOwnProperty('raw')) {
      scores.push(value);
    } else if (typeof value === 'object' && depth < 4) {
      scores.push(...flattenScores(value, depth + 1));
    }
  }

  return scores;
};
