import _ from 'lodash';
import { makeVar, useMutation, useQuery, useReactiveVar } from '@apollo/client';

// Data
import { getCurrUser } from './user.service';
import { GET_COMPANY_BY_DOMAIN, GET_COMPANY_BY_ID, REGISTER_COMPANY, UPDATE_COMPANY } from '../queries/company.queries';
import { IRegisterCompanyProps } from '../../components/Company/RegisterCompanyForm/register-company-form.interfaces';
import { IUpdateCompanyProps } from '../../components/Company/UpdateCompanyForm/update-company-form.interfaces';
import { store } from '../utils/storage';
import logo from '../../assets/logo.png';
import RestService from '../utils/api.service';
import { Sport, SportTermLookup } from "../types";
import { SPORT_TERMINOLOGY } from "../constants";

export const companyHasAutomation = makeVar<boolean>(false);
export const getCompanyHasAutomation = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(companyHasAutomation);
};
export const setCompanyHasAutomation = (hasAutomation: boolean) => companyHasAutomation(hasAutomation);

export const companyHasCompositeVideos = makeVar<boolean>(false);
export const getCompanyHasCompositeVideos = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(companyHasCompositeVideos);
};
export const setCompanyHasCompositeVideos = (hasCompositeVideos: boolean) => companyHasCompositeVideos(hasCompositeVideos);

export const companyHasReportCards = makeVar<boolean>(false);
export const getCompanyHasReportCards = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(companyHasReportCards);
};
export const setCompanyHasReportCards = (hasReportCards: boolean) => companyHasReportCards(hasReportCards);

export const companyLogo = makeVar(logo);
export const getCompanyLogo = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useReactiveVar(companyLogo);
export const setCompanyLogo = (nothing: string) => {//async (logo: string) => {
  // store.setItem('mea_logo', logo);
  companyLogo(logo);
};

export const companySportTerminology = makeVar<SportTermLookup>(
  SPORT_TERMINOLOGY[Sport.default]
);
export const getCompanySportTerminology = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(companySportTerminology);
};

export const companySport = makeVar<Sport>(Sport.default);
export const getCompanySport = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(companySport);
};
export const setCompanySport = (sport: Sport) => {
  companySport(sport);
  companySportTerminology(SPORT_TERMINOLOGY[sport]);
};

export const getActiveCompanyId = () => {
  const { user } = getCurrUser();

  return _.get(user, 'settings.activeCompanyId');
};

export const getCompany = (_id: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, ...rest } = useQuery(GET_COMPANY_BY_ID, {
    skip: !_id,
    variables: { _id },
    fetchPolicy: 'cache-and-network'
  });

  return { ...rest, company: _.get(data, 'company') };
};

export const getCompanyByDomain = (domain: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, ...rest } = useQuery(GET_COMPANY_BY_DOMAIN, {
    skip: !domain,
    variables: { domain },
    fetchPolicy: 'network-only'
  });

  return { ...rest, company: _.get(data, 'companyByDomain') };
};

export const buildRegisterCompany = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [register, ...rest] = useMutation(REGISTER_COMPANY);

  return {
    ...rest,
    registerCompany: (data: IRegisterCompanyProps) =>
      register({ variables: { data } })
  }
};

export const buildUpdateCompany = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [update, ...rest] = useMutation(UPDATE_COMPANY);
  const activeCompanyId = getActiveCompanyId();

  return {
    ...rest,
    updateCompany: (data: IUpdateCompanyProps) =>
      update({ variables: { _id: activeCompanyId, data } })
  };
}

export const handleCompanyLogoUpload = (companyId: string, files: FileList, token: string | undefined | null) => {
  const data = new FormData();

  data.set('uploadToCloudStorage', 'true');
  data.append('assetFile', files[0], files[0].name);

  return RestService.upload(`/companies/${companyId}/logo`, data, token);
}
