import axios, { AxiosRequestConfig } from 'axios';
import { BASE_API_URL, HTTP_PROTOCOL } from '../constants';
import { OutgoingHttpHeaders } from 'http2';

class RestService {
  static http = axios.create({
    baseURL: `${HTTP_PROTOCOL}://${BASE_API_URL}`,
    withCredentials: true
  });

  static async post(endpoint: string, payload = {}, token?: string) {
    const headers: any = {
      'Content-Type': 'application/json'
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return fetch(`${HTTP_PROTOCOL}://${BASE_API_URL}${endpoint}`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify(payload)
    }).then(response => response.status === 200 ? response.json() : response)
  }

  static async upload(endpoint: string, formData: any, token: string | null | undefined, options: any = {}) {
    RestService.http.defaults.headers.common.Authorization = `Bearer ${token}`;

    return RestService.http({
      method: 'post',
      url: `${HTTP_PROTOCOL}://${BASE_API_URL}${endpoint}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...options
    });
  }
}

export default RestService;
