import _ from 'lodash';
import React from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

// Data
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { SportTerminology } from '../../../lib/types';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { skillToSkillOption } from '../../../lib/utils/skill.service';
import { SKILL_TYPE_OPTIONS } from '../../../lib/constants';

// Components
import { FormInput } from '../../_core/_ui/forms.component';
import { EnhancedTable, IEnhancedTableRow } from '../../_core/_ui/table.components';
import { PrimaryButton, SecondaryButton } from '../../_core/_ui/buttons.component';
import { Spacer } from '../../_core/_ui/structure.components';
import ArchiveIcon from '@material-ui/icons/Archive';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Tooltip from '@material-ui/core/Tooltip';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ToggleGlobal from '../../User/ToggleGlobal/toggle-global.component';

interface ISkillList {
  skills: Array<any>;
  countArchivedSkills: number;
  searchSkills: Function;
  loading: boolean;
  fetchError: boolean;
  selected: ISkill[];
  setSelected: Function;
  viewingArchived: boolean;
  setViewingArchived: Function;
  removeSkills: Function;
  restoreSkills: Function;
  isAdmin: boolean;
}

const SkillList: React.FC<ISkillList> = ({
  skills,
  searchSkills,
  loading,
  fetchError,
  selected,
  setSelected,
  countArchivedSkills,
  viewingArchived,
  setViewingArchived,
  removeSkills,
  restoreSkills,
  isAdmin
}) => {
  const termLookup = getCompanySportTerminology()
  const metricsTerm = pluralTerm(termLookup[SportTerminology.METRIC]);

  const rows: IEnhancedTableRow[] = [];

  _.each(skills, skill => {
    const skillOption = skillToSkillOption(skill);
    const row: IEnhancedTableRow = {
      _id: skill._id,
      data: skill,
      cells: [
        <TableCell key="name">{skill.name}</TableCell>,
        <TableCell key="type">{skillOption ? SKILL_TYPE_OPTIONS.find(({value}) => value === skillOption)?.label ?? 'Unknown' : 'Unknown'}</TableCell>,
      ]
    };

    if (!skill.companyId && !isAdmin) row.disableSelect = true;
    rows.push(row);
  });

  const columns = [
    { key: 'name', label: 'Name', path: 'name' },
    { key: 'type', label: 'Type' },
  ];

  return (
    <>
      <EnhancedTable
        numCols={3}
        columns={columns}
        rows={rows}
        defaultOrder="name"
        entityName="skills"
        showError={fetchError}
        showLoading={loading}
        title={viewingArchived ? 'Archived ' + metricsTerm : metricsTerm}
        selected={selected}
        setSelected={setSelected}
        selectedAction={(
          <Tooltip title={viewingArchived ? 'Restore' : 'Delete'}>
            <IconButton aria-label="delete" onClick={() => {
              viewingArchived ? restoreSkills(selected) : removeSkills(selected);
              setSelected([]);
            }}>
              {viewingArchived ? <UnarchiveIcon /> : <DeleteIcon />}
            </IconButton>
          </Tooltip>
        )}
        filterComponent={(<>
          <Box mr={2} flexShrink={0}><ToggleGlobal label={"Show Max Analytics Marketplace " + metricsTerm} /></Box>
          <FormInput
            placeholder={viewingArchived ? `Search archived ${metricsTerm.toLowerCase()}...` : `Search ${metricsTerm.toLowerCase()}...`}
            onKeyUp={(e: any) => searchSkills(e.currentTarget.value)} />
        </>)}
      />
      {countArchivedSkills > 0 && !viewingArchived && (
        <Box display="flex">
          <Spacer />
          <SecondaryButton startIcon={<ArchiveIcon />} onClick={() => setViewingArchived(true)}>View Archived {metricsTerm}</SecondaryButton>
        </Box>
      )}
      {viewingArchived && (
        <Box display="flex">
          <Spacer />
          <PrimaryButton startIcon={<ArrowBackIosIcon />} onClick={() => setViewingArchived(false)}>Back to {metricsTerm}</PrimaryButton>
        </Box>
      )}
    </>
  );
};

export default SkillList;
