import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import dayjs from 'dayjs';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Component
import { EnhancedTable, IEnhancedTableColumn, IEnhancedTableRow } from '../../_core/_ui/table.components';
import { Spacer } from '../../_core/_ui/structure.components';
import { PrimaryButton } from '../../_core/_ui/buttons.component';
import { getLastUpdated } from '../PlayerRankingReport/player-ranking-report.component';
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { SportTerminology } from '../../../lib/types';

interface ISkillComparisonReport {
  columns: IEnhancedTableColumn[];
  reportRows: IEnhancedTableRow[],
  loading: boolean;
  fetchError: boolean;
  exportReports: ClickHandler;
}

const SkillComparisonReport: React.FC<ISkillComparisonReport> = ({
  columns,
  reportRows,
  fetchError,
  loading,
  exportReports
}) => {
  const termLookup = getCompanySportTerminology();
  const currLastUpdated = getLastUpdated();

  return (
    <>
      <Box mb={2} display="flex" alignItems="center">
        <Typography>Reports are automatically updated every 10 minutes (last updated at {currLastUpdated.format('h:mm A')})</Typography>
        <Spacer />
        <PrimaryButton onClick={exportReports}>Export Report</PrimaryButton>
      </Box>
      <EnhancedTable
        stickyHeader
        maxHeight="800px"
        numCols={5}
        rows={reportRows}
        columns={columns}
        defaultOrder="rank"
        entityName="players"
        showError={fetchError}
        showLoading={loading && reportRows.length === 0}
        title={termLookup[SportTerminology.METRIC] + " Comparison"}
      />
    </>
  );
};

export default SkillComparisonReport;
