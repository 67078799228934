import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

// Material UI
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// Data
import { COMMUNICATION_FIELDS } from '../../../lib/queries/email-log.queries';
import dayjs from 'dayjs';
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { SportTerminology } from '../../../lib/types';
import { Box } from '@material-ui/core';
import { PrimaryButton } from '../../_core/_ui/buttons.component';
import { getAuthUser } from '../../../lib/services/auth.service';

const GET_COMMUNICATION_LIST = gql`
  query GetCommunicationList($playerAssessmentId: ID!) {
    communications(playerAssessmentId: $playerAssessmentId) {
      ...CommunicationFields
    }
  }

  ${COMMUNICATION_FIELDS}
`;

const RESEND_COMMUNICATION = gql`
  mutation ResendCommunication($assessmentId: ID!, $communicationId: ID!) {
    resendCommunication(assessmentId: $assessmentId, communicationId: $communicationId)
  }
`;

interface ICommunicationListGraphQL {
  playerAssessmentId: string;
}

const CommunicationListGraphQL: React.FC<ICommunicationListGraphQL> = ({ playerAssessmentId }) => {
  const user = getAuthUser();

  const { data, loading, error } = useQuery(GET_COMMUNICATION_LIST, {
    variables: { playerAssessmentId },
    fetchPolicy: 'cache-and-network'
  });

  const [resendCommunication] = useMutation(RESEND_COMMUNICATION)

  const logs: ICommunication[] = (data && data.communications) || [];

  const termLookup = getCompanySportTerminology()
  const sessionTerm = termLookup[SportTerminology.SESSION];
  const emailTypeLabels: { [key: string]: string } = {
    WELCOME: 'Welcome Email',
    SESSION_START: `${sessionTerm} Start`,
    SESSION_UPDATE: `${sessionTerm} Update`,
    SESSION_REMINDER: `${sessionTerm} Reminder`,
    SESSION_CANCELLED: `${sessionTerm} Cancelled`,
    HOLD: `On Hold`,
    REPORT_CARD: 'Report Card',
    LEVEL_ASSIGNMENT: 'Level Assigned',
    TEAM_ASSIGNMENT: 'Team Assigned',
    CONCLUDE: 'Assessment Concluded'
  }

  if (logs.length === 0 && loading) return <Typography>Loading...</Typography>;

  return (
    <List style={{ maxHeight: '400px', overflowY: 'auto' }}>
      {logs.map(log => {
        const date = log.scheduledAt
          ? `Scheduled @ ${dayjs(log.scheduledAt).format('YYYY/MM/DD h:mm A')}`
          :  `Sent @ ${dayjs(log.sentAt).format('YYYY/MM/DD h:mm A')}`;

        return (
          <ListItem key={log._id}>
            <Box display="flex" alignItems="center" justifyItems="space-between" width="100%">
              <ListItemText
                primary={`${emailTypeLabels[log.type]}`}
                secondary={<span>
                  <span>{log.to}</span><br />
                  <span>{date}</span>
                </span>}
              />
              <Box>
                <PrimaryButton
                  onClick={() => {
                      if (window.confirm("Are you sure you want to resend notification?")) {
                        resendCommunication({variables: {assessmentId: user?.settings.activeAssessmentId ?? "", communicationId: log._id}})
                          .then((result) => alert(result ? "Resent notification" : "Failed to resend notification"))
                          .catch(() => alert("Failed to resend notification"))
                      }
                    }
                  }>
                  Resend
                </PrimaryButton>
              </Box>
            </Box>
          </ListItem>
        );
      })}
      {logs.length === 0 && (
        <ListItem>
          <ListItemText primary="None" />
        </ListItem>
      )}
    </List>
  );
};

export default CommunicationListGraphQL;
