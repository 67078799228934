import _ from 'lodash';
import { ApolloClient, from } from '@apollo/client';
import { createHttpLink } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import SerializingLink from 'apollo-link-serialize';
import QueueLink from 'apollo-link-queue';
import { persistCache } from 'apollo3-cache-persist';

import { GRAPHQL_HTTP_URI } from './constants';
import { authUser } from './services/auth.service';
import trackerLink from './utils/tracker.link';
import { store } from './utils/storage';
import { IonicStorageWrapper } from './utils/ionic-storage-wrapper';
import { recordUserSession } from './utils/user-session.service';

let currToken: string | undefined | null;

async function createApolloClient(currUser: any) {
  if (currUser) {
    authUser(currUser);
  }

  const serializingLink = new SerializingLink();
  const queueLink = new QueueLink();

  const httpLink = createHttpLink({
    uri: GRAPHQL_HTTP_URI,
  });

  // Authentication
  const authLink = setContext(async (ctx, { headers }) => {
    const nextToken = await store.get('mea_token');

    if (currToken !== nextToken) {
      currToken = nextToken;
    }

    const authorization = currToken ? `Bearer ${nextToken}` : null;

    return { headers: { authorization } };
  });

  const storage = new IonicStorageWrapper(store);
  const cache = new InMemoryCache();
  await persistCache({ cache, storage });

  const link = from([
    trackerLink(),
    queueLink,
    serializingLink,
    authLink.concat(httpLink)
  ]);

  const client = new ApolloClient({ cache, link, resolvers: {} });
  await recordUserSession(currUser)

  if (!currUser) {
    await client.clearStore();

    if (window.location.pathname !== '/') {
      await store.set('logout', String(Date.now()));
    }
  }

  return { client, queueLink };
};

export default createApolloClient;
