import _ from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { makeVar, useApolloClient, useMutation, useReactiveVar } from '@apollo/client';

import { removeTrackedQuery, trackedQueries } from '../../../lib/utils/tracker.link';
import { UPDATE_HANDLERS } from '../../../lib/services/update-handler.service';

import LoadingPage from '../../_pages/_boilerplate/LoadingPage/loading-page.container';
import { getQueueOpen, isSyncing } from '../PersistGateContainer/persist-gate.container';
import { RECORD_USER_SESSION_ACTION } from '../../Score/ManageEvaluations/manage-evaluations.graphql';
import { store } from '../../../lib/utils/storage';
import { getAuthUser } from '../../../lib/services/auth.service';
import { filterUserSessions, getCachedUserSessions, reduceUserActions, removeUserSessionActions } from '../../../lib/utils/user-session.service';

interface IOfflineHydrator {
  children: ReactNode;
}

let timeout: any;
export const USER_SESSIONS_LOCAL_REF = 'max_UserSessions';
export const QUERY_LOCAL_REF = 'max_TrackedQueries';

export const isSavingScores = makeVar<boolean>(false);
export const getIsSavingScores = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(isSavingScores);
};

export const shouldRefetchAfterOfflineSync = makeVar<boolean>(false);
export const getShouldRefetchAfterOfflineSync = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactiveVar(shouldRefetchAfterOfflineSync);
};


const actionSort = (a: IUserAction, b: IUserAction) => {
  if (a.actionType !== b.actionType) {
    if (a.actionType === "score") return -1;
    else if (b.actionType === "score") return 1;
    else if (a.actionType === "compositeScore") return -1;
    else if (b.actionType === "compositeScore") return 1;
    else if (a.actionType === "removeScore") return -1;
    else if (b.actionType === "removeScore") return 1;
    return -1;
  }

  return new Date(b.loggedAt).getTime() - new Date(a.loggedAt).getTime()
}

const OfflineHydrator: React.FC<IOfflineHydrator> = ({ children }) => {
  const user = getAuthUser();
  const client = useApolloClient();
  const isQueueOpen = getQueueOpen();
  const savingScores = getIsSavingScores()
  const [isLoaded, setLoaded] = useState(false);
  const [isHydrated, setIsHydrated] = useState(false);
  const [recordUserSessionActionMutation] = useMutation(RECORD_USER_SESSION_ACTION);


  useEffect(() => {
    if (isHydrated && !isQueueOpen) {
      console.log("Went offline, prepare to sync when reconnected")
      setIsHydrated(false)
    }
  }, [isHydrated, isQueueOpen])

  useEffect(() => {
    const hydrate = async () => {
      console.log("Begin offline query hydration")
      const promises: Array<Promise<any>> = [];

      const currQueries = trackedQueries();

      if (!currQueries) {
        try {
          trackedQueries(JSON.parse(await store.get(QUERY_LOCAL_REF) || '').queries || []);
        } catch (e) {
          trackedQueries([]);
        }
      }

      trackedQueries().forEach(trackedQuery => {
        const context = JSON.parse(trackedQuery.contextJSON);
        const query = JSON.parse(trackedQuery.queryJSON);
        const variables = JSON.parse(trackedQuery.variablesJSON);

        promises.push(
          client.mutate({
            context,
            mutation: query,
            optimisticResponse: context.optimisticResponse,
            update: UPDATE_HANDLERS[trackedQuery.name],
            variables
          })
        );

        removeTrackedQuery(trackedQuery.id);
      });

      try {
        if (promises.length) isSyncing(true);
        Promise.all(promises)
          .then(() => {
            if (promises.length) {
              if (timeout) clearTimeout(timeout);
              timeout = setTimeout(() => {
                isSyncing(false);
              }, 3000);
            }
          });
      } catch(e) {
        // ALLOW TRACKED QUERIES TO FAIL
        console.error('Offline mutation hydration failed...', e);
      }

      console.log("Finished offline query hydration, app is now loaded")
      setLoaded(true);

      if (user) {
        console.log("Begin hydrating offline scores")
        try {
          setIsHydrated(true)
          isSavingScores(true);

          // Loop through all sessions for curr user
          const sessions = await getCachedUserSessions()
          for (const [userId, userSessions] of Object.entries(sessions)) {
            const sessionsToDelete: number[] = []
            if (userSessions?.length) {
              for (let i = 0; i < userSessions.length; i++) {
                const session = userSessions[i]
                console.log(`Submitting session ${i + 1} of ${userSessions.length} for user ${userId}`, {session})
                let allSessionActionsSucceeded = !session.actions.length  // Default to true if no actions

                // Submit all actions for each stored session
                if (session.actions.length) {
                  let remainingActions = 0

                  // Batch actions in series of a hundred here
                  const uniqueSessionActions = _.uniqBy([...session.actions], ({loggedAt}) => loggedAt)
                  const chunks = _.chunk(uniqueSessionActions.sort(actionSort), 100)
                  console.log(`Found ${session.actions.length} of which ${uniqueSessionActions.length} are unique. Record user session actions in ${chunks.length} chunks`)

                  for (let j = 0; j < chunks.length; j++) {
                    const chunk = chunks[j]
                    const variables = {...session, actions: reduceUserActions(chunk), newUserId: userId, skipDuplicateActions: true}
                    console.log(`Record user session action chunk ${j + 1} of ${chunks.length}`, {variables, chunk})
                    try {
                      const actionResults = await recordUserSessionActionMutation({variables})
                      if (actionResults.data.recordUserSession) {
                        const matchingSucceededActions = actionResults.data.recordUserSession.reduce((curr: IUserAction[], a: {loggedAt: Date}) => {
                          const matches = session.actions.filter((sa) => new Date(sa.loggedAt).getTime() === new Date(a.loggedAt).getTime())
                          curr.push(...matches);

                          return curr;
                        }, [])

                        // Remove all successful session actions from local storage
                        console.log(`Chunk ${j + 1} result`, {actionsCount: chunk.length, succeededActionsCount: matchingSucceededActions.length, userId})
                        if (matchingSucceededActions.length) {
                          const remainingChunkActions = await removeUserSessionActions(userId, session, matchingSucceededActions)
                          if (remainingChunkActions) remainingActions += remainingChunkActions
                          shouldRefetchAfterOfflineSync(true)
                        }
                      }
                    } catch (err) {
                      console.error("Failed to record user session", err, {i, userId})
                    }
                  }

                  // Explicitly test for 0 not falsy, due to possible null response
                  if (remainingActions === 0) allSessionActionsSucceeded = true
                }

                if (allSessionActionsSucceeded) {
                  // Delete any empty sessions on successful handlel of all actions. Any sessions with actions that failed should keep those actions in storage.
                  // If last session (ie. curr session) then delete actions only, otherwise delete session if empty
                  if (i === userSessions.length - 1 && userId === user._id)
                    userSessions[i].actions = []
                  else sessionsToDelete.push(i)
                }
              }

              await filterUserSessions(userId, userSessions.filter((_, i) => sessionsToDelete.includes(i)))
            }
          }
        } catch(e) {
          console.error("Failed on score hydration", e);
        } finally {
          console.log("Finished hydrating offline scores")
          isSavingScores(false);
        }
      } else console.log("Skipped offline score hydration due to lack of user access")
    };

    if (!savingScores && !isHydrated && isQueueOpen) hydrate();
  }, [isQueueOpen, user, isHydrated, savingScores]);

  if (!isLoaded) return <LoadingPage />;

  return <>{children}</>;
};

export default OfflineHydrator;