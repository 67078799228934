import _ from 'lodash';
import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// Material UI
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Box from '@material-ui/core/Box';
import ArchiveIcon from '@material-ui/icons/Archive';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Data
import { getCompanyHasReportCards, getCompanySportTerminology } from '../../../lib/services/company.service';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { SportTerminology } from '../../../lib/types';

// Components
import { EnhancedTable, IEnhancedTableRow } from '../../_core/_ui/table.components';
import { FormInput } from '../../_core/_ui/forms.component';
import { Spacer } from '../../_core/_ui/structure.components';
import { PrimaryButton, SecondaryButton } from '../../_core/_ui/buttons.component';
import { ROLES } from '../../../../_configuration';

const totalRoles = Object.keys(ROLES).length;

dayjs.extend(relativeTime);

interface IMemberList {
  revokeAccess: Function;
  restoreAccess: Function;
  currUser: IUser;
  members: IMember[];
  countArchivedMembers: number;
  loading: boolean;
  fetchError: boolean;
  search: Function;
  selected: IMember[];
  setSelected: Function;
  viewingArchived: boolean;
  setViewingArchived: Function;
  updateMemberRoles: Function;
  toggleRole: Function;
  currMember?: IMember;
}

const MemberList: React.FC<IMemberList> = ({
  revokeAccess,
  restoreAccess,
  currUser,
  members,
  countArchivedMembers,
  loading,
  fetchError,
  search,
  selected,
  setSelected,
  viewingArchived,
  setViewingArchived,
  updateMemberRoles,
  toggleRole,
  currMember
}) => {
  const rows: IEnhancedTableRow[] = [];

  _.each(members, (member: IMember) => {
    const isCurrMember = currMember && member._id === currMember._id;

    rows.push({
      _id: member._id,
      data: member,
      disableSelect: isCurrMember,
      cells: [
        <TableCell key="name">{member.user.name}<br />{member.user.email}</TableCell>,
        <TableCell align="center" key="all">
          <Checkbox
            checked={member.roles.length === totalRoles}
            onClick={() => {
              const roles = member.roles.length === totalRoles ? [] : Object.keys(ROLES);
              if (!member.roles.includes(ROLES.USER_MANAGEMENT) && currMember?._id === member._id) {
                roles.push(ROLES.USER_MANAGEMENT);
              }

              updateMemberRoles(member, roles);
            }}
          />
        </TableCell>,
        <TableCell align="center" key="checkin">
          <Checkbox
            checked={_.includes(member.roles, 'CHECK_IN')}
            onClick={() => toggleRole(member, 'CHECK_IN')}
          />
        </TableCell>,
        <TableCell align="center" key="scoring">
          <Checkbox
            checked={_.includes(member.roles, 'SCORING')}
            onClick={() => toggleRole(member, 'SCORING')}
          />
        </TableCell>,
        <TableCell align="center" key="playerNames">
          <Checkbox
            checked={_.includes(member.roles, 'PLAYER_NAMES')}
            onClick={() => toggleRole(member, 'PLAYER_NAMES')}
          />
        </TableCell>,
        <TableCell align="center" key="reports">
          <Checkbox
            checked={_.includes(member.roles, 'REPORTS')}
            onClick={() => toggleRole(member, 'REPORTS')}
          />
        </TableCell>,
        <TableCell align="center" key="configuration">
          <Checkbox
            checked={_.includes(member.roles, 'CONFIGURATION')}
            onClick={() => toggleRole(member, 'CONFIGURATION')}
          />
        </TableCell>,
        <TableCell align="center" key="users">
          <Checkbox
            disabled={isCurrMember}
            checked={_.includes(member.roles, 'USER_MANAGEMENT')}
            onClick={() => toggleRole(member, 'USER_MANAGEMENT')}
          />
        </TableCell>
      ]
    });
  });

  const hasReportCards = getCompanyHasReportCards()
  const termLookup = getCompanySportTerminology()
  const athletesTerm = pluralTerm(termLookup[SportTerminology.ATHLETE].toLowerCase())
  const sessionsTerm = pluralTerm(termLookup[SportTerminology.SESSION].toLowerCase())

  const columns = [
    { key: 'name', label: 'Name', path: 'member.user.name' },
    { key: 'all', label: 'All Roles', disableSort: true },
    { key: 'checkin', label: 'Check-In', disableSort: true, helper: 'Allows access to check-in ' + athletesTerm},
    { key: 'evaluator', label: 'Scoring', disableSort: true, helper: `Allows access to evaluation tools and upcoming ${sessionsTerm}` },
    { key: 'playerNames', label: termLookup[SportTerminology.ATHLETE] + ' Names', disableSort: true, helper: `Allows access to ${termLookup[SportTerminology.ATHLETE].toLowerCase()} names while evaluating ${athletesTerm}` },
    { key: 'reports', label: 'Reports', disableSort: true, helper: `Allows access to summary reports${hasReportCards ? "," : " and"} detailed ${termLookup[SportTerminology.ATHLETE]} scores${hasReportCards ? ", and report cards" : ""}` },
    { key: 'config', label: 'Configuration', disableSort: true, helper: `Allows access to import and create ${sessionsTerm}, ${athletesTerm}, ${pluralTerm(termLookup[SportTerminology.METRIC])}, and assessment scheduling configuration` },
    { key: 'users', label: 'User Management', disableSort: true, helper: 'Allows access to invite and manage users' },
  ];

  return (
    <>
      <EnhancedTable
        numCols={7}
        rows={rows}
        columns={columns}
        defaultOrder="player.lastName"
        entityName="players"
        showError={fetchError}
        showLoading={loading && rows.length === 0}
        title="Users"
        selected={selected}
        setSelected={setSelected}
        selectedAction={(
          <Tooltip title={viewingArchived ? 'Restore Access' : 'Revoke Access'}>
            <IconButton onClick={() => {
              const _ids = selected.map(item => item._id);
              viewingArchived ? restoreAccess(_ids) : revokeAccess(_ids);
              setSelected([]);
            }}>
              {viewingArchived ? <UnarchiveIcon /> : <DeleteIcon />}
            </IconButton>
          </Tooltip>
        )}
        filterComponent={(
          <FormInput
            placeholder={viewingArchived ? `Search archived users...` : `Search users...`}
            onKeyUp={(e: any) => search(e.currentTarget.value)} />
        )}
      />

      {countArchivedMembers > 0 && !viewingArchived && (
        <Box display="flex">
          <Spacer />
          <SecondaryButton startIcon={<ArchiveIcon />} onClick={() => setViewingArchived(true)}>View Archived Users</SecondaryButton>
        </Box>
      )}
      {viewingArchived && (
        <Box display="flex">
          <Spacer />
          <PrimaryButton startIcon={<ArrowBackIosIcon />} onClick={() => setViewingArchived(false)}>Back to Users</PrimaryButton>
        </Box>
      )}
    </>
  );
}

export default MemberList;
