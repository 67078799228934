import { defineMessages } from 'react-intl';
import { SportTermLookup, SportTerminology } from '../../../lib/types';

export const scope = 'app.components.CreateSkillForm';

export default (termLookup: SportTermLookup) => {
  return defineMessages({
    lblName: {
      id: `${scope}.lblName`,
      defaultMessage: termLookup[SportTerminology.METRIC] + ' Name'
    },
    lblType: {
      id: `${scope}.lblType`,
      defaultMessage: 'Field Type'
    },
    lblCategory: {
      id: `${scope}.lblCategory`,
      defaultMessage: 'Category'
    },
    lblGlobal: {
      id: `${scope}.lblGlobal`,
      defaultMessage: 'Accessible to all associations'
    },
    btnSubmit: {
      id: `${scope}.btnSubmit`,
      defaultMessage: 'Create ' + termLookup[SportTerminology.METRIC],
    },
    btnSubmitting: {
      id: `${scope}.btnSubmitting`,
      defaultMessage: 'Saving...',
    }
  });
}
