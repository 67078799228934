import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Material UI
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

// Data
import { SPORT_TERMINOLOGY } from '../../../lib/constants';
import { toListSentence } from '../../../lib/helpers/conversion.helpers';
import { Bold } from '../../_core/_ui/typography.component';
import { PrimaryButton } from '../../_core/_ui/buttons.component';
import ApproveGroupingsGraphQL from '../ApproveGroupings/approve-groupings.graphql';
import { Sport, SportTerminology } from '../../../lib/types';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { getCompanyHasAutomation, getCompanySportTerminology } from '../../../lib/services/company.service';

dayjs.extend(utc)
dayjs.extend(timezone)

interface IGroupStageHandler {
  stage: IAssessmentStage;
  ageGroup: IAgeGroup;
  isNext?: boolean;
  positionHash: { [key: string]: string };
  isSummary?: boolean;
}

const GroupStageHandler: React.FC<IGroupStageHandler> = ({
  ageGroup,
  stage,
  isNext,
  positionHash,
  isSummary
}) => {
  const hasAutomation = getCompanyHasAutomation()
  const termLookup = getCompanySportTerminology();
  const athleteTerm = termLookup[SportTerminology.ATHLETE];
  const athletesTerm = pluralTerm(athleteTerm);
  const sessionTerm = termLookup[SportTerminology.SESSION];

  const history = useHistory();

  let isInvalid = false;
  const content = [];

  if (isNext) {
    if (stage.order !== 1) {
      content.push(<Box mt={2}><Typography key="first" variant="subtitle1"><Bold>Next Stage:</Bold> Group {athletesTerm}</Typography></Box>);
    }

    return <>{content}</>;
  }

  content.push(
    <Typography key="title" variant="subtitle1"><Bold>Current Stage:</Bold> Group {athletesTerm}</Typography>,
  );

  content.push(<Box my={1} key="divider"><Divider /></Box>);
  if (!ageGroup.numPlayers) {
    isInvalid = true;
    content.push(<Typography key="players">Missing {athletesTerm.toLowerCase()}. <Link to={"/" + pluralTerm(SPORT_TERMINOLOGY[Sport.default][SportTerminology.ATHLETE].toLowerCase())}>Add some here</Link></Typography>);
  }

  if (!ageGroup.numSessions) {
    isInvalid = true;
    content.push(<Typography key="sessions">Missing {pluralTerm(sessionTerm).toLowerCase()}. <Link to="/sessions">Add some here</Link></Typography>);
  }

  if (!isInvalid && stage.isReady?.noSessions && hasAutomation) {
    isInvalid = true;
    content.push(
      <Typography key="no-configs">
        No {sessionTerm.toLowerCase()} format found following this grouping. <Link to="/format">Add some here</Link>
      </Typography>
    );
  }

  if (!isInvalid && stage.isReady?.numFound < stage.isReady?.numRequired) {
    const playerLimits: {positionNames: string[]; limit: number}[] = stage.config.playerLimits.map((playerLimit: IPlayerLimit) => {
      const positionNames = playerLimit.positions.map(positionId => positionHash[positionId]);
      return {positionNames, limit: playerLimit.limit};
    });

    const playerLimitsSentences = playerLimits.map(({positionNames, limit}) => `${toListSentence(positionNames)} to ${limit}`);

    const { athleteCounts, groupsByLimit, numFound, numRequired, sessionNum, afterDate, afterEnd } = stage.isReady;
    isInvalid = true;

    const insufficientLimits = playerLimits.reduce((acc: {positionNames: string[]; requiredLimit: number}[], {positionNames}, i) => {
      if (groupsByLimit[i] > numFound) {
        acc.push({positionNames, requiredLimit: Math.ceil(athleteCounts[i] / numFound)})
      }
      return acc;
    }, []);

    const insufficientLimitsStrings = insufficientLimits.map(({positionNames, requiredLimit}, i) => `${positionNames.join(", ")} to ${requiredLimit}`)

    const numToAdd = numRequired - numFound
    const sessionOption = <>You need to <Bold>add {numToAdd} {numToAdd > 1 ? pluralTerm(sessionTerm).toLowerCase() : sessionTerm.toLowerCase()}</Bold> and you can do that <Link to="/sessions">here.</Link></>
    const options = numFound > 0 ? <>You either need to <Bold>A) increase your athletes limits {toListSentence(insufficientLimitsStrings)}</Bold> and you can do that <Link to="/format">here</Link>. OR <Bold>B)</Bold> {sessionOption}</> : sessionOption
    content.push(
      <Typography key="missing-sessions">
        {stage.config.playerLimits.length &&
          <>Based on the {athleteTerm.toLowerCase()} limits per {sessionTerm.toLowerCase()} that are setup within your evaluation format for the next round, which are currently set to: <Bold>{toListSentence(playerLimitsSentences)}</Bold> we've calculated that you need to review and update your settings; {options}</>
        }
      </Typography>
    );
  }

  if (!isInvalid) {
    if (stage.config.manualApproval) {
      if (isSummary) {
        content.push(
          <PrimaryButton fullWidth onClick={() => history.push(`/age-group/${ageGroup._id}`)} key="review">Review & Approve
            Groupings</PrimaryButton>
        );
      } else {
        content.push(<ApproveGroupingsGraphQL ageGroupId={ageGroup._id} stage={stage} />);
      }
    } else {
      content.push(<Typography>Automated scheduling is not enabled. Please contact your account manager for assistance</Typography>);
    }
  }

  return <Box mt={2}>{content}</Box>;
};

export default GroupStageHandler;
