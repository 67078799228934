import React from 'react';

// Material UI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// Components
import DashboardLayout from '../_layout/DashboardLayout/dashboard.layout';
import { PageTitle, SectionHeading } from '../../_core/_ui/typography.component';
import { SectionWrapper } from '../../_core/_ui/structure.components';
import CreateAssessmentGraphQL from '../../Assessment/CreateAssessment/create-assessment.graphql';
import UpdateCompanyFormContainer from '../../Company/UpdateCompanyForm/update-company-form.container';
import { getActiveCompanyId, getCompany } from '../../../lib/services/company.service';
import Typography from '@material-ui/core/Typography';
import AssessmentInfoGraphQL from '../../Assessment/AssessmentInfo/assessment-info.graphql';

const CreateAssessmentPage = () => {
  const companyId = getActiveCompanyId();
  const { company, loading, error, refetch } = getCompany(companyId);

  return (
    <DashboardLayout>
      <Grid item xs={12}>
        <PageTitle>Create New Assessment</PageTitle>
        <SectionWrapper>
          <Box mb={2}>
            <AssessmentInfoGraphQL />
          </Box>
          <CreateAssessmentGraphQL />
        </SectionWrapper>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageTitle>Update Company Settings</PageTitle>
          <SectionWrapper style={{ maxWidth: '800px' }}>
            {loading && !company && <Typography>Loading...</Typography>}
            {company &&  <UpdateCompanyFormContainer company={company} refetch={refetch} />}
          </SectionWrapper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default CreateAssessmentPage;
