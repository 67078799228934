import React from 'react';

const ImporterProgressStatus = () => {
  return (
    <div>

    </div>
  );
};

export default ImporterProgressStatus;
