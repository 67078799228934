import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

// Material UI
import Box from '@material-ui/core/Box';
import { number, string } from 'yup';
import { FormHelperText, withStyles } from '@material-ui/core';
import { dangerColor, sectionDarkColor } from '../../../lib/theme';
import { SkillOption } from '../../../lib/types';
import { lookupSkillSuffix } from '../../../lib/utils/skill.service';

const Suffix = styled.span`
  background: ${sectionDarkColor};
  border: 1px solid #ddd;
  border-left: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  display: inline-block;
  min-width: 90px;
  padding: 8px;
  text-align: center;
  width: 10%;
`;

const HAS_SUFFIX_CLASS = "has-suffix";
const NumberInput = styled.input`
  background: #fafcfe;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  width: 100%;

  &:focus {
    outline-color: #bdd739;
  }

  &.${HAS_SUFFIX_CLASS} {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    max-width: calc(100% - 90px);
    width: 90%;
  }
`;

const ErrorText = withStyles({
  root: {
    fontFamily: 'Quicksand, sans-serif;',
    fontWeight: 700,
    color: `${dangerColor} !important`,
  }
})(FormHelperText);


interface IEvaluateNumberSkill {
  allowedDecimals?: number
  skill: ISkill;
  score?: IScore;
  saveScore: Function;
  removeScore: Function;
}

const EvaluateNumberSkill: React.FC<IEvaluateNumberSkill> = ({
  allowedDecimals,
  skill,
  score,
  saveScore,
  removeScore
}) => {
  const pattern = new RegExp(String.raw`^\d*(\.\d{1,${allowedDecimals ?? 2}})?$`);
  const validationSchema = allowedDecimals ? string().nullable().matches(pattern) : number().nullable().integer().positive();

  const [currScore, setCurrScore] = useState(score ? score.raw : "");
  const [error, setError] = useState("");

  useEffect(() => {
    if (score) setCurrScore(score.raw)
    else setCurrScore("")
  }, [score])

  const handleChange = ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
    setCurrScore(value);
    if (value) {
      try {
        validationSchema.validateSync(value);
        setError("")
        saveScore(value);
      } catch (error) {
        setError(allowedDecimals ? `Please enter a positive number with a maximum of ${allowedDecimals} decimals` : "Please enter a positive integer")
      }
    } else if (score) {
      setError("")
      removeScore(score)
    } else {
      setError("")
    }
  }

  const suffix = !!skill.options && "subType" in skill.options ? lookupSkillSuffix(skill.options.subType as SkillOption) : null;

  return (
    <Box>
      <Box>
        <NumberInput placeholder='-1' type="number" value={currScore} onChange={handleChange} className={suffix ? HAS_SUFFIX_CLASS : ""} />
        {!!suffix && <Suffix>{suffix}</Suffix>}
      </Box>
      {!!error && <ErrorText>{error}</ErrorText>}
    </Box>
  );
};

export default EvaluateNumberSkill;
