import React from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

// Material UI
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Data
import { DRILL_FIELDS } from '../../../lib/queries/drill.queries';

// Components
import { PageTitle } from '../../_core/_ui/typography.component';
import DashboardLayout from '../_layout/DashboardLayout/dashboard.layout';
import { Spacer } from '../../_core/_ui/structure.components';
import { SecondaryButton } from '../../_core/_ui/buttons.component';
import CreateDrillGraphQL from '../../Drill/CreateDrill/create-drill.graphql';
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { Sport, SportTerminology } from '../../../lib/types';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { SPORT_TERMINOLOGY } from '../../../lib/constants';

interface IEditDrillPage {
  match: any;
}

const GET_DRILL = gql`
  query GetDrill($_id: ID!) {
    drill(_id: $_id) {
      ...DrillFields
      skillSets {
        positionIds
        skillIds
      }
      companyId
    }
  }

  ${DRILL_FIELDS}
`

const EditDrillPage: React.FC<IEditDrillPage> = ({ match }) => {
  const termLookup = getCompanySportTerminology();
  const history = useHistory();
  const { drillId } = match.params;

  const { data, error, loading } = useQuery(GET_DRILL, {
    variables: { _id: drillId },
    fetchPolicy: 'cache-and-network'
  });

  const drill = data && data.drill;

  return (
    <DashboardLayout hideSidebar>
      <Box display="flex" mb={2}>
        <PageTitle>Edit {termLookup[SportTerminology.EVENT]}</PageTitle>
        <Spacer />
        <SecondaryButton
          startIcon={<ArrowBackIosIcon />}
          onClick={() => history.push('/' + pluralTerm(SPORT_TERMINOLOGY[Sport.default][SportTerminology.EVENT].toLowerCase()))}
        >
          Back
        </SecondaryButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!drill && <Typography>Loading...</Typography>}
          {drill && <CreateDrillGraphQL drill={drill} />}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default EditDrillPage;
