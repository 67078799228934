export enum ContactRelationship {
  Mother = 'mother',
  Father = 'father',
  Guardian = 'guardian',
  Grandparent = 'grandparent',
  Other = 'other'
};

export const ContactRelationshipValues: string[] = Object.values(ContactRelationship)

export const ContactRelationshipLabels: Record<ContactRelationship, string> = {
  [ContactRelationship.Mother]: "Mother",
  [ContactRelationship.Father]: "Father",
  [ContactRelationship.Grandparent]: "Grandparent",
  [ContactRelationship.Guardian]: "Guardian",
  [ContactRelationship.Other]: "Other",
}

export const ContactRelationshipFormOptions = Object.entries(ContactRelationshipLabels).map(([value, label]) => ({label, value}))
