import { Storage } from '@ionic/storage';

export class IonicStorageWrapper {
   private storage: Storage;

    constructor(storage: Storage) {
     this.storage = storage;
   }

    getItem(key: string): string | Promise<string | null> | null {
     return this.storage.get(key);
   }

    removeItem(key: string): void | Promise<void> {
     return this.storage.remove(key);
   }

    setItem(key: string, value: string): void | Promise<void> {
     return this.storage.set(key, value);
   }
}
