import { gql } from '@apollo/client';

export const GET_COMPANY_BY_ID = gql`
  query GetCompanyById($_id: ID!) {
    company(_id: $_id) {
      _id
      name
      logo
      timezone
      companyWebsite
      communicationEmail
      hasAutomation
      hasCompositeVideos
      hasReportCards
      sport
      evaluationGroupName
      introLink
    }
  }
`;

export const GET_COMPANY_BY_DOMAIN = gql`
  query GetCompanyByDomain($domain: String!) {
    companyByDomain(domain: $domain) {
      _id
      name
    }
  }
`;

export const REGISTER_COMPANY = gql`
  mutation RegisterCompany($data: RegistrationInput) {
    register(data: $data) {
      success
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($_id: ID!, $data: UpdateCompanyInput!) {
    updateCompany(_id: $_id, data: $data) {
      _id
      name
      timezone
      logo
      companyWebsite
      communicationEmail
      evaluationGroupName
      introLink
    }
  }
`;
